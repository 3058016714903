import { IPagination, IPaginationForRQ, TError, TLeaderBoardSchema, TLeaderBoardType } from '@onesource/schemas';
import { useQuery } from '@tanstack/react-query';
import axios from '../axios';
import { ENTITY } from './entity';

export interface IGetContentsParams {
  filters: {
    type: TLeaderBoardType;
  };
}

export const getLeaderboardQueryKey = (filters?: IGetContentsParams['filters']) => {
  const serializeFilters = Object.entries(filters || {});
  return [ENTITY, serializeFilters];
};

export const useGetLeaderboardQuery = (param: IGetContentsParams) => {
  const { filters } = param;
  return useQuery<IPaginationForRQ<TLeaderBoardSchema>, TError>({
    queryKey: getLeaderboardQueryKey(filters),
    queryFn: async () => {
      const res = await axios.get<IPagination<TLeaderBoardSchema>>(ENTITY, {
        params: filters,
      });
      return {
        ...res.data.data,
        items: res.data.data.items.splice(0, 16),
      };
    },
  });
};
