import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import {
  AddEditPlaylistDialog,
  ClassesDataStateHandler,
  useGetByDevice,
  useSetSearchParams,
} from '@onesource/components';
import { contentFilterDefaultValues, InstructorTypeEnum } from '@onesource/schemas';
import { useGetContentsQuery, useGetInstructorsQuery, useGetSubscriptionQuery } from '@onesource/services';
import { usePlaylistMenuService } from 'components/add-to-playlist/service';
import { usePlaylistAction } from 'hooks/use-playlist-actions';
import { FC, useCallback, useEffect } from 'react';
import { ContentForToday } from '../../components/content-for-today';
import { PostFilterSidebar } from '../../components/post-filter-sidebar';
import { TeachersFilterNav } from '../../components/teacher-filter-nav';
import { useRouteUtils } from '../../components/use-route-utils';

export const ClassesByTeachers: FC = () => {
  const { searchParams } = useRouteUtils();
  const { isPortable } = useGetByDevice();

  const {
    contextClassId,
    onAddToPlaylist,
    createPlaylist,
    isCreatePending,
    onClickCreate,
    onClosePlaylistDialog,
    isCreatePlaylistDialogOpen,
  } = usePlaylistAction();

  const { data: instructorsData, isLoading } = useGetInstructorsQuery({
    filters: {
      type: InstructorTypeEnum.REGULAR,
    },
  });

  const searchFilterUtils = useSetSearchParams({
    initialFilters: { ...contentFilterDefaultValues, ...searchParams, scrollTop: '1' },
    resetFilters: { ...contentFilterDefaultValues, scrollTop: '1' },
  });

  const { patchFilter, filters } = searchFilterUtils;

  const contentStates = useGetContentsQuery({ filters: searchFilterUtils.filters });

  const { isSubscriptionActive } = useGetSubscriptionQuery();

  const handleTeacherSelect = useCallback((instructorId: string) => {
    patchFilter({ instructorId });
  }, []);

  const onNext = useCallback((page: number) => {
    patchFilter({ page: page.toString() });
  }, []);

  const onPrev = useCallback((page: number) => {
    patchFilter({ page: page.toString() });
  }, []);

  const defaultSelectInstructorId = instructorsData?.items[0]?.id;

  const { setPlaylistMenu } = usePlaylistMenuService();

  const onClickAddPlaylistHandler = useCallback(
    (anchorEl: HTMLElement, contentId: string) => {
      setPlaylistMenu({
        contentId,
        anchorEl,
        onAddToPlaylist,
        onClickCreate,
      });
    },
    [onAddToPlaylist, onClickCreate, onClosePlaylistDialog],
  );

  useEffect(() => {
    if (!filters.instructorId && defaultSelectInstructorId) {
      patchFilter({ instructorId: defaultSelectInstructorId });
    }
  }, [filters.instructorId]);

  return (
    <Box sx={{ pt: { xs: 2.5, md: 3 }, pb: 4, background: theme => theme.palette.common.white, overflow: 'hidden' }}>
      <Container maxWidth="xl">
        <Grid container spacing={{ xs: 2, md: 4 }}>
          <Grid item xs={12} sx={{ mb: { xs: 0, md: 5 } }}>
            <TeachersFilterNav
              slides={instructorsData?.items || []}
              onSelectTeacher={handleTeacherSelect}
              selected={filters.instructorId}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12} sx={{ display: isPortable ? 'none' : 'block' }}>
            <PostFilterSidebar searchFilterUtils={searchFilterUtils} isDefaultExpanded hide={['instructor']} />
          </Grid>
          <Grid item md={12} sm={12} xs={12} lg={9}>
            <ClassesDataStateHandler
              isSubscriptionActive={isSubscriptionActive}
              onClickAddPlaylist={onClickAddPlaylistHandler}
              onNext={onNext}
              onPrev={onPrev}
              queryResult={contentStates}
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
              }}
            />
          </Grid>
          <ContentForToday />
        </Grid>

        <AddEditPlaylistDialog
          isPending={isCreatePending}
          open={isCreatePlaylistDialogOpen}
          onClose={onClosePlaylistDialog}
          onSave={createPlaylist}
          contentId={contextClassId}
        />
      </Container>
    </Box>
  );
};
