import type { SVGProps } from 'react';
const SvgPlayButtonWhite = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 69 69"
    fontSize="1rem"
    {...props}
  >
    <g clipPath="url(#play-button-white_svg__a)">
      <path
        fill="currentColor"
        fillOpacity={0.7}
        d="M34.5 0C15.446 0 0 15.446 0 34.5S15.446 69 34.5 69 69 53.554 69 34.5 53.554 0 34.5 0m17.87 37.43L26.732 53.194c-2.203 1.295-4.98-.294-4.98-2.85V18.656c0-2.673 3.01-4.24 5.2-2.707l25.637 15.925c1.971 1.38 1.855 4.338-.22 5.557z"
      />
    </g>
    <defs>
      <clipPath id="play-button-white_svg__a">
        <path fill="#fff" d="M0 0h69v69H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgPlayButtonWhite;
