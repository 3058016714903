import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { SxProps, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { BackButton } from 'components/back-button';

export const PageHeader: FC<{ title: string; sx?: SxProps }> = props => {
  const { title, sx } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        background: theme.palette.primary.main,
        py: { xs: 3, md: 4.5 },
        borderRadius: { xs: '0 0 60px 60px', md: '0 0 70px 70px' },
        ...sx,
      }}
    >
      <Container maxWidth="xl">
        <Grid container spacing={4}>
          <Grid item xs={12} sx={{ position: 'relative' }}>
            <Stack flexDirection="row" alignItems="center" width="100%">
              <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <BackButton />
              </Box>
              <Box
                sx={{
                  textAlign: 'center',
                  alignItems: 'center',
                  display: 'flex',
                  flexGrow: 1,
                }}
              >
                <Typography
                  variant="h2"
                  sx={{
                    textAlign: 'center',
                    width: '100%',
                    color: theme => theme.palette.common.white,
                  }}
                >
                  {title}
                </Typography>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
