import { createFileRoute } from '@tanstack/react-router';
import { PageHeader } from '../../components/page-header';
import { SeriesPage } from '../../pages/series';
import { AuthGuard } from '@onesource/components';

export const Route = createFileRoute('/series/')({
  component: () => (
    <AuthGuard>
      <>
        <PageHeader title="Curated Collections" />
        <SeriesPage />
      </>
    </AuthGuard>
  ),
});
