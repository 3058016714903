import { IPaginationForRQ, TContentByDate, TDateRangeQuerySchema, TError } from '@onesource/schemas';
import { useQuery } from '@tanstack/react-query';
import axios from '../axios';
import { ENTITY } from './entity';
import { DateTime } from 'luxon';
import { useGetSubscriptionQuery } from '../profile';

export const getFirstAndLastDayOfMonth = (year: number, month: number): TDateRangeQuerySchema => {
  const localZone = DateTime.local().zoneName;
  const date = DateTime.fromObject({ month, year }).setZone(localZone);

  const startDate = date.startOf('month').toUTC().toISO();
  const endDate = date.endOf('month').toUTC().toISO();

  return { startDate, endDate };
};

const now = new Date();
const currentYear = now.getFullYear();
const currentMonth = now.getFullYear();
const DEFAULT_FILTERS: TDateRangeQuerySchema = getFirstAndLastDayOfMonth(currentYear, currentMonth);

export const getContentsByDateRangeQueryKey = (filters: TDateRangeQuerySchema = DEFAULT_FILTERS) => {
  return [ENTITY, Object.entries(filters)];
};

export const useGetContentsByDateRangeQuery = (params: TDateRangeQuerySchema = DEFAULT_FILTERS) => {
  const { isLoadingProfileInfo } = useGetSubscriptionQuery();
  return useQuery<IPaginationForRQ<TContentByDate>, TError>({
    queryKey: getContentsByDateRangeQueryKey(params),
    queryFn: async () => {
      const res = await axios.get(`${ENTITY}/by-date-range`, {
        params,
      });
      return res.data;
    },
    enabled: !isLoadingProfileInfo,
  });
};

export const useGetContentsByMonthQuery = (param: { year: number; month: number; page: number }) => {
  const { year, month, page } = param;
  const date = getFirstAndLastDayOfMonth(year, month);
  return useGetContentsByDateRangeQuery({ ...date, page });
};

export const useGetContentsForTodayQuery = () => {
  const today = DateTime.local();
  const startDate = today.startOf('day').toISO().split('T')[0];
  const endDate = today.endOf('day').toISO().split('T')[0];
  return useGetContentsByDateRangeQuery({ startDate, endDate });
};
