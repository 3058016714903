import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { ChipProps } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { EventStatusEnum, TBaseEventSchema, TEventStatus } from '@onesource/schemas';
import { FC } from 'react';

import { ellipsis, formatDateWithTime } from '@onesource/utils';

type TProps = {
  event: TBaseEventSchema;
  actions?: JSX.Element | JSX.Element[];
};

export const EventCardV2: FC<TProps> = ({ event, actions }) => {
  const { instructor } = event;

  const eventStatusChipColor: Record<TEventStatus, ChipProps['color']> = {
    [EventStatusEnum.SCHEDULED]: 'warning',
    [EventStatusEnum.STARTED]: 'success',
    [EventStatusEnum.ENDED]: 'error',
  };

  return (
    <Card
      sx={{
        borderRadius: 4,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        minHeight: '200px',
        boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      }}
    >
      <Grid container spacing={2}>
        <Grid md={4} item>
          <CardMedia
            component="img"
            image={instructor.profilePicture?.landscape}
            sx={{
              height: '100%',
              objectFit: 'cover',
            }}
            alt={`${instructor.firstName} ${instructor.lastName}`}
          />
        </Grid>

        <Grid md={8} item>
          <CardContent sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column', boxSizing: 'border-box' }}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h5" component="h2" sx={{ mb: 1, fontWeight: 'bold', ...ellipsis }}>
                {event.title}
              </Typography>

              <Stack spacing={2} direction="row">
                <Chip color="info" variant="outlined" label={event.type} />
                <Chip sx={{ ml: 2 }} variant="filled" color={eventStatusChipColor[event.status]} label={event.status} />
              </Stack>
            </Stack>

            <Stack direction="row">
              <Typography variant="body2" color="textSecondary" mb={0.5} fontStyle="italic">
                {formatDateWithTime(new Date(event.scheduleDateTime.toString()))}
              </Typography>
            </Stack>

            <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
              {event.description?.substring(0, 500)}...
            </Typography>

            <Box flex={1} />

            {actions ? actions : null}
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
};
