import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import {
  AddEditPlaylistDialog,
  ClassesDataStateHandler,
  useGetByDevice,
  useSetSearchParams,
} from '@onesource/components';
import { contentFilterDefaultValues } from '@onesource/schemas';
import { useGetContentsQuery, useGetSubscriptionQuery } from '@onesource/services';
import { usePlaylistMenuService } from 'components/add-to-playlist/service';
import { usePlaylistAction } from 'hooks/use-playlist-actions';
import { FC, useCallback } from 'react';
import { ContentForToday } from '../../components/content-for-today';
import { PostFilterSidebar } from '../../components/post-filter-sidebar';
import { useRouteUtils } from '../../components/use-route-utils';

export const AllClasses: FC = () => {
  const { searchParams } = useRouteUtils();
  const { isPortable } = useGetByDevice();

  const {
    contextClassId,
    onAddToPlaylist,
    createPlaylist,
    isCreatePending,
    onClickCreate,
    onClosePlaylistDialog,
    isCreatePlaylistDialogOpen,
  } = usePlaylistAction();

  const searchFilterUtils = useSetSearchParams({
    initialFilters: { ...contentFilterDefaultValues, ...searchParams, scrollTop: '1' },
    resetFilters: { ...contentFilterDefaultValues, scrollTop: '1' },
  });

  const contentStates = useGetContentsQuery({ filters: searchFilterUtils.filters });
  const { isSubscriptionActive } = useGetSubscriptionQuery();

  const { patchFilter } = searchFilterUtils;

  const onNext = useCallback((page: number) => {
    patchFilter({ page: page.toString() });
  }, []);

  const onPrev = useCallback((page: number) => {
    patchFilter({ page: page.toString() });
  }, []);

  const { setPlaylistMenu } = usePlaylistMenuService();

  const onClickAddPlaylistHandler = useCallback(
    (anchorEl: HTMLElement, contentId: string) => {
      setPlaylistMenu({
        contentId,
        anchorEl,
        onAddToPlaylist,
        onClickCreate,
      });
    },
    [onAddToPlaylist, onClickCreate, onClosePlaylistDialog],
  );

  return (
    <Box sx={{ py: { xs: 3, md: 7 }, background: theme => theme.palette.common.white, overflow: 'hidden' }}>
      <Container maxWidth="xl">
        <Grid container spacing={4}>
          <Grid item md={3} sm={6} xs={12} sx={{ display: isPortable ? 'none' : 'block' }}>
            <PostFilterSidebar searchFilterUtils={searchFilterUtils} isDefaultExpanded />
          </Grid>

          <Grid item md={12} lg={9} sm={12} xs={12}>
            <ClassesDataStateHandler
              isSubscriptionActive={isSubscriptionActive}
              onNext={onNext}
              onPrev={onPrev}
              onClickAddPlaylist={onClickAddPlaylistHandler}
              queryResult={contentStates}
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
              }}
            />
          </Grid>
          <ContentForToday />
        </Grid>
      </Container>

      <AddEditPlaylistDialog
        isPending={isCreatePending}
        open={isCreatePlaylistDialogOpen}
        onClose={onClosePlaylistDialog}
        onSave={createPlaylist}
        contentId={contextClassId}
      />
    </Box>
  );
};
