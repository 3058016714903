import { alpha, SxProps, useTheme } from '@mui/material';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { IoReload } from 'react-icons/io5';
import { FC } from 'react';

interface ILoadingErrorProps {
  entity: string;
  message: string;
  errorCode?: string;
  refetch?: () => void;
  sx?: SxProps;
}

export const LoadingError: FC<ILoadingErrorProps> = props => {
  const { sx, entity, message, errorCode, refetch } = props;
  const theme = useTheme();

  return (
    <Box sx={sx}>
      <Alert
        severity="error"
        sx={{
          width: '100%',
          py: 2,
          pl: 3,
          pr: 4,
          borderRadius: 4,
          boxSizing: 'border-box',
          border: `1px solid ${alpha(theme.palette.error.light, 0.2)}`,
        }}
        action={
          refetch && (
            <Button
              size="large"
              startIcon={<IoReload />}
              sx={{ minWidth: 'auto', pr: 2.5, p: `10px 20px`, boxShadow: 'none' }}
              onClick={refetch}
            >
              Retry data fetching
            </Button>
          )
        }
      >
        <AlertTitle sx={{ textTransform: 'capitalize' }}>
          {entity}: {errorCode?.replace(/_/g, ' ').toLowerCase()}
        </AlertTitle>

        {message}
      </Alert>
    </Box>
  );
};
