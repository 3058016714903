import { alpha, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { FC } from 'react';
import { MdKeyboardArrowRight } from 'react-icons/md';

export interface IFeaturedNavItemProps {
  startIcon: JSX.Element;
  name: string;
  selected: boolean;
  onClick: (categoryId: string) => void;
}

export const FeaturedNavItem: FC<IFeaturedNavItemProps> = props => {
  const { startIcon, name, selected, onClick } = props;
  const theme = useTheme();

  return (
    <Button
      onClick={() => onClick(name)}
      startIcon={startIcon}
      fullWidth
      endIcon={
        <Box
          sx={{
            background: theme.palette.common.white,
            borderRadius: '100%',
            lineHeight: 0,
            padding: { xs: 0.8, md: 0.7 },
            '& svg': {
              fontSize: { xs: '25px', md: '35px' },
            },
          }}
        >
          <MdKeyboardArrowRight
            style={{
              lineHeight: 1,
              color: theme.palette.grey[800],
            }}
          />
        </Box>
      }
      sx={{
        background: `${selected ? theme.palette.primary.main : alpha(theme.palette.primary.light, 0.12)} !important`,
        color: selected ? theme.palette.common.white : theme.palette.grey[800],
        '& .MuiButton-startIcon svg, & .MuiButton-startIcon svg': {
          color: selected ? theme.palette.common.white : theme.palette.grey[800],
          height: {
            xs: '25px',
            md: '36px',
          },
          width: {
            xs: '25px',
            md: '36px',
          },
        },
        width: '100%',
        fontSize: {
          xs: '16px',
          md: '24px',
        },
        lineHeight: {
          xs: 1,
          md: '36px',
        },
        fontWeight: '600',
        padding: {
          xs: '10px 10px 10px 14px',
          md: '30px',
        },
        boxShadow: 'none',
        textTransform: 'capitalize',
        '& .MuiButton-endIcon': {
          marginLeft: 'auto',
        },
        '&:hover': {
          boxShadow: 'none',
          color: selected ? theme.palette.common.white : theme.palette.primary.main,
          background: theme.palette.primary.main,
          '& .MuiButton-startIcon svg, & .MuiButton-startIcon svg': {
            color: selected ? theme.palette.common.white : theme.palette.primary.main,
          },
          '& .MuiButton-endIcon svg, & .MuiButton-endIcon svg': {
            color: selected ? theme.palette.common.white : theme.palette.primary.main,
          },
        },
      }}
    >
      {name.toLowerCase()}
    </Button>
  );
};
