import type { SVGProps } from 'react';
const SvgRestore = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 39 39"
    fontSize="1rem"
    {...props}
  >
    <path
      fill="currentColor"
      d="M37.781 18.89a8.55 8.55 0 0 0-4.875-7.71v-.211A7.313 7.313 0 0 0 19.5 6.933a7.312 7.312 0 0 0-13.406 4.036v.211a8.532 8.532 0 0 0 0 15.418v.214A7.313 7.313 0 0 0 19.5 30.848a7.313 7.313 0 0 0 13.406-4.036v-.215a8.55 8.55 0 0 0 4.875-7.707M13.406 31.687a4.876 4.876 0 0 1-4.846-4.35 8.5 8.5 0 0 0 1.19.085h1.219a1.219 1.219 0 0 0 0-2.438H9.75a6.094 6.094 0 0 1-2.03-11.842 1.22 1.22 0 0 0 .811-1.148v-1.025a4.875 4.875 0 1 1 9.75 0v10.399a7.28 7.28 0 0 0-4.875-1.868 1.219 1.219 0 0 0 0 2.437 4.875 4.875 0 1 1 0 9.75m15.844-6.703h-1.219a1.22 1.22 0 0 0 0 2.438h1.219q.598-.001 1.19-.086a4.874 4.874 0 1 1-4.846-5.399 1.219 1.219 0 0 0 0-2.437 7.28 7.28 0 0 0-4.875 1.867V10.97a4.875 4.875 0 1 1 9.75 0v1.025a1.22 1.22 0 0 0 .812 1.149 6.094 6.094 0 0 1-2.031 11.841m2.438-7.922a1.22 1.22 0 0 1-1.22 1.22h-.609a5.484 5.484 0 0 1-5.484-5.485v-.61a1.219 1.219 0 0 1 2.438 0v.61a3.047 3.047 0 0 0 3.046 3.047h.61a1.22 1.22 0 0 1 1.218 1.218M9.14 18.282h-.61a1.219 1.219 0 0 1 0-2.438h.61a3.047 3.047 0 0 0 3.047-3.047v-.61a1.219 1.219 0 0 1 2.437 0v.61a5.484 5.484 0 0 1-5.484 5.484"
    />
  </svg>
);
export default SvgRestore;
