import { FC } from 'react';
import { InstructorDetailsBody } from '../components/sections/single-instructor-details';
import { useInstructorDetailsPageParams } from 'routes/instructors/$instructorId';

export const InstructorDetails: FC = () => {
  const { instructorId } = useInstructorDetailsPageParams();

  if (!instructorId) {
    return null;
  }

  return <InstructorDetailsBody instructorId={instructorId} />;
};
