import Button from '@mui/material/Button';
import { ButtonProps, SxProps } from '@mui/material';
import { FC, type ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';

type TProps = {
  sx?: SxProps;
  buttonProps?: ButtonProps;
  children?: ReactNode;
  disabled?: boolean;
};

export const RHFSubmitBtn: FC<TProps> = props => {
  const { sx, buttonProps, children, disabled } = props;
  const context = useFormContext();
  const { formState } = context;
  const { isDirty, isValid } = formState;

  return (
    <>
      <Button
        type="submit"
        color="success"
        disabled={!isDirty || !isValid || disabled}
        autoFocus
        variant="contained"
        size="large"
        sx={{
          ...sx,
        }}
        {...buttonProps}
      >
        {children ?? 'Submit'}
      </Button>
    </>
  );
};
