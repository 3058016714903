import { useTheme, Breakpoint } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

type TQuery = 'up' | 'down' | 'between' | 'only';

type TValue = Breakpoint | number;

type TReturnGetDevice = {
  isDesktop: boolean;
  isTablet: boolean;
  isMobile: boolean;
  isXsMobile: boolean;
  isPortable: boolean;
};

export function useGetByBreakpoint(query: TQuery, start?: TValue, end?: TValue): boolean {
  const theme = useTheme();
  const mediaUp = useMediaQuery(theme.breakpoints.up(start as TValue));
  const mediaDown = useMediaQuery(theme.breakpoints.down(start as TValue));
  const mediaBetween = useMediaQuery(theme.breakpoints.between(start as TValue, end as TValue));
  const mediaOnly = useMediaQuery(theme.breakpoints.only(start as Breakpoint));

  if (query === 'up') {
    return mediaUp;
  }

  if (query === 'down') {
    return mediaDown;
  }

  if (query === 'between') {
    return mediaBetween;
  }

  return mediaOnly;
}

export const useGetByDevice = (): TReturnGetDevice => {
  const isDesktop = useGetByBreakpoint('up', 'lg');
  const isTablet = useGetByBreakpoint('between', 'sm', 'lg');
  const isMobile = useGetByBreakpoint('down', 'sm');
  const isXsMobile = useGetByBreakpoint('down', 376);

  const isPortable = isMobile || isTablet;

  return { isDesktop, isTablet, isMobile, isXsMobile, isPortable };
};
