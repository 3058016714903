import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { alpha, useTheme } from '@mui/material';
import { EventStatusEnum, TBaseEventSchema } from '@onesource/schemas';
import { Link } from '@tanstack/react-router';
import { GiTeacher } from 'react-icons/gi';
import { FC } from 'react';
import { motion } from 'framer-motion';
import { LiveIcon } from '@onesource/icons';

type TEvetnCardActionsProps = {
  event: TBaseEventSchema;
};

export const EventCardActions: FC<TEvetnCardActionsProps> = ({ event }) => {
  const theme = useTheme();
  const { instructor } = event;
  const isEventStarted = event?.status === EventStatusEnum.STARTED;
  const isEventEnded = event?.status === EventStatusEnum.ENDED;

  if (isEventEnded) {
    return <></>;
  }

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Link to={`/instructors/${instructor.id}`} style={{ textDecoration: 'none', display: 'inline-block' }}>
        <Stack direction="row" spacing={1} alignItems="center">
          <GiTeacher size={26} color={theme.palette.primary.main} />
          <Typography variant="h6" color="primary.main">
            {instructor.firstName} {event.instructor.lastName}
          </Typography>
        </Stack>
      </Link>

      <Link to={`/events/${event.id}`} style={{ textDecoration: 'none' }}>
        <Button
          color="primary"
          variant={isEventStarted ? 'contained' : 'outlined'}
          endIcon={
            <Box
              sx={{
                display: 'flex',
                borderRadius: '50%',
                height: '50px',
                background: theme =>
                  isEventStarted ? theme.palette.primary.main : alpha(theme.palette.primary.main, 0.1),
                border: `1px solid ${theme.palette.primary.main}`,
                boxShadow: `5px 5px 15px ${theme.palette.common.black}20`,
              }}
            >
              {isEventStarted ? (
                <motion.div
                  initial={{ opacity: 0.8, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{
                    repeat: Infinity,
                    duration: 1,
                    ease: 'easeInOut',
                    repeatType: 'reverse',
                  }}
                >
                  <LiveIcon fontSize={50} color={theme.palette.common.white} />
                </motion.div>
              ) : (
                <LiveIcon fontSize={50} color={theme.palette.primary.main} />
              )}
            </Box>
          }
        >
          {isEventStarted ? 'Join Live Event' : 'Join and wait to start'}
        </Button>
      </Link>
    </Stack>
  );
};
