import type { SVGProps } from 'react';
const SvgUpArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 21 14"
    fontSize="1rem"
    {...props}
  >
    <path stroke="#fff" strokeLinecap="round" strokeMiterlimit={10} strokeWidth={3} d="m19 2-8.511 9L2 2" />
  </svg>
);
export default SvgUpArrow;
