import type { SVGProps } from 'react';

const SvgBoxPlayButton = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 512 512"
    width="1em"
    height="1em"
    fontSize="1rem"
    {...props}
  >
    <path
      fill="currentColor"
      d="M475.241 90.074H39.13C18.204 90.074 0 106.75 0 127.676v255.972c0 20.917 18.204 38.278 39.13 38.278h436.111c20.926 0 36.759-17.361 36.759-38.278V127.676c0-20.926-15.833-37.602-36.759-37.602m17.796 293.917c0 10.477-8.498 18.972-18.981 18.972H37.944c-10.484 0-18.981-8.495-18.981-18.972V128.009c0-10.477 8.498-18.972 18.981-18.972h436.111c10.484 0 18.981 8.495 18.981 18.972z"
    />
    <path
      fill="currentColor"
      d="m357.435 247.889-142.222-99.556c-2.889-2.037-7.268-2.287-10.408-.639-3.139 1.63-5.694 4.87-5.694 8.407v199.111c0 3.537 2.556 6.778 5.694 8.407 1.38.722 3.176 1.074 4.676 1.074 1.907 0 3.963-.574 5.583-1.713l142.296-99.556c2.537-1.778 4.083-4.676 4.083-7.768s-1.471-5.989-4.008-7.767M218.074 337V174.315l116.204 81.343z"
    />
  </svg>
);
export default SvgBoxPlayButton;
