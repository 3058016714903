import { AuthGuard } from '@onesource/components';
import { Outlet, createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_protected')({
  component: () => (
    <AuthGuard>
      <Outlet />
    </AuthGuard>
  ),
});
