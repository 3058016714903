import { alpha, SxProps, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { TPlaylist } from '@onesource/schemas';
import { ellipsis } from '@onesource/utils';
import { Link } from '@tanstack/react-router';
import { FC, MouseEvent, useCallback } from 'react';
import { FaPencil } from 'react-icons/fa6';
import { HiDotsVertical } from 'react-icons/hi';
import { IoMdRemoveCircle } from 'react-icons/io';
import { useContextMenuService } from '../../context-menu';

type IProps = {
  playlist: TPlaylist;
  onEdit: (playlist: TPlaylist) => void;
  onDelete: (playlist: TPlaylist) => void;
  sx?: SxProps;
};

export const PlaylistCard: FC<IProps> = props => {
  const { playlist, sx, onEdit, onDelete } = props;
  const { setContextMenu } = useContextMenuService();
  const theme = useTheme();
  const bannerUrl = playlist.items[0]?.content.video.bannerUrl2x;
  const contentCount = playlist.items.length;

  const handleOnClickPlaylistEdit = useCallback(
    (event: MouseEvent<HTMLButtonElement>, playlist: TPlaylist) => {
      setContextMenu({
        anchorEl: event.currentTarget,
        items: [
          {
            id: 'edit',
            label: 'Edit Name',
            icon: <FaPencil color={theme.palette.primary.main} size={20} />,
            color: 'info',
            context: playlist,
            onClick: () => onEdit(playlist),
          },
          {
            id: 'remove',
            label: 'Remove playlist',
            icon: <IoMdRemoveCircle color={theme.palette.primary.main} size={24} />,
            color: 'info',
            context: playlist,
            onClick: () => onDelete(playlist),
          },
        ],
      });
    },
    [onDelete, onEdit],
  );

  return (
    <Box sx={{ position: 'relative', ...sx }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
        <Typography variant="h6" component="h2" color="common.black" sx={{ ...ellipsis, fontWeight: 'bold' }}>
          {playlist.name}
        </Typography>

        <Box component="span" fontSize={14} fontWeight="regular" ml={1}>
          ({contentCount}) videos
        </Box>

        <Box flex={1} />

        <IconButton
          disabled={playlist.isFavorite}
          onClick={event => handleOnClickPlaylistEdit(event, playlist)}
          sx={{
            pr: 0,
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.common.black,
            height: '45px',
            width: '45px',
            p: 0,
            '&:hover': {
              color: theme.palette.common.white,
              background: theme.palette.primary.main,
            },
          }}
        >
          <HiDotsVertical fontSize={24} />
        </IconButton>
      </Stack>

      <Link to={`/playlists/${playlist.id}`} style={{ textDecoration: 'none' }}>
        <Card sx={{ boxShadow: 'none' }}>
          <Stack justifyContent="center" alignItems="center">
            <Box
              sx={{
                mb: 0.4,
                height: '7px',
                width: '90%',
                borderRadius: '20px 20px 0 0',
                background: theme => alpha(theme.palette.primary.light, 0.2),
              }}
            />

            <Box
              sx={{
                mb: 0.4,
                height: '7px',
                borderRadius: '20px 20px 0 0',
                width: '95%',
                background: theme => alpha(theme.palette.primary.light, 0.4),
              }}
            />
          </Stack>

          {!contentCount ? (
            <Box
              sx={{
                height: 340,
                borderRadius: 3,
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                background: theme => alpha(theme.palette.primary.light, 0.1),
              }}
            >
              <Typography color="primary.main" variant="h6">
                No Videos added yet
              </Typography>
            </Box>
          ) : (
            <CardMedia
              sx={{
                position: 'relative',
                height: 340,
                borderRadius: 3,
                '&:after': {
                  content: '""',
                  position: 'absolute',
                  borderRadius: 3,
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background: theme => alpha(theme.palette.common.black, 0.3),
                },
              }}
              image={bannerUrl || 'https://placehold.co/720x350'}
            />
          )}
        </Card>
      </Link>
    </Box>
  );
};
