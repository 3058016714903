import Skeleton from '@mui/material/Skeleton';
import { SxProps, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Link, RegisteredRouter, RoutePaths } from '@tanstack/react-router';
import { FC, useMemo } from 'react';
import { SlArrowRight } from 'react-icons/sl';
import { FeaturedInstructorCard, FeaturedInstructorLoading, Slideshow, useGetByDevice } from '@onesource/components';
import { useGetInstructorsQuery } from '@onesource/services';
import { InstructorTypeEnum, TInstructor } from '@onesource/schemas';

export type TInstructorSectionProps = {
  title: string;
  subtitle: string;
  buttonLabel: string;
  buttonLink: RoutePaths<RegisteredRouter['routeTree']>;
  sx?: SxProps;
};

const InstructorSectionLoading: FC = () => {
  const { isMobile } = useGetByDevice();

  return (
    <Box sx={{ background: theme => theme.palette.grey[100], py: { xs: 6, md: 12 } }}>
      <Container maxWidth="xl">
        <Grid container spacing={4}>
          <Grid item md={4} xs={12}>
            <Typography
              variant="h2"
              sx={{
                mt: { xs: 0, md: 8.5 },
                mb: { xs: 2, md: 4 },
              }}
            >
              <Skeleton />
              <Skeleton />
            </Typography>
            <Typography
              variant="body1"
              sx={{
                mb: { xs: 0, md: '30px' },
              }}
            >
              <Skeleton />
            </Typography>
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: { xs: 'none', md: 'block' },
              }}
            >
              <Skeleton sx={{ width: 200, height: 100 }} />
            </Box>
          </Grid>

          <Grid item md={8} xs={12}>
            <Grid container spacing={4}>
              {Array.from({ length: isMobile ? 1 : 3 }).map((_x, index) => (
                <Grid md={4} sm={6} xs={12} item key={index}>
                  <FeaturedInstructorLoading />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export const InstructorSection: FC<TInstructorSectionProps> = props => {
  const { title, subtitle, buttonLabel, buttonLink, sx } = props;
  const theme = useTheme();

  const { data: instructorsRes, isLoading } = useGetInstructorsQuery({
    filters: {
      type: InstructorTypeEnum.REGULAR,
    },
  });

  const instructorList = useMemo(() => instructorsRes?.items || [], [instructorsRes?.items]);

  const findInstructorLauren = useMemo(() => {
    const findInstructor = instructorList.find(item => item.slug === 'lauren-ohayon');
    if (!findInstructor) {
      return {} as TInstructor;
    }
    return findInstructor;
  }, [instructorList]);

  const randomInstructors = useMemo(() => {
    const sorts = instructorList.sort(() => 0.5 - Math.random());
    return sorts.filter(item => item.slug !== findInstructorLauren.slug).slice(0, 2);
  }, [instructorList, findInstructorLauren]);

  const SliderSettings = {
    slidesToShow: 3,
    slidesToScroll: 0,
    arrows: false,
    infinite: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerMode: true,
          centerPadding: '12%',
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerMode: true,
          centerPadding: '0%',
          infinite: true,
        },
      },
    ],
  };

  if (isLoading) {
    return <InstructorSectionLoading />;
  }

  return (
    <Box sx={{ background: theme.palette.grey[100], py: { xs: 6, md: 12 }, ...sx }}>
      <Container maxWidth="xl">
        <Grid container spacing={4} sx={{ pt: { xs: 0, md: 0 } }}>
          <Grid item md={4} xs={12}>
            <Typography
              variant="h2"
              sx={{
                mt: { xs: 0, md: 8.5 },
                mb: { xs: 2, md: 4 },
                textAlign: { xs: 'center', md: 'left' },
              }}
            >
              {title}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                mb: { xs: 0, md: '30px' },
                fontSize: { xs: '14px', md: '16px' },
                fontWeight: '400',
                textAlign: { xs: 'center', md: 'left' },
              }}
            >
              {subtitle}
            </Typography>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              <Link to={buttonLink}>
                <Button
                  endIcon={
                    <Box
                      sx={{
                        background: theme.palette.primary.main,
                        borderRadius: '100%',
                        padding: '14px',
                        lineHeight: 0,
                      }}
                    >
                      <SlArrowRight
                        style={{
                          height: '17px',
                          border: '3px',
                          width: '17px',
                        }}
                      />
                    </Box>
                  }
                  sx={{ paddingLeft: '20px' }}
                >
                  {buttonLabel}
                </Button>
              </Link>
            </Box>
          </Grid>
          <Grid item md={8} xs={12}>
            <Box
              sx={{
                display: { xs: 'block', md: 'none' },
                m: 'auto',
                '& .slick-track>div>div': { margin: 'auto' },
                '& .slick-track': {
                  gap: 2,
                  display: 'flex',
                  alignItems: 'center',
                },
              }}
            >
              <Slideshow
                slides={[findInstructorLauren, ...randomInstructors]}
                renderSlide={instructors => <FeaturedInstructorCard instructor={instructors} key={instructors.id} />}
                settings={SliderSettings}
              />
            </Box>

            <Grid container spacing={3} sx={{ display: { xs: 'none', md: 'flex' } }}>
              <Grid item md={4} sm={6} xs={12}>
                <FeaturedInstructorCard instructor={findInstructorLauren} />
              </Grid>

              {randomInstructors.map(instructor => (
                <Grid item md={4} sm={6} xs={12} key={instructor.id}>
                  <FeaturedInstructorCard instructor={instructor} />
                </Grid>
              ))}
            </Grid>
            <Box
              sx={{
                display: { xs: 'flex', md: 'none' },
                justifyContent: 'center',
                mt: 5,
              }}
            >
              <Link to={buttonLink}>
                <Button
                  endIcon={
                    <Box
                      sx={{
                        background: theme.palette.primary.main,
                        borderRadius: '100%',
                        padding: '14px',
                        lineHeight: 0,
                      }}
                    >
                      <SlArrowRight
                        style={{
                          height: '17px',
                          border: '3px',
                          width: '17px',
                        }}
                      />
                    </Box>
                  }
                  sx={{ paddingLeft: '20px' }}
                >
                  {buttonLabel}
                </Button>
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
