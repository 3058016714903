import { TError, TPlaylist } from '@onesource/schemas';
import { useQuery } from '@tanstack/react-query';
import axios from '../axios';
import { ENTITY } from './entity';

export const getPlaylistQueryKey = (id: string): [string, string] => {
  return [ENTITY, id];
};

export const useGetPlaylistQuery = (id: string) => {
  return useQuery<TPlaylist, TError>({
    queryKey: getPlaylistQueryKey(id),
    queryFn: async () => {
      const res = await axios.get<TPlaylist>(`${ENTITY}/${id}`);
      return res.data;
    },
  });
};

export type TGetPlaylistQueryReturn = ReturnType<typeof useGetPlaylistQuery>;
