import { atom } from 'jotai';

export interface IConfirmAtom {
  open: boolean;
  message: string;
  title: string;
  confirmBtnLabel?: string;
  cancelBtnLabel?: string;
  onConfirm: () => void;
  onCancel?: () => void;
}

export const confirmAtom = atom<IConfirmAtom | null>(null);
