import IconButton from '@mui/material/IconButton';
import { SnackbarKey, closeSnackbar } from 'notistack';
import { FC } from 'react';
import { IoClose } from 'react-icons/io5';

type TProps = {
  snackbarId: SnackbarKey;
};

export const SnackbarCloseAction: FC<TProps> = ({ snackbarId }) => {
  return (
    <IconButton color="inherit" onClick={() => closeSnackbar(snackbarId)}>
      <IoClose />
    </IconButton>
  );
};
