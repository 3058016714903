import type { SVGProps } from 'react';
const SvgMobileMenuBars = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 27 27"
    fontSize="1rem"
    {...props}
  >
    <g stroke="#CE3B4D" strokeLinecap="round" strokeWidth={2}>
      <path d="M1 1.5h25M1 17.5h25M1 9.5h25M1 25.5h25" />
    </g>
  </svg>
);
export default SvgMobileMenuBars;
