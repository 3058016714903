import { createFileRoute } from '@tanstack/react-router';
import { AuthGuard } from '@onesource/components';
import { SeriesDetails } from '../../pages/series-details';
import { PageHeader } from '../../components/page-header';

export const Route = createFileRoute('/series/$seriesId')({
  component: () => (
    <AuthGuard>
      <>
        <PageHeader title="Focussed Fitness Series" />
        <SeriesDetails />
      </>
    </AuthGuard>
  ),
});
