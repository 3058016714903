import { Cardio, Restore, Strength, YogaPose } from '@onesource/icons';
import { FC, useCallback, useMemo } from 'react';
import { FeaturedNav } from '../components/featured-nav';
import { InstructorSection } from '../components/instructor-section';
import { MostViewed } from '../components/most-viewed';
import { FocusedFitnessSection } from '../components/sections/focused-fitness';
import { Leaderboard } from '../components/sections/leaderboard';
import { RecentlyAdded } from '../components/sections/recently-added';
// import { OnlineMembersSection } from '../components/sections/online-members';
import { useTheme } from '@mui/material';
import { OneSourceSlideShow, cleanedFilters } from '@onesource/components';
import { contentFilterDefaultValues } from '@onesource/schemas';
import { useRouter } from '@tanstack/react-router';
import { useGetContentsForTodayQuery } from '@onesource/services';
import { MonthChallenge } from '../components/sections/months-challange';

export const Home: FC = () => {
  const theme = useTheme();
  const { navigate } = useRouter();

  const { data: classForToday } = useGetContentsForTodayQuery();

  const contentForTodayMemo = useMemo(() => {
    if (!classForToday?.items?.length) {
      return null;
    }

    return classForToday?.items?.[0];
  }, [classForToday]);

  const onClickCategoryHandler = useCallback(
    (categoryId: string) => {
      const cleaned = cleanedFilters({ ...contentFilterDefaultValues, categoryId });
      navigate({
        to: '/classes/by-category',
        search: cleaned,
      });
    },
    [cleanedFilters, navigate],
  );

  return (
    <>
      <OneSourceSlideShow app="fit" />
      <FeaturedNav
        sx={{ mt: { xs: 1, md: 7 } }}
        sectionTitle="Workout. Feel Good. Repeat."
        sectionSubtitle="Give your body the movement it craves."
        nav={[
          {
            name: 'STRENGTH',
            selected: false,
            onClick: onClickCategoryHandler,
            startIcon: <Strength color={theme.palette.grey[800]} width="42px" height="42px" />,
          },
          {
            name: 'FLOW',
            selected: false,
            onClick: onClickCategoryHandler,
            startIcon: <YogaPose color={theme.palette.grey[800]} width="42px" height="42px" />,
          },
          {
            name: 'CARDIO',
            selected: false,
            onClick: onClickCategoryHandler,
            startIcon: <Cardio color={theme.palette.grey[800]} width="42px" height="42px" />,
          },
          {
            name: 'RESTORE',
            selected: false,
            onClick: onClickCategoryHandler,
            startIcon: <Restore color={theme.palette.grey[800]} width="42px" height="42px" />,
          },
        ]}
      />

      {contentForTodayMemo && (
        <MonthChallenge
          title={contentForTodayMemo.content.name}
          subtitle={`${contentForTodayMemo.content.instructor.firstName} ${contentForTodayMemo.content.instructor.lastName}`}
          description={contentForTodayMemo.content.video.description}
          buttonLabel="Show all"
          buttonLink="/classes/by-date"
          cardMedia={{
            image: contentForTodayMemo.content.video.bannerUrl2x || contentForTodayMemo.content.video.bannerUrl || '',
            sx: {
              borderRadius: '30px 0 30px 30px',
            },
          }}
        />
      )}
      <InstructorSection
        title="All the ways you love to move"
        subtitle="Meet the Instructors team
Our certified instructors have a class for any mood you’re in"
        buttonLabel="Meet All"
        buttonLink="/instructors"
      />
      <RecentlyAdded title="New to the Platform" buttonLabel="See all" buttonLink="/classes/all" />
      <FocusedFitnessSection
        title="Curated Collections"
        description="Each collection has been thoughtfully curated to meet your unique fitness goals or needs, offering a tailored approach to your success."
        buttonLink="/series"
        buttonLabel="See all"
      />
      <MostViewed sectionTitle="Most Popular" />
      {/* <OnlineMembersSection sectionTitle="Who's Online" /> */}
      <Leaderboard title="Leaderboard" />
    </>
  );
};
