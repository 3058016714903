import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { SlArrowRight } from 'react-icons/sl';
import Button, { ButtonProps } from '@mui/material/Button';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { SxProps, darken } from '@mui/material';
import { theme } from '@onesource/theme';
import { Content, Slideshow } from '@onesource/components';
import { TContent } from '@onesource/schemas';
import { useGetSubscriptionQuery } from '@onesource/services';

export type TRecentlyAddedProps = {
  sectionTitle: string;
  actionButton?: ButtonProps;
  contents: TContent[];
  sx?: SxProps;
  sliderSettings?: {};
};

export const RelatedPosts: FC<TRecentlyAddedProps> = props => {
  const { sectionTitle, actionButton, sx, contents, sliderSettings } = props;

  const { isSubscriptionActive } = useGetSubscriptionQuery();

  return (
    <Box sx={sx}>
      <Container maxWidth="xl" sx={{ mt: 1 }}>
        <Grid container spacing={{ xs: 2, md: 4 }}>
          <Grid item xs={12}>
            <Typography
              variant="h2"
              sx={{
                mb: { xs: 0, md: 1.5 },
                textAlign: 'center',
                color: darken(theme.palette.common.white, 0.799),
                lineHeight: '1.5',
              }}
            >
              {sectionTitle}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              mt: 2,
              '& .slick-slider': { width: { xs: '100%', md: 'calc(100% - 64px)' }, margin: 'auto' },
              '& .slick-track': { display: 'flex', gap: { xs: 2, sm: 4, md: 5 }, alignItems: 'center', pb: 2 },
            }}
          >
            <Slideshow
              slides={contents}
              renderSlide={post => <Content isLocked={!isSubscriptionActive} content={post} key={post.id} />}
              settings={sliderSettings}
            />
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'center', mt: { xs: 0, md: 2 } }}>
            {actionButton && (
              <Button
                endIcon={
                  <Box
                    sx={{
                      background: theme.palette.primary.main,
                      borderRadius: '100%',
                      padding: '14px',
                      lineHeight: 0,
                    }}
                  >
                    <SlArrowRight
                      style={{
                        height: '17px',
                        width: '17px',
                      }}
                    />
                  </Box>
                }
                {...actionButton}
                sx={{ marginBottom: { xs: 4, md: 8 } }}
              >
                {actionButton.children}
              </Button>
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
