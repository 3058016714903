import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { darken } from '@mui/material/styles';
import { ellipsis } from '../../../utils/ellipsis';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { useGetByDevice } from '@onesource/components';

type TItem = {
  id: string;
  label: string;
};

export interface ISidebarCheckboxGroupFilterProps<T extends TItem> {
  onChange: (name: string, value: string) => void;
  items: T[];
  name: string;
  value: string[];
}

export function SidebarFilterCheckboxGroup<T extends TItem>(props: ISidebarCheckboxGroupFilterProps<T>) {
  const { items, onChange, name, value = [] } = props;
  const { isMobile } = useGetByDevice();

  return (
    <FormGroup>
      <Grid container spacing={1}>
        {items.map(option => (
          <Grid key={option.id} xs={6} item>
            <Tooltip title={option.label}>
              <FormControlLabel
                value={value.includes(option.id)}
                onChange={() => onChange(name, option.id)}
                checked={value.includes(option.id)}
                sx={{
                  maxWidth: '100%',
                  '& .MuiFormControlLabel-label': {
                    ...ellipsis,
                    color: theme => theme.palette.grey[800],
                    fontSize: { xs: '14px', md: '16px' },
                    fontWeight: 400,
                  },
                }}
                control={
                  <Checkbox
                    size={isMobile ? 'small' : 'medium'}
                    sx={{
                      color: theme => darken(theme.palette.common.black, 0.799),
                    }}
                  />
                }
                label={option.label}
              />
            </Tooltip>
          </Grid>
        ))}
      </Grid>
    </FormGroup>
  );
}
