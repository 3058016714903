import { FC } from 'react';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export type CommentProps = {
  avatarUrl: string;
  username: string;
  date: string;
  comment: string;
};

export const Comment: FC<CommentProps> = ({ avatarUrl, username, date, comment }) => (
  <Grid container spacing={2} sx={{ mb: 3 }}>
    <Grid item>
      <Avatar src={avatarUrl} alt={username} />
    </Grid>
    <Grid item xs>
      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
        {username}{' '}
        <Typography variant="caption" sx={{ color: 'gray', ml: 1 }}>
          {date}
        </Typography>
      </Typography>
      <Typography variant="body2" sx={{ mt: 1 }}>
        {comment}
      </Typography>
      {/* <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
        <IconButton size="small" color="primary">
          <FaRegThumbsUp />
        </IconButton>
        <IconButton size="small" color="primary">
          <FaRegThumbsDown />
        </IconButton>
      </Box> */}
    </Grid>
  </Grid>
);
