import { SxProps, alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Closeicon } from '@onesource/icons';
import { TEvent } from '@onesource/schemas';
import { theme } from '@onesource/theme';
import Player from '@vimeo/player';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { FaInfo } from 'react-icons/fa6';

export type VideoSectionProps = {
  event: TEvent;
  sx?: SxProps;
};

export const LiveClass: FC<VideoSectionProps> = props => {
  const { event, sx } = props;
  const [isRatinngDialogOpen, setRatingDialogOpen] = useState(false);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (iframeRef.current) {
      const player = new Player(iframeRef.current);

      player.on('ended', () => {
        setRatingDialogOpen(true);
      });

      return () => {
        player.off('ended');
      };
    }
  }, []);

  const handleClose = useCallback(() => {
    setRatingDialogOpen(false);
  }, []);

  // const handleRatingChange = (indicatorKey: string, newRating: number) => {
  //   const updatedContent = {
  //     ...content,
  //     rating: content.rating.map(item => (item.key === indicatorKey ? { ...item, point: newRating } : item)),
  //   };
  //   console.log(updatedContent);
  // };

  return (
    <Box sx={{ ...sx }}>
      <Box sx={{ position: 'relative', zIndex: 1 }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Box sx={{ mb: 4 }}>
              <Card
                raised
                sx={{
                  position: 'relative',
                  borderRadius: '30px',
                  margin: '0 auto',
                  boxShadow: 'none',
                  overflow: 'hidden',
                  maxWidth: '100%',
                  background: 'transparent',
                  padding: '58.25% 0 0 0',
                }}
              >
                <CardMedia
                  component="iframe"
                  src={event.videoStreamUrl}
                  allow="autoplay"
                  ref={iframeRef}
                  sx={{
                    maxHeight: '700px',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: '100%',
                    width: '100%',
                    border: 0,
                    borderRadius: 'inherit',
                  }}
                />
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Modal Popup */}
      <Modal
        open={isRatinngDialogOpen}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 776,
            height: 267,
            bgcolor: 'background.paper',
            borderRadius: '10px',
            boxShadow: 24,
            background: alpha(theme.palette.primary.main, 0.15),
            display: 'flex',
            gap: 2,
            justifyContent: 'space-around',
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              color: theme.palette.grey[500],
            }}
          >
            <Closeicon style={{ fontSize: '32px' }} />
          </IconButton>

          <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column', flex: 1, px: 2, py: 3 }}>
            <Typography variant="h2" sx={{ fontSize: '40px' }}>
              Congratulation for doing this great class!
            </Typography>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mt: 3 }}>
              <Box
                sx={{
                  display: 'block',
                  alignItems: 'center',
                  backgroundColor: theme.palette.common.white,
                  borderRadius: '100%',
                  height: '45px',
                  width: '45px',
                  position: 'relative',
                }}
              >
                <FaInfo
                  style={{
                    fontSize: '16px',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                />
              </Box>
              <Typography variant="body2">
                Now you can add your <strong>rating!</strong>
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              flex: 1,
              borderRadius: '30px',
              background: '#f7f7f7',
              boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.05)',
              padding: '45px',
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              width="100%"
              sx={{ display: 'flex', flexDirection: 'column', gap: 3, justifyItems: 'space-between' }}
            >
              {/* {content.rating.map(item => (
                <RequirementIndicator
                  indicator={item}
                  key={item.key}
                  showName={true}
                  isInteractive={true}
                  onChange={newRating => handleRatingChange(item.key, newRating)}
                />
              ))} */}
            </Stack>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};
