import CardActions from '@mui/material/CardActions';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { darken, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Calender, Clock, MvPlay } from '@onesource/icons';
import { IndicatorEnum, TContentByDate } from '@onesource/schemas';
import { ellipsis, formatDate } from '@onesource/utils';
import { Link } from '@tanstack/react-router';
import { FC } from 'react';
import { ContentCategoryBadge } from '../../content-category-badge';
import { LockedCardOverlay } from '../../overlays/locked-card';
import { RequirementIndicator } from '../../requirement-indicator';

type IProps = {
  contentWithDate: TContentByDate;
  isLocked: boolean;
};

export const HorizontalPostMobileView: FC<IProps> = props => {
  const { contentWithDate, isLocked } = props;
  const { content, activeOn } = contentWithDate;
  const { category, equipments, averageRating } = content;
  const theme = useTheme();

  return (
    <Box sx={{ position: 'relative' }}>
      <Card
        sx={{ borderRadius: 6, boxShadow: 'rgba(0, 0, 0, 0.08) 0px 11px 13px 2px, rgba(0, 0, 0, 0.035) 0px 2px 2px' }}
      >
        <CardContent sx={{ p: 1 }}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <CardMedia
                    component="img"
                    sx={{
                      width: '95px',
                      height: '95px',
                      borderRadius: '100%',
                    }}
                    image={content.video.bannerUrl}
                  />
                </Grid>
                <Grid item xs={6.5}>
                  <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Box>
                        <ContentCategoryBadge size="medium" category={category} sx={{ boxShadow: 0 }} />
                      </Box>
                      <Box sx={{ display: 'flex' }}>
                        <Calender fontSize={20} color={theme.palette.primary.main} />
                        <Typography variant="body2" sx={{ ml: 0.7 }}>
                          {formatDate(activeOn)}
                        </Typography>
                      </Box>
                    </Box>
                    <Box py={1.5}>
                      <Typography gutterBottom variant="body1" sx={{ ...ellipsis }}>
                        {content.name.substring(0, 55)}
                      </Typography>

                      <Typography variant="body2" color="text.secondary">
                        {content.instructor.firstName} {content.instructor.lastName}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={1.5} sx={{ display: 'flex', mb: 1.5 }}>
                  {content.type === 'VIDEO' && (
                    <Link
                      to={`/classes/details/${content.id}`}
                      style={{
                        textDecoration: 'none',
                        display: 'flex',
                        alignItems: 'flex-end',
                      }}
                    >
                      <IconButton
                        sx={{
                          width: '35px',
                          height: '35px',
                          color: theme => theme.palette.common.white,
                          background: `linear-gradient(95.28deg, ${theme.palette.primary.main} 36.31%, ${theme.palette.primary.light} 57.37%)`,
                        }}
                      >
                        <MvPlay />
                      </IconButton>
                    </Link>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions
          sx={{
            background: theme => darken(theme.palette.grey[100], 0.01),
            padding: '11px',
            border: theme => `1px solid ${theme.palette.grey[100]}`,
          }}
        >
          <Box
            sx={{
              width: '100%',
            }}
          >
            <Grid container spacing={0.7}>
              <Grid item>
                <Chip
                  label={`${content.video.duration} m`}
                  sx={{
                    p: 0.2,
                    backgroundColor: theme => theme.palette.grey[800],
                    color: 'white',
                  }}
                  size="small"
                  icon={<Clock fontSize={18} color={theme.palette.common.white} />}
                />
              </Grid>

              {equipments.map(item => (
                <Grid item key={item.slug}>
                  <Chip
                    key={item.slug}
                    label={item.name}
                    sx={{
                      p: 0.2,
                      backgroundColor: theme => theme.palette.grey[800],
                      color: 'white',
                    }}
                    size="small"
                  />
                </Grid>
              ))}
            </Grid>
            <Stack direction="row" spacing={1} sx={{ my: 1.5, justifyContent: 'space-between', alignItems: 'center' }}>
              <Stack direction="row" justifyContent="space-between" width="100%">
                <RequirementIndicator
                  indicator={IndicatorEnum.difficulty}
                  point={averageRating.difficulty}
                  isInteractive={false}
                  fontSize={20}
                />

                <RequirementIndicator
                  indicator={IndicatorEnum.intensity}
                  point={averageRating.intensity}
                  isInteractive={false}
                  fontSize={20}
                />

                <RequirementIndicator
                  indicator={IndicatorEnum.difficulty}
                  point={averageRating.nerdiness}
                  isInteractive={false}
                  fontSize={20}
                />
              </Stack>
            </Stack>
          </Box>
        </CardActions>
      </Card>

      <LockedCardOverlay isLocked={isLocked} />
    </Box>
  );
};
