import { FC } from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { TParticipant } from './participants.type';

export type TParticipantProps = {
  participant: TParticipant;
};

export const Participant: FC<TParticipantProps> = props => {
  const { participant } = props;

  return (
    <Box sx={{ display: 'flex', gap: 2 }}>
      <Avatar src={participant.avatarUrl} alt={participant.username} sx={{ width: '73px', height: '73px' }} />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <Typography variant="body2">{participant.username}</Typography>
          {/* <Box
                sx={{
                  height: '11px',
                  width: '11px',
                  borderRadius: '100%',
                  background: participant.online
                    ? theme.palette.primary.main
                    : darken(theme.palette.common.white, 0.23),
                }}
              ></Box> */}
        </Box>
        <Box sx={{ display: 'flex', gap: 1 }}>
          {/* <Location style={{ fontSize: '19px', color: theme.palette.primary.main }} /> */}
          {/* <Typography variant="body2">{participant.location}</Typography> */}
        </Box>
      </Box>
    </Box>
  );
};
