import { FC } from 'react';
import { RiErrorWarningLine } from 'react-icons/ri';

import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { alpha, darken, SxProps, useTheme } from '@mui/material';

type TNotfoundCardProps = {
  entity: string;
  size?: string;
  sx?: SxProps;
};

export const NotFoundCard: FC<TNotfoundCardProps> = props => {
  const { entity, size, sx } = props;
  const sm = size === 'sm';
  const iconSize = sm ? 45 : 90;
  const textSize = sm ? 15 : 20;
  const theme = useTheme();

  const title = `no ${entity} found`;

  return (
    <Card sx={{ boxShadow: 'none', ...sx }}>
      <CardContent
        sx={{
          backgroundColor: darken(alpha(theme.palette.grey[500], 0.2), 0.02),
        }}
      >
        <Grid container alignItems="center" justifyContent="center" p={sm ? 1 : 2}>
          <Grid item xs={12} textAlign="center">
            <RiErrorWarningLine size={iconSize} color={theme.palette.grey[500]} />
            <Typography
              sx={{
                fontSize: textSize,
                color: theme.palette.grey[500] ?? 'text.secondary',
                textAlign: 'center',
              }}
            >
              {title}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
