import { lighten, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Calender, Clock } from '@onesource/icons';
import { TPlaylistItem } from '@onesource/schemas';
import { ellipsis, formatDate } from '@onesource/utils';
import { FC, MouseEvent, useCallback } from 'react';
import { HiDotsVertical } from 'react-icons/hi';
import { IoMdRemoveCircle } from 'react-icons/io';
import { MdDriveFileMove } from 'react-icons/md';
import { ContentCategoryBadge } from '../../content-category-badge';
import { useContextMenuService } from '../../context-menu';

type TContentSmallCardProps = {
  playlistItem: TPlaylistItem;
  selected?: boolean;
  onClick?: (playlistItem: TPlaylistItem) => void;
  onRemove: (params: TPlaylistItem) => void;
  onMove: (item: TPlaylistItem) => void;
};

export const ContentCardSmall: FC<TContentSmallCardProps> = props => {
  const { playlistItem, onMove, onRemove, onClick, selected } = props;
  const { category, instructor, name } = playlistItem.content;
  const { setContextMenu } = useContextMenuService();
  const theme = useTheme();

  const onClickContextMenuHandler = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setContextMenu({
        anchorEl: event.currentTarget,
        items: [
          {
            id: 'remove',
            label: 'Remove from playlist',
            icon: <IoMdRemoveCircle color={theme.palette.primary.main} size={24} />,
            color: 'info' as const,
            context: playlistItem,
            onClick: () => onRemove(playlistItem),
          },
          {
            id: 'move',
            label: 'Move to another playlist',
            icon: <MdDriveFileMove color={theme.palette.primary.main} size={24} />,
            color: 'info' as const,
            context: playlistItem,
            onClick: () => onMove(playlistItem),
          },
        ],
      });
    },
    [playlistItem, setContextMenu],
  );

  return (
    <Card sx={{ boxShadow: 'none', cursor: onClick ? 'pointer' : 'auto' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Grid
          container
          spacing={{ xs: 1, md: 2 }}
          alignItems="center"
          sx={{
            py: 1,
            overflow: 'hidden',
          }}
        >
          <Grid item xs={6} onClick={() => onClick?.(playlistItem)}>
            <Box sx={{ position: 'relative' }}>
              <CardMedia
                component="img"
                sx={{
                  borderRadius: 3,
                }}
                image={playlistItem.content.video.bannerUrl}
              />

              <ContentCategoryBadge
                sx={{
                  position: 'absolute',
                  top: 5,
                  left: 5,
                }}
                category={category}
                size="small"
              />

              <Box
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  display: 'flex',
                  boxSizing: 'border-box',
                  gap: 1,
                  width: '100%',
                  justifyContent: 'space-between',
                  background: lighten(theme.palette.primary.light, 0.9),
                  borderRadius: '0 0 11px 11px',
                  padding: '2px 8px',
                }}
              >
                <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
                  <Clock style={{ fontSize: '10px', color: theme.palette.primary.main }} />
                  <Typography variant="body2" sx={{ fontSize: '9px' }}>
                    {playlistItem.content.video.duration} m
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
                  <Calender style={{ fontSize: '10px', color: theme.palette.primary.main }} />
                  <Typography variant="body2" sx={{ fontSize: '9px' }}>
                    {formatDate(playlistItem.content.publishedDate)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={5} onClick={() => onClick?.(playlistItem)}>
            <Typography
              fontSize={14}
              color={selected ? 'primary.main' : 'common.black'}
              variant="body1"
              sx={{ ...ellipsis }}
            >
              {name}
            </Typography>

            <Typography variant="body2" fontSize={12} color={selected ? 'primary.main' : ''}>
              {instructor.firstName} {instructor.lastName}
            </Typography>
          </Grid>

          <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton
              onClick={onClickContextMenuHandler}
              size="small"
              sx={{
                color: theme.palette.common.black,
                '&:hover': {
                  background: theme.palette.primary.main,
                  color: theme.palette.common.white,
                },
              }}
            >
              <HiDotsVertical fontSize={18} />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};
