import Box from '@mui/material/Box';
import { SxProps } from '@mui/material/styles';
import { ReactNode } from 'react';
import { FormProvider as RhfFormProvider, UseFormReturn, FieldValues } from 'react-hook-form';
import { RHFTextField } from '../rhf-textfield';
import { RHFSelect } from '../rhf-select';
import { RHFSubmitBtn } from '../rhf-submit-btn';
import { RHFCheckbox } from '../rhf-checkbox';
import { RHFDatePicker } from '../rhf-date-picker';
import { RHFAutoComplete } from '../rhf-autocomplete';
import { RHFDateTimePicker } from '../rhf-date-time-picker';

export type TProps<T extends FieldValues> = {
  children: ReactNode;
  methods: UseFormReturn<T>;
  onSubmit: VoidFunction;
  sx?: SxProps;
};

export const FormProvider = <T extends FieldValues>(props: TProps<T>) => {
  const { children, methods, onSubmit, sx } = props;

  return (
    <Box sx={sx}>
      <RhfFormProvider {...methods}>
        <form onSubmit={onSubmit}>{children}</form>
      </RhfFormProvider>
    </Box>
  );
};

FormProvider.RHFAutoComplete = RHFAutoComplete;
FormProvider.RHFCheckbox = RHFCheckbox;
FormProvider.RHFDatePicker = RHFDatePicker;
FormProvider.RHFDateTimePicker = RHFDateTimePicker;
FormProvider.RHFSelect = RHFSelect;
FormProvider.RHFSubmitBtn = RHFSubmitBtn;
FormProvider.RHFTextField = RHFTextField;
