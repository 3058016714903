import { createFileRoute } from '@tanstack/react-router';
import { LiveClassroom } from 'pages/live-classroom';
import { object, string } from 'zod';

const RouteParamSchema = object({
  eventId: string().uuid().min(1, 'eventId is required'),
});

export const Route = createFileRoute('/_protected/events/$eventId')({
  component: () => <LiveClassroom />,
  parseParams: params => {
    return RouteParamSchema.parse(params);
  },
});

export const useEventIdSearch = Route.useSearch;
export const useEventIdParams = Route.useParams;
