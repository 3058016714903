import { createFileRoute } from '@tanstack/react-router';
import { AllInstructors } from '../../pages/instructors';
import { AuthGuard } from '@onesource/components';
import { PageHeader } from '../../components/page-header';

export const Route = createFileRoute('/instructors/')({
  component: () => (
    <AuthGuard>
      <>
        <PageHeader title="Instructors" />
        <AllInstructors />
      </>
    </AuthGuard>
  ),
});
