import type { SVGProps } from 'react';
const SvgClock = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    fontSize="1rem"
    {...props}
  >
    <path
      fill="currentColor"
      d="M9.985 20C4.495 20.002.004 15.53 0 10.057-.004 4.484 4.447-.001 9.981 0 15.528 0 20.008 4.483 20 10.023 19.992 15.55 15.525 19.998 9.984 20m-8.589-9.997c.01 4.763 3.854 8.611 8.595 8.603 4.777-.008 8.625-3.864 8.613-8.632a8.6 8.6 0 0 0-8.618-8.579c-4.755.011-8.6 3.864-8.59 8.607"
    />
    <path
      fill="currentColor"
      d="M9.302 7.668c0-.726.018-1.454-.005-2.18-.019-.558.342-.84.708-.838s.709.296.7.85c-.021 1.33-.004 2.66-.01 3.989 0 .156.04.268.153.38.806.797 1.603 1.602 2.408 2.4.222.221.307.476.207.772-.164.489-.773.624-1.153.253-.447-.436-.885-.882-1.327-1.324-.472-.472-.942-.948-1.419-1.416a.84.84 0 0 1-.265-.642c.007-.747.003-1.496.003-2.244"
    />
  </svg>
);
export default SvgClock;
