import { SxProps } from '@mui/material/styles';
import { IPaginationNew, TContent, TPlaylist } from '@onesource/schemas';
import Player from '@vimeo/player';
import { FC, useEffect, useMemo, useRef } from 'react';
import { VideoPlayer } from '../video-player';

export type TVimeoPlayerProps = {
  content: TContent;
  onVideoEnd?: (videoId: string) => void;
  onMarkComplete?: (videoId: string) => void;
  sx?: SxProps;
  playlists?: IPaginationNew<TPlaylist>;
  isPendingUpdatePlaylist?: boolean;
  onClickCreate?: () => void;
};

export const VimeoPlayer: FC<TVimeoPlayerProps> = props => {
  const { content, onVideoEnd, onMarkComplete } = props;

  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (iframeRef.current) {
      const player = new Player(iframeRef.current);

      player.on('progress', data => {
        if (data.percent * 100 > 80) {
          onMarkComplete?.(content.id);
        }
      });

      player.on('ended', () => {
        onVideoEnd?.(content.id);
      });

      return () => {
        player.off('ended');
      };
    }
  }, []);

  const videoPlayerProps = useMemo(() => ({ ...props, iframeRef }), []);

  return <VideoPlayer {...videoPlayerProps} />;
};
