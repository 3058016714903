import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from '../axios';
import { ENTITY } from './entity';
import { enqueueSnackbar } from 'notistack';
import { getAllPlaylistsQueryKey } from './use-get-all-playlist-query';

type TAddContentToPlaylistPayload = {
  playlistId: string;
  contentId: string;
};

export const useAddContentToPlaylistMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: TAddContentToPlaylistPayload) => {
      return axios.post(`${ENTITY}/add`, data);
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getAllPlaylistsQueryKey(),
      });

      enqueueSnackbar(`Adding to playlist successful!`, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    },
    onError: () => {
      enqueueSnackbar('Unable to add content to playlist', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    },
  });
};
