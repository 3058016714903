import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { TContent } from '@onesource/schemas';
import axios from '../axios';
import { getContentQueryKey } from '../content/get-content';
import { getContentsQueryKey } from '../content/get-contents';
import { ENTITY } from './entity';

export type TConetntCompletePayload = {
  contentId: string;
};

export const useCompleteContentMutation = () => {
  const queryPatient = useQueryClient();

  // this is the mutationFn that contains the actual request. it is expected that the single content data will be returned after a successful mutation
  const mutationFn = useCallback(async (payload: TConetntCompletePayload) => {
    return axios.post<TContent>(`${ENTITY}/complete`, payload);
  }, []);

  return useMutation({
    mutationFn,
    onSuccess: async ({ data }) => {
      // on success we need to obsolete the old content data for the feed and in single content view, this is why here invalidateQueries function is used
      // in the future we will try to update the cache directly from here without firing an extra request however that improvement will cost some exrta work and testing effort
      await queryPatient.invalidateQueries({ queryKey: getContentsQueryKey() });
      await queryPatient.invalidateQueries({ queryKey: getContentQueryKey(data.id) });
    },
    onError: error => {
      console.error(error);
    },
  });
};
