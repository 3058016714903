import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { Calender, Clock } from '@onesource/icons';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material';
import { darken } from '@mui/material';
import { TContentByDate } from '@onesource/schemas';
import SvgPlayButtonWhite from '@onesource/icons/compiled/play-button-white';
import { ContentCategoryBadge } from '../../content-category-badge';
import { Link } from '@tanstack/react-router';
import { formatDate } from '@onesource/utils';

type IProps = {
  scheduledContent: TContentByDate;
};

export const FeaturedClass: FC<IProps> = props => {
  const {
    scheduledContent,
    scheduledContent: { content },
  } = props;
  const theme = useTheme();

  return (
    <Grid
      container
      spacing={4}
      sx={{
        background: theme => theme.palette.common.white,
        position: 'relative',
        pb: 8,
        pt: 5,
        mt: 0,
      }}
    >
      <Grid item md={6} xs={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Typography variant="h2" sx={{ mb: 3 }}>
          Class of the day
        </Typography>
        <Stack direction="row" spacing={2} gap={5} sx={{ mb: 2 }}>
          <Typography
            sx={{
              fontSize: '13px',
              fontWeight: '400',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: '6px',
            }}
          >
            <Clock fontSize={20} color={theme.palette.primary.main} />
            {`${scheduledContent.content.video.duration} m`}
          </Typography>
          <Typography
            sx={{
              fontSize: '13px',
              fontWeight: '400',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: '6px',
            }}
          >
            <Calender fontSize={20} color={theme.palette.primary.main} />
            {formatDate(scheduledContent.activeOn)}
          </Typography>
        </Stack>
        <Typography
          variant="body1"
          sx={{
            mb: 1.5,
            color: darken(theme.palette.common.white, 0.799),
          }}
        >
          {content.name}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            mb: 2.5,
            color: darken(theme.palette.common.white, 0.799),
          }}
        >
          {content.instructor.firstName} {content.instructor.lastName}
        </Typography>
        <Divider style={{ marginBottom: '14px' }} />
        <Typography
          variant="body2"
          sx={{
            mb: 4,
            color: darken(theme.palette.common.white, 0.799),
            lineHeight: 1.8,
          }}
        >
          <span dangerouslySetInnerHTML={{ __html: content.video.description }} />
        </Typography>
        <Box sx={{ width: '100%' }}>
          {content.equipments.map(item => (
            <Chip
              key={item.slug}
              label={item.name}
              sx={{
                mr: 1,
                px: 2,
                height: '28px',
                backgroundColor: theme => theme.palette.grey[800],
                color: 'white',
              }}
              size="small"
            />
          ))}
        </Box>
      </Grid>
      <Grid item md={6} xs={12} sx={{ position: 'relative' }}>
        <Box
          sx={{
            position: 'absolute',
            zIndex: 0,
            background: theme.palette.primary.main,
            width: '100%',
            height: '100%',
            top: 0,
            right: 0,
            bottom: 0,
            left: '50%',
            borderRadius: '30px 0 0 30px',
          }}
        />
        <Card sx={{ pt: 5.5, pb: 9.5, background: 'transparent', position: 'relative', zIndex: 1, boxShadow: 0 }}>
          <Box sx={{ position: 'absolute', top: '65px', left: '10px' }}>
            <ContentCategoryBadge category={content.category} />
          </Box>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '70px',
              height: '70px',
              borderRadius: 6,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Link to={`/classes/details/${content.id}`}>
              <SvgPlayButtonWhite fontSize={69} color={theme.palette.common.white} />
            </Link>
          </Box>
          <CardMedia
            component="img"
            width="234"
            image={content.video.bannerUrl2x || content.video.bannerUrl}
            sx={{ borderRadius: '30px' }}
          />
        </Card>
      </Grid>
    </Grid>
  );
};
