import { ListItemIcon } from '@mui/material/';
import MenuItem from '@mui/material/MenuItem';
import { FC, ReactElement } from 'react';
import { ContextMenu } from '../menu';
import { useContextMenuService } from '../service';

export interface IContextMenuProps {
  children: ReactElement;
}

export const ContextMenuProvider: FC<IContextMenuProps> = props => {
  const { children } = props;
  const { contextMenuConfig, closeContextMenu } = useContextMenuService();

  return (
    <>
      {children}

      {contextMenuConfig && (
        <ContextMenu onClose={closeContextMenu} anchorEl={contextMenuConfig?.anchorEl || null}>
          {contextMenuConfig.items.map(config => {
            const { id, icon, onClick, context, ...rest } = config;

            return (
              <MenuItem {...rest} key={id} onClick={() => onClick(context)}>
                <ListItemIcon>{icon}</ListItemIcon>
                {config.label}
              </MenuItem>
            );
          })}
        </ContextMenu>
      )}
    </>
  );
};
