import type { SVGProps } from 'react';
const SvgCloseicon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 33 32"
    fontSize="1rem"
    {...props}
  >
    <g strokeWidth={2}>
      <path stroke="currentColor" strokeLinecap="round" d="M23.602 9.309 9.996 22.915M22.886 22.2 9.87 9.184" />
      <circle cx={16.686} cy={16} r={15} stroke="#333" />
    </g>
  </svg>
);
export default SvgCloseicon;
