import Button from '@mui/material/Button';
import { alpha, capitalize, SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { ContentCategoryBadge } from '@onesource/components';
import { Calender, Clock, VideoIcon } from '@onesource/icons';
import { TContent } from '@onesource/schemas';
import { theme } from '@onesource/theme';
import { formatDate } from '@onesource/utils';
import { FC, RefObject } from 'react';

type IProps = {
  content: TContent;
  sx?: SxProps;
  onClickAddPlaylist?: (anchorEl: HTMLElement, contentId: string) => void;
  iframeRef: RefObject<HTMLIFrameElement> | null;
};

export const VideoPlayer: FC<IProps> = props => {
  const { content, sx, onClickAddPlaylist, iframeRef } = props;

  return (
    <Box sx={sx}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          background: alpha(theme.palette.primary.main, 0.15),
          borderRadius: { xs: '10px 10px 0 0', md: '30px 30px 0 0' },
          padding: { xs: 1.5, md: '15px 30px' },
          mb: { xs: 1, md: 0 },
          boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.05)',
        }}
      >
        <Box sx={{ display: 'flex', gap: { xs: 1, md: 2 }, alignItems: 'center' }}>
          <ContentCategoryBadge
            category={content.category}
            sx={{
              ml: { xs: 0, md: 2 },
              width: '23px',
              height: '23px',
              background: 'transparent',
              boxShadow: 'none',
              '& svg': {
                fontSize: '23px',
                color: theme.palette.primary.main,
              },
            }}
          />
          <Typography variant="body2" sx={{ fontSize: '13px', lineHeight: '2' }}>
            {capitalize(content.category.toLowerCase())}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: { xs: 1, md: 2 }, alignItems: 'center' }}>
          <Clock style={{ fontSize: '20px', color: theme.palette.primary.main }} />
          <Typography variant="body2" sx={{ fontSize: '13px', lineHeight: '2' }}>
            {content.video.duration} m
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: { xs: 1, md: 2 }, alignItems: 'center' }}>
          <Calender style={{ fontSize: '20px', color: theme.palette.primary.main }} />
          <Typography variant="body2" sx={{ fontSize: '13px', lineHeight: '2' }}>
            {formatDate(content.publishedDate)}
          </Typography>
        </Box>

        {onClickAddPlaylist && (
          <Button
            variant="text"
            onClick={event => onClickAddPlaylist(event.currentTarget, content.id)}
            startIcon={<VideoIcon style={{ color: theme.palette.primary.main }} />}
            sx={{
              px: { xs: 1, md: 2 },
              borderRadius: 1.5,
              minWidth: 'auto',
              '&:hover': {
                backgroundColor: theme => alpha(theme.palette.primary.main, 0.1),
              },
              '& .MuiButton-startIcon': {
                marginRight: { xs: -1, md: 2 },
              },
            }}
          >
            <Typography
              variant="body2"
              sx={{ fontSize: '13px', display: { xs: 'none', md: 'block' } }}
              color="common.black"
            >
              Add to playlist
            </Typography>
          </Button>
        )}
      </Box>
      <Box sx={{ textAlign: 'center', mb: { xs: 0, md: 4 }, mt: -2 }}>
        <Card
          raised
          sx={{
            margin: '0 auto',
            boxShadow: 'none',
            overflow: 'hidden',
            maxWidth: '100%',
            background: 'transparent',
            borderRadius: { xs: '0 0 20px 20px', md: '0 0 30px 30px' },
            padding: '58.25% 0 0 0',
            position: 'relative',
          }}
        >
          <CardMedia
            component="iframe"
            src={`https://player.vimeo.com/video/${content.video.vimeoVideoId}`}
            ref={iframeRef}
            allow="fullscreen; picture-in-picture"
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              height: '100%',
              width: '100%',
              border: 0,
              borderRadius: 'inherit',
            }}
          />
        </Card>
      </Box>
    </Box>
  );
};
