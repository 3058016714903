import type { SVGProps } from 'react';
const SvgFullbars = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 46 23"
    fontSize="1rem"
    {...props}
  >
    <g fill="#CE3B4D">
      <rect width={6.179} height={7.062} y={15.938} rx={3.09} />
      <rect width={6.179} height={15.889} x={19.42} y={7.11} rx={3.09} />
      <rect width={6.179} height={12.358} x={9.71} y={10.642} rx={3.09} />
      <rect width={6.179} height={19.42} x={29.13} y={3.58} rx={3.09} />
      <rect width={6.179} height={22.951} x={38.841} y={0.049} rx={3.09} />
    </g>
  </svg>
);
export default SvgFullbars;
