import { useGetInstructorsQuery } from '@onesource/services';
import { Select, TOption } from '../select';

import { SelectChangeEvent, SxProps } from '@mui/material';
import { FC } from 'react';
import { TInstructor } from '@onesource/schemas';

interface ISelectProps {
  selected: string;
  onChange: (name: string, value: string) => void;
  sx?: SxProps;
}

export const InstructorSelect: FC<ISelectProps> = props => {
  const { data } = useGetInstructorsQuery();
  // Need to check with asif vai TInstructor or TOption
  const instructors: TOption[] =
    data?.items.map((instructor: TInstructor) => ({
      id: instructor.id,
      label: `${instructor.firstName} ${instructor.lastName}`,
    })) || [];

  const { selected = '', onChange, sx } = props;

  return (
    <Select
      sx={sx}
      selected={selected}
      name="instructorId"
      label="Instructor"
      onChange={(e: SelectChangeEvent) => onChange('instructorId', e?.target.value)}
      items={instructors}
    />
  );
};
