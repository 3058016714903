import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FC, ReactNode, useCallback } from 'react';
import { IConfirmAtom } from '../atom';

export interface IAlertProps extends Omit<IConfirmAtom, 'message'> {
  onClose: () => void;
  children: ReactNode | ReactNode[];
}

export const AlertDialog: FC<IAlertProps> = props => {
  const { onClose, onConfirm, onCancel, children, open, title, cancelBtnLabel, confirmBtnLabel } = props;

  const onConfirmHandler = useCallback(() => {
    onConfirm();
    onClose();
  }, [onConfirm, onClose]);

  const onDisagreeHandler = useCallback(() => {
    onCancel?.();
    onClose();
  }, [onCancel, onClose]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{children}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button
          variant="outlined"
          sx={{
            minWidth: 'auto',
            color: 'primary.main',
            borderColor: 'primary.main',
            py: 1.1,
            px: 2.7,
          }}
          onClick={onDisagreeHandler}
        >
          {cancelBtnLabel ?? 'Cancel'}
        </Button>
        <Button
          sx={{
            minWidth: 'auto',
            textAlign: 'center',
            py: 1.2,
            px: 7,
          }}
          onClick={onConfirmHandler}
          autoFocus
        >
          {confirmBtnLabel ?? 'Ok'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
