import type { SVGProps } from 'react';
const SvgEnergy = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 19 26"
    fontSize="1rem"
    {...props}
  >
    <path
      fill="currentColor"
      d="m7.349 6.132.305 5.473c1.862-1.107 3.649-.93 5.379.179.349-.342.665-.697 1.023-.995 1.29-1.07 2.73-1.337 4.3-.838.33.105.476.313.41.566-.073.28-.305.39-.647.278-1.814-.594-3.369.187-4.395 1.592.195.308.4.604.574.918.107.193.187.408.245.623.108.403-.002.666-.28.737-.253.065-.408-.104-.529-.514a3 3 0 0 0-1.035-1.525c-1.493-1.183-3.427-1.186-4.932-.024-.109.085-.188.22-.263.342-.077.126-.123.273-.199.4-.129.218-.313.307-.548.193-.222-.107-.325-.36-.179-.595.317-.513.258-1.06.224-1.619a422 422 0 0 1-.256-4.543c-.014-.272-.077-.499-.252-.699-.148-.17-.246-.397-.094-.58.108-.128.32-.199.494-.22.44-.052.893.008 1.244-.396.148-.172.363-.095.524.057q.287.268.564.547c.153.158.292.177.442 0 .53-.627 1.067-1.246 1.583-1.886.065-.081.071-.306.01-.392-.518-.744-1.059-1.473-1.605-2.223l-1.731.833C6.638 2.343 5.55 2.857 4.47 3.393c-.136.067-.284.226-.322.373q-1.475 5.74-2.914 11.49c-.089.357-.076.746-.08 1.12 0 .093.1.242.184.27.716.244 1.282.74 1.89 1.173.92.657 1.841 1.32 2.816 1.877 1.614.92 3.386.96 5.166.834 1.704-.12 3.345-.532 4.943-1.153.582-.226.612-.194.935.342.347.579.743 1.105 1.374 1.377a.423.423 0 0 1 .222.585c-.125.261-.335.302-.572.21-.535-.208-.934-.607-1.296-1.053-.162-.198-.295-.502-.495-.564-.193-.06-.456.13-.688.212l-.52.183a7.8 7.8 0 0 0 1.496 2.128c.57.572 1.22 1.009 1.986 1.23.28.08.41.253.405.555-.005.323.005.646-.006.969-.01.274-.144.446-.419.45-.242.004-.44-.179-.398-.46.085-.571-.167-.792-.637-1.016-1.416-.675-2.357-1.887-3.092-3.289-.125-.24-.232-.297-.492-.24-1.654.363-3.323.553-5.02.423-1.587-.123-3.03-.666-4.358-1.56a40 40 0 0 1-2.116-1.54c-.456-.353-.925-.66-1.46-.862-.518-.195-.66-.47-.688-1.05-.056-1.175.291-2.276.572-3.382.818-3.218 1.668-6.425 2.497-9.64.094-.365.3-.592.618-.745C5.756 1.8 7.508.952 9.262.11c.376-.181.502-.148.758.202q.843 1.15 1.68 2.304c.416.575.399 1.087-.056 1.631q-.78.934-1.572 1.855c-.427.494-1.055.566-1.5.122-.277-.278-.517-.399-.866-.19-.093.055-.214.061-.357.1"
    />
  </svg>
);
export default SvgEnergy;
