import { getSession, setTokenToAxios } from '@onesource/services';
import { FC, ReactElement, useEffect } from 'react';

export type TAuthProviderProps = {
  children: ReactElement;
};

export const AuthProvider: FC<TAuthProviderProps> = props => {
  const { children } = props;

  useEffect(() => {
    const accessToken = getSession();

    if (accessToken) {
      setTokenToAxios(accessToken);
    }
  }, []);

  return children;
};
