import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import MuiSelect, { SelectChangeEvent } from '@mui/material/Select';
import { useGetByDevice } from '../../hooks';

export type TOption = {
  id: string;
  label: string;
};

export interface ISelectProps<T extends TOption> {
  selected: string;
  name: string;
  label: string;
  onChange: (event: SelectChangeEvent) => void;
  items: T[];
  sx?: SxProps;
}

export function Select<T extends TOption>(props: ISelectProps<T>) {
  const { selected, onChange, name, label, items, sx } = props;
  const { isMobile } = useGetByDevice();

  return (
    <Box sx={sx}>
      <FormControl size={isMobile ? 'small' : 'medium'} fullWidth>
        <InputLabel id={`select-${name}-label`}>{label}</InputLabel>
        <MuiSelect
          labelId={`select-${name}-label`}
          id={`select-${name}`}
          value={selected}
          label={label}
          onChange={onChange}
        >
          {items.map(item => (
            <MenuItem sx={{ fontSize: { xs: '14px', md: '16px' } }} key={item.id} value={item.id}>
              {item.label}
            </MenuItem>
          ))}
        </MuiSelect>
      </FormControl>
    </Box>
  );
}
