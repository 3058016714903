import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import Box from '@mui/material/Box';
import { SxProps, useTheme } from '@mui/material';
import { darken } from '@mui/material';
import { Participant } from './participants';
import { TParticipant } from './participants.type';
import { Participants } from '@onesource/icons';
import { motion } from 'framer-motion';

export type TParticipantSectionProps = {
  title: string;
  participants: TParticipant[];
  sx?: SxProps;
};

export const ParticipantSection: FC<TParticipantSectionProps> = props => {
  const { title, participants, sx } = props;
  const theme = useTheme();

  return (
    <Box sx={{ mb: 7, ...sx }}>
      <Box
        sx={{
          backgroundColor: theme.palette.grey[100],
          borderRadius: '30px 30px 0 0',
          p: 3,
          paddingLeft: 3.5,
          display: 'flex',
          gap: 2,
          alignItems: 'center',
        }}
      >
        <Participants style={{ fontSize: '33px' }} />
        <Typography variant="body2" sx={{ fontWeight: '700', color: darken(theme.palette.common.white, 0.799) }}>
          {title} ({participants.length})
        </Typography>
      </Box>

      <Box sx={{ mb: 2, backgroundColor: theme.palette.grey[50], p: 3.5, overflow: 'auto' }}>
        <Grid container spacing={2}>
          {participants.map(participant => (
            <Grid item xs={12} key={participant.id}>
              <motion.div
                initial={{
                  transform: 'translateY(-50px)',
                  transformOrigin: 'center',
                  opacity: 0.3,
                }}
                animate={{
                  transform: 'translateY(0)',
                  transformOrigin: 'center',
                  opacity: 1,
                }}
                transition={{ type: 'easeIn', duration: 0.1 }}
              >
                <Participant participant={participant} />
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};
