import { atom } from 'jotai';

export interface IPlaylistMenuAtom {
  contentId: string | null;
  anchorEl: HTMLElement | null;
  onAddToPlaylist?: (playlistId: string, contentId: string) => void;
  onClickCreate?: () => void;
}

export const playlistMenuAtom = atom<IPlaylistMenuAtom>({
  contentId: null,
  anchorEl: null,
});
