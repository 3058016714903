import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import {
  AddEditPlaylistDialog,
  DataStateHandler,
  PlaylistCard,
  PlaylistCardLoading,
  TOnSaveParams,
  useConfirmService,
} from '@onesource/components';
import { TPlaylist } from '@onesource/schemas';
import {
  useCreatePlaylistMutation,
  useGetAllPlaylistsQuery,
  useEditPlaylistNameMutation,
  useDeletePlaylistMutation,
} from '@onesource/services';
import { createFileRoute } from '@tanstack/react-router';
import { PageHeader } from 'components/page-header';
import { useCallback, useState } from 'react';
import { FaPlus } from 'react-icons/fa6';

export const Route = createFileRoute('/_protected/playlists/')({
  component: PlaylistPage,
});

const PlaylistLoading = () => {
  return (
    <Grid container spacing={2}>
      <Grid xs={12} sm={6} item>
        <PlaylistCardLoading />
      </Grid>
      <Grid xs={12} sm={6} item>
        <PlaylistCardLoading />
      </Grid>
    </Grid>
  );
};

function PlaylistPage() {
  const queryResult = useGetAllPlaylistsQuery();
  const { setConfirm } = useConfirmService();
  const [isAddEditPlaylistDialogOpen, setIsAddEditPlaylistDialogOpen] = useState(false);
  const [playlistContext, setPlaylistContext] = useState<TPlaylist | null>(null);
  const isPlaylistEditMode = Boolean(playlistContext?.id);
  const { mutateAsync: createPlaylist, isPending: isPendingCreateMutation } = useCreatePlaylistMutation();
  const { mutateAsync: editPlaylist, isPending: isPendingEditMutation } = useEditPlaylistNameMutation();
  const { mutateAsync: deletePlaylist, isPending: isPendingDeleteMutation } = useDeletePlaylistMutation();

  const isPending = isPendingCreateMutation || isPendingEditMutation || isPendingDeleteMutation;

  const resetPlaylistContext = useCallback(() => {
    setPlaylistContext(null);
  }, []);

  const handleOpenCreatePlaylist = useCallback(() => {
    setIsAddEditPlaylistDialogOpen(true);
  }, []);

  const handleCloseAddEditPlaylist = useCallback(() => {
    setIsAddEditPlaylistDialogOpen(false);
    resetPlaylistContext();
  }, []);

  const handlePlaylistContextEdit = useCallback((playlist: TPlaylist) => {
    setIsAddEditPlaylistDialogOpen(true);
    setPlaylistContext(playlist);
  }, []);

  const handleDeletePlayList = useCallback(
    async (playlistId: string) => {
      await deletePlaylist(playlistId, {
        onSuccess: handleCloseAddEditPlaylist,
      });
    },
    [playlistContext, isPlaylistEditMode],
  );

  const handlePlaylistDelete = useCallback((playlist: TPlaylist) => {
    setPlaylistContext(playlist);

    setConfirm({
      title: 'Delete Playlist?',
      open: true,
      confirmBtnLabel: 'Removed',
      cancelBtnLabel: 'Cancel',
      message: 'Are you sure you want to remove this playlist?',
      onConfirm: () => {
        resetPlaylistContext();
        handleDeletePlayList(playlist.id);
      },
      onCancel: resetPlaylistContext,
    });
  }, []);

  const handleSavePlayList = useCallback(
    async (params: TOnSaveParams) => {
      const { name } = params;
      const payload = { name, playlistId: playlistContext?.id };

      if (isPlaylistEditMode) {
        await editPlaylist(payload, {
          onSuccess: handleCloseAddEditPlaylist,
        });
        return;
      }

      await createPlaylist(payload, {
        onSuccess: handleCloseAddEditPlaylist,
      });
    },
    [playlistContext, isPlaylistEditMode],
  );

  return (
    <>
      <PageHeader title="Playlists" />
      <Box
        sx={{
          pt: 0,
          pb: 6,
          background: theme => theme.palette.common.white,
          overflow: 'hidden',
        }}
      >
        <Container maxWidth="xl" sx={{ mt: 3 }}>
          <DataStateHandler<TPlaylist>
            queryResult={queryResult}
            entity="Playlist"
            gridProps={{
              sm: 6,
              xs: 12,
            }}
            LoadingComponent={PlaylistLoading}
            IterativeComponent={(props: { item: TPlaylist }) => {
              return (
                <PlaylistCard
                  playlist={props.item}
                  key={props.item.id}
                  onEdit={handlePlaylistContextEdit}
                  onDelete={handlePlaylistDelete}
                />
              );
            }}
          />

          <Stack direction="row" justifyContent="center" mt={4}>
            <Button
              onClick={handleOpenCreatePlaylist}
              endIcon={
                <Box
                  sx={{
                    background: theme => theme.palette.primary.main,
                    borderRadius: '100%',
                    padding: '14px',
                    lineHeight: 0,
                  }}
                >
                  <FaPlus
                    style={{
                      height: '17px',
                      width: '17px',
                    }}
                  />
                </Box>
              }
              sx={{ minWidth: 'auto' }}
            >
              Create a new playlist
            </Button>
          </Stack>
        </Container>
      </Box>

      <AddEditPlaylistDialog
        isPending={isPending}
        open={isAddEditPlaylistDialogOpen}
        onClose={handleCloseAddEditPlaylist}
        onSave={handleSavePlayList}
        formValues={
          playlistContext
            ? {
                name: playlistContext?.name,
              }
            : undefined
        }
      />
    </>
  );
}
