import { FC } from 'react';
import { HeroLiveSlide } from '../slide/hero-live-silde';
import { HeroPlaylistSlide, HeroSlide, THeroSlideProps } from '../slide';

export const HeroSection: FC<THeroSlideProps> = props => {
  const { sectionTitle, sectionSubtitle, sectionDescription, actionButton, bgImg, type, classDate, customize, status } =
    props;
  if (type === 'content' || type === 'regular') {
    return (
      <HeroSlide
        customize={customize}
        sectionTitle={sectionTitle}
        sectionSubtitle={sectionSubtitle}
        sectionDescription={sectionDescription}
        bgImg={bgImg}
        type={type}
        actionButton={actionButton}
      />
    );
  }

  if (type === 'event') {
    return (
      <HeroLiveSlide
        sectionTitle={sectionTitle}
        sectionSubtitle={sectionSubtitle}
        sectionDescription={sectionDescription}
        status={status}
        bgImg={bgImg}
        type={type}
        actionButton={actionButton}
        classDate={classDate}
      />
    );
  }

  if (type === 'series') {
    return (
      <HeroPlaylistSlide
        customize={customize}
        sectionTitle={sectionTitle}
        sectionSubtitle={sectionSubtitle}
        sectionDescription={sectionDescription}
        bgImg={bgImg}
        type={type}
        actionButton={actionButton}
      />
    );
  }

  return null;
};
