import { ThemeProvider } from '@mui/material';
import { AuthProvider, ConfirmProvider, ContextMenuProvider, ProfileInfoProvider } from '@onesource/components';
import { theme } from '@onesource/theme';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { RouterProvider, createRouter } from '@tanstack/react-router';
import { SnackbarProvider } from 'notistack';
import { routeTree } from './routeTree.gen';
import { PlaylistMenuProvider } from 'components/add-to-playlist/provider';

const router = createRouter({ routeTree });

/* WARNING: Do not remove this code. it will mess up the route types */
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

router.subscribe('onLoad', (params: { toLocation: { search: { scrollTop?: string } } }) => {
  if (params.toLocation.search.scrollTop !== '1') {
    window.scrollTo(0, 0);
  }
});

export const queryClient = new QueryClient();

export const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <PlaylistMenuProvider>
            <ContextMenuProvider>
              <ConfirmProvider>
                <ProfileInfoProvider>
                  <SnackbarProvider />
                  <RouterProvider router={router} />
                  <ReactQueryDevtools />
                </ProfileInfoProvider>
              </ConfirmProvider>
            </ContextMenuProvider>
          </PlaylistMenuProvider>
        </AuthProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
};
