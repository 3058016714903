import Skeleton from '@mui/material/Skeleton';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC, memo } from 'react';

export const SliderLoading: FC = memo(() => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: 'relative',
        background: theme => theme.palette.grey[200],
        minHeight: {
          xs: '468px',
          md: '725px',
        },
        pt: { xs: 0, md: 11 },
        '&:before': {
          content: "''",
          position: 'absolute',
          height: '100%',
          width: {
            xs: '100%',
            md: '50%',
          },
          top: 0,
          backgroundImage: `linear-gradient(to left, transparent ,${theme.palette.common.black})`,
        },
      }}
    >
      <Container maxWidth="xl" sx={{ position: 'relative', zIndex: 1, overflow: 'hidden' }}>
        <Grid container spacing={4} sx={{}}>
          <Grid
            item
            md={6}
            xs={12}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              minHeight: {
                xs: '400px',
                md: '725px',
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                minHeight: '350px',
                pt: { xs: 6 },
                pb: { xs: 6 },
              }}
            >
              <Box>
                <Typography
                  variant="h2"
                  sx={{
                    mt: {
                      xs: 0,
                      md: 9,
                    },
                    fontSize: { xs: '30px', md: '48px' },
                    fontWeight: { xs: '700', md: '600' },
                    mb: { xs: 1.5, md: 3 },
                    color: theme => theme.palette.common.white,
                    '& br': { display: { xs: 'none', md: 'block' } },
                  }}
                >
                  <Skeleton />
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    mb: 2,
                    color: theme => theme.palette.common.white,
                  }}
                >
                  <Skeleton />
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    mb: 3,
                    color: theme => theme.palette.common.white,
                    lineHeight: 1.6,
                  }}
                >
                  <Skeleton />
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            md={6}
            sx={{
              display: {
                xs: 'none',
                md: 'flex',
              },
            }}
          ></Grid>
        </Grid>
      </Container>
    </Box>
  );
});
