import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FC } from 'react';

import { ellipsis } from '@onesource/utils';

export const EventCardLoading: FC = () => {
  return (
    <Card
      sx={{
        borderRadius: 4,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        minHeight: '200px',
        boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      }}
    >
      <Grid container spacing={2}>
        <Grid md={4} item>
          <Skeleton variant="rectangular" height="100%" />
        </Grid>

        <Grid md={8} item>
          <CardContent sx={{ p: 3 }}>
            <Box>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="h5" component="h2" sx={{ mb: 1, fontWeight: 'bold', ...ellipsis }}>
                  <Skeleton width={200} height={40} />
                </Typography>

                <Stack spacing={2} direction="row">
                  <Skeleton width={100} />
                  <Skeleton width={100} />
                </Stack>
              </Stack>

              <Stack direction="row">
                <Typography variant="body2" color="textSecondary" mb={0.5} fontStyle="italic">
                  <Skeleton width={100} />
                </Typography>
              </Stack>

              <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </Typography>

              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Stack direction="row" spacing={1} alignItems="center">
                  <Skeleton variant="rectangular" width={30} height={30} />
                  <Typography variant="h6" color="primary.main">
                    <Skeleton width={150} height={30} variant="rectangular" />
                  </Typography>
                </Stack>

                <Skeleton variant="rectangular" width={150} height={40} />
              </Stack>
            </Box>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
};
