import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { useCallback } from 'react';
import axios from '../axios';

type TPayload = {
  username: string;
  password: string;
};

export const useLogin = () => {
  const mutationFn = useCallback(async (payload: TPayload) => axios.post('/auth/login', payload), []);

  return useMutation({
    mutationFn,
    onSuccess: async () => {
      enqueueSnackbar(`Login successful!`, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    },
    onError: () => {
      enqueueSnackbar('Unable to Login', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    },
  });
};
