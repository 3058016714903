import Box from '@mui/material/Box';
import { SxProps } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { TMeta } from '@onesource/schemas';
import { FC } from 'react';
import { TfiAngleLeft, TfiAngleRight } from 'react-icons/tfi';

export interface IPaginationProps {
  meta: TMeta;
  sx?: SxProps;
  onNext: (pageNo: number) => void;
  onPrev: (pageNo: number) => void;
}

export const Pagination: FC<IPaginationProps> = props => {
  const { meta, sx, onNext, onPrev } = props;

  return (
    <Stack direction="row" alignItems="center" sx={sx}>
      <IconButton
        disabled={meta.page === 1}
        sx={{ color: theme => theme.palette.primary.main, mr: 2 }}
        onClick={() => onPrev(meta.page - 1)}
      >
        <TfiAngleLeft size={20} />
      </IconButton>

      <Box width="80px" textAlign="center">
        {meta.page} / {meta.totalPages}
      </Box>

      <IconButton
        sx={{ color: theme => theme.palette.primary.main, ml: 2 }}
        disabled={meta.page === meta.totalPages}
        onClick={() => onNext(meta.page + 1)}
      >
        <TfiAngleRight size={20} />
      </IconButton>
    </Stack>
  );
};
