import { useAtom } from 'jotai';
import { useCallback } from 'react';
import { confirmAtom, IConfirmAtom } from '../atom';

export function useConfirmService() {
  const [confirmConfig, setConfirmConfig] = useAtom(confirmAtom);

  function setConfirm(config: IConfirmAtom) {
    setConfirmConfig(config);
  }

  const closeConfirm = useCallback(() => {
    setConfirmConfig(null);
  }, [setConfirmConfig]);

  return {
    confirmConfig,
    closeConfirm,
    setConfirm,
  };
}
