import { TContent, TError } from '@onesource/schemas';
import { useQuery } from '@tanstack/react-query';
import axios from '../axios';
import { ENTITY } from './entity';

interface IGetContentParams {
  contentId: string;
}

export const getContentQueryKey = (conentId: string) => {
  return [ENTITY, conentId];
};

export const useGetContentQuery = (param: IGetContentParams) => {
  const { contentId } = param;
  return useQuery<TContent, TError>({
    queryKey: getContentQueryKey(contentId),
    queryFn: async () => {
      const res = await axios.get<TContent>(`${ENTITY}/${contentId}`);
      return res.data;
    },
  });
};
