import Typography from '@mui/material/Typography';
import { FC } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { SxProps, alpha, useTheme } from '@mui/material';
import { darken } from '@mui/material';
import { FaPlus } from 'react-icons/fa6';
import { IndicatorEnum, TContent } from '@onesource/schemas';
import { RequirementIndicator, useGetByDevice } from '@onesource/components';
import { FaInfo } from 'react-icons/fa';

export type TRatingsProps = {
  title: string;
  post: TContent;
  openModal: () => void;
  sx?: SxProps;
};

export const Ratings: FC<TRatingsProps> = props => {
  const {
    title,
    sx,
    openModal,
    post: { averageRating },
  } = props;
  const theme = useTheme();
  const { isMobile } = useGetByDevice();

  return (
    <Box sx={{ mb: 7, ...sx }}>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{
          backgroundColor: alpha(theme.palette.primary.main, 0.15),
          borderRadius: '30px 30px 0 0',
          p: 3,
          paddingLeft: 3.5,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            alignItems: 'center',
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: '700', color: darken(theme.palette.common.white, 0.799) }}>
            {title}
          </Typography>
          <IconButton color="primary">
            <FaInfo size="16px" />
          </IconButton>
        </Box>

        <IconButton
          onClick={openModal}
          sx={{
            backgroundColor: theme.palette.common.white,
            color: theme.palette.primary.main,
            '&:hover': {
              backgroundColor: alpha(theme.palette.common.white, 0.65),
            },
          }}
        >
          <FaPlus size="16px" />
        </IconButton>
      </Grid>
      <Stack
        sx={{ mb: 2, backgroundColor: theme.palette.grey[50], p: { xs: 2, md: 3.5 }, borderRadius: '0 0 30px 30px' }}
        gap={2.5}
        direction={isMobile ? 'row' : 'column'}
        justifyContent="space-between"
      >
        <RequirementIndicator
          showName={!isMobile}
          indicator={IndicatorEnum.difficulty}
          point={averageRating.difficulty}
          isInteractive={false}
        />

        <RequirementIndicator
          showName={!isMobile}
          indicator={IndicatorEnum.intensity}
          point={averageRating.intensity}
          isInteractive={false}
        />

        <RequirementIndicator
          showName={!isMobile}
          indicator={IndicatorEnum.nerdiness}
          point={averageRating.nerdiness}
          isInteractive={false}
        />
      </Stack>
    </Box>
  );
};
