import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import Grid from '@mui/material/Grid';
import { TDeleteContentFromPlaylistRequestSchema, TPlaylistItem } from '@onesource/schemas';
import { SortableItem } from 'components/sortable-contents/sortable-item';
import { FC, useCallback } from 'react';

interface ISortableContentProps {
  items: TPlaylistItem[];
  setSortedItems: (contents: TPlaylistItem[]) => void;
  enableDrag?: boolean;
  playlistId: string;
  onChangeOrder?: (event: DragEndEvent) => void;
  onRemove: (params: TDeleteContentFromPlaylistRequestSchema) => void;
  onMove: (item: TPlaylistItem) => void;
}

export const SortableContents: FC<ISortableContentProps> = props => {
  const { items, enableDrag = false, setSortedItems, onRemove, onMove, playlistId, onChangeOrder } = props;
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (active.id !== over?.id) {
        const oldIndex = items.findIndex(item => item.id === active.id);
        const newIndex = items.findIndex(item => item.id === over?.id);
        const sortedContent = arrayMove(items, oldIndex, newIndex);
        setSortedItems(sortedContent);
      }
      onChangeOrder?.(event);
    },
    [setSortedItems, onChangeOrder, items, arrayMove],
  );

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={items} strategy={verticalListSortingStrategy} disabled={!enableDrag}>
        <Grid container spacing={2}>
          {items.map(item => (
            <SortableItem
              onRemove={onRemove}
              onMove={onMove}
              key={item.id}
              item={item}
              enableDrag={enableDrag}
              playlistId={playlistId}
            />
          ))}
        </Grid>
      </SortableContext>
    </DndContext>
  );
};
