import { ReactNode } from 'react';
import { AnimatePresence, Variants, LazyMotion, domAnimation, m } from 'framer-motion';

type TItem<T> = T & { id: string | number };

type TProps<T> = {
  items: TItem<T>[];
  renderItem: (item: TItem<T>) => ReactNode;
  initial?: boolean;
};

const variants: Variants = {
  initial: {
    opacity: 0,
    height: 0,
  },
  animate: {
    opacity: 1,
    height: 'fit-content',
    transition: {
      type: 'tween',
      opacity: { delay: 0.025 },
    },
  },
  exit: {
    opacity: 0,
    height: 0,
  },
};

export function ListAnimation<T>(props: TProps<T>) {
  const { items, renderItem, initial = false } = props;

  return (
    <LazyMotion features={domAnimation}>
      <AnimatePresence initial={initial}>
        {items.map(item => (
          <m.div
            key={item.id}
            layout
            variants={variants}
            initial={'initial'}
            animate={'animate'}
            exit={'exit'}
            transition={{
              duration: 0.15,
              opacity: { duration: 0.03 },
            }}
          >
            {renderItem(item)}
          </m.div>
        ))}
      </AnimatePresence>
    </LazyMotion>
  );
}
