import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import {
  AddEditPlaylistDialog,
  ClassesDataStateHandler,
  useGetByDevice,
  useSetSearchParams,
} from '@onesource/components';
import { Cardio, Restore, Strength, YogaPose } from '@onesource/icons';
import { contentFilterDefaultValues } from '@onesource/schemas';
import { useGetContentsQuery, useGetSubscriptionQuery } from '@onesource/services';
import { theme } from '@onesource/theme';
import { usePlaylistMenuService } from 'components/add-to-playlist/service';
import { usePlaylistAction } from 'hooks/use-playlist-actions';
import { FC, useCallback, useEffect } from 'react';
import { FeaturedNav } from '../../components/featured-nav';
import { PostFilterSidebar } from '../../components/post-filter-sidebar';
import { useRouteUtils } from '../../components/use-route-utils';

export const ClassesByCategory: FC = () => {
  const { searchParams } = useRouteUtils();
  const { isPortable } = useGetByDevice();

  const {
    contextClassId,
    onAddToPlaylist,
    createPlaylist,
    isCreatePending,
    onClosePlaylistDialog,
    onClickCreate,
    isCreatePlaylistDialogOpen,
  } = usePlaylistAction();

  const searchFilterUtils = useSetSearchParams({
    initialFilters: { ...contentFilterDefaultValues, ...searchParams, scrollTop: '1' },
    resetFilters: { ...contentFilterDefaultValues, scrollTop: '1' },
  });

  const { patchFilter, filters } = searchFilterUtils;

  const { categoryId } = filters;

  const contentStates = useGetContentsQuery({ filters: searchFilterUtils.filters });

  const { isSubscriptionActive } = useGetSubscriptionQuery();

  const onClickCategoryHandler = useCallback((categoryId: string) => {
    patchFilter({ categoryId });
  }, []);

  const onNext = useCallback((page: number) => {
    patchFilter({ page: page.toString() });
  }, []);

  const onPrev = useCallback((page: number) => {
    patchFilter({ page: page.toString() });
  }, []);

  const defaultSelectCategoryId = 'STRENGTH';

  const { setPlaylistMenu } = usePlaylistMenuService();

  const onClickAddPlaylistHandler = useCallback(
    (anchorEl: HTMLElement, contentId: string) => {
      setPlaylistMenu({
        contentId,
        anchorEl,
        onAddToPlaylist,
        onClickCreate,
      });
    },
    [onAddToPlaylist, onClickCreate, onClosePlaylistDialog],
  );

  useEffect(() => {
    if (!filters.categoryId && defaultSelectCategoryId) {
      patchFilter({ categoryId: defaultSelectCategoryId });
    }
  }, []);

  return (
    <Box sx={{ background: theme => theme.palette.common.white }}>
      <FeaturedNav
        nav={[
          {
            name: 'STRENGTH',
            selected: categoryId === 'STRENGTH',
            onClick: onClickCategoryHandler,
            startIcon: <Strength color={theme.palette.grey[800]} width="42px" height="42px" />,
          },
          {
            name: 'FLOW',
            selected: categoryId === 'FLOW',
            onClick: onClickCategoryHandler,
            startIcon: <YogaPose color={theme.palette.grey[800]} width="42px" height="42px" />,
          },
          {
            name: 'CARDIO',
            selected: categoryId === 'CARDIO',
            onClick: onClickCategoryHandler,
            startIcon: <Cardio color={theme.palette.grey[800]} width="42px" height="42px" />,
          },
          {
            name: 'RESTORE',
            selected: categoryId === 'RESTORE',
            onClick: onClickCategoryHandler,
            startIcon: <Restore color={theme.palette.grey[800]} width="42px" height="42px" />,
          },
        ]}
        sx={{ py: { xs: 4, md: 2 }, pb: { xs: 4, md: 5 } }}
      />

      <Container maxWidth="xl" sx={{ my: { xs: 0, md: 6 } }}>
        <Grid container spacing={{ xs: 0, md: 2 }}>
          <Grid item xs={12} sm={12}>
            <Grid container spacing={{ xs: 2, md: 5 }}>
              <Grid item md={3} sm={6} xs={12} sx={{ display: isPortable ? 'none' : 'block' }}>
                <PostFilterSidebar searchFilterUtils={searchFilterUtils} isDefaultExpanded hide={['category']} />
              </Grid>
              <Grid item md={12} lg={9} sm={12} xs={12}>
                <ClassesDataStateHandler
                  isSubscriptionActive={isSubscriptionActive}
                  onClickAddPlaylist={onClickAddPlaylistHandler}
                  onNext={onNext}
                  onPrev={onPrev}
                  queryResult={contentStates}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <AddEditPlaylistDialog
        isPending={isCreatePending}
        open={isCreatePlaylistDialogOpen}
        onClose={onClosePlaylistDialog}
        onSave={createPlaylist}
        contentId={contextClassId}
      />
    </Box>
  );
};
