import { IPaginationNew, TError, TPlaylist } from '@onesource/schemas';
import { queryOptions, useQuery } from '@tanstack/react-query';
import axios from '../axios';
import { ENTITY } from './entity';

export const getAllPlaylistsQueryKey = () => {
  return [ENTITY];
};

export const getAllPlaylistQueryOptions = () => {
  return queryOptions<IPaginationNew<TPlaylist>, TError>({
    queryKey: getAllPlaylistsQueryKey(),
    queryFn: async () => {
      const res = await axios.get(ENTITY);
      return res.data;
    },
  });
};
export type TGetAllPlaylistQueryOptionsReturn = ReturnType<typeof getAllPlaylistQueryOptions>;

export const useGetAllPlaylistsQuery = () => {
  return useQuery(getAllPlaylistQueryOptions());
};
