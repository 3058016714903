import type { SVGProps } from 'react';
const SvgMvPlay = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 12 15"
    fontSize="1rem"
    {...props}
  >
    <path
      fill="currentColor"
      d="M0 2.697C0 1.108 1.764.154 3.095 1.023l7.345 4.803a2 2 0 0 1 0 3.348l-7.345 4.803C1.765 14.847 0 13.892 0 12.303z"
    />
  </svg>
);
export default SvgMvPlay;
