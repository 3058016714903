import { useAtom } from 'jotai';
import { useCallback } from 'react';
import { contextMenuAtom, IContextMenuAtom } from '../atom';

export function useContextMenuService<T>() {
  const [contextMenuConfig, setContextMenuConfig] = useAtom(contextMenuAtom);

  function setContextMenu(config: IContextMenuAtom<T>) {
    setContextMenuConfig(config);
  }

  const closeContextMenu = useCallback(() => {
    setContextMenuConfig(null);
  }, [setContextMenuConfig]);

  return {
    contextMenuConfig,
    closeContextMenu,
    setContextMenu,
  };
}
