import type { SVGProps } from 'react';
const SvgBackLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 45 45"
    fontSize="1rem"
    {...props}
  >
    <path
      fill="currentColor"
      d="M24.88 30c-.305-.067-.525-.272-.738-.5q-2.896-3.1-5.796-6.194c-.304-.324-.432-.702-.285-1.144a1.2 1.2 0 0 1 .264-.436q2.98-3.195 5.969-6.382c.301-.322.663-.432 1.067-.27.379.15.594.46.634.893.032.348-.099.636-.327.88q-.69.734-1.377 1.469l-3.801 4.058c-.036.038-.079.067-.144.165.048.029.105.048.142.088l5.169 5.518c.295.315.421.68.293 1.118a.97.97 0 0 1-.641.684l-.155.053z"
    />
    <circle cx={22.343} cy={22.343} r={21.343} stroke="currentColor" strokeWidth={2} />
  </svg>
);
export default SvgBackLogo;
