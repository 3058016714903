import { createFileRoute } from '@tanstack/react-router';
import { Home } from '../pages/home';
import { AuthGuard } from '@onesource/components';

export const Route = createFileRoute('/')({
  component: () => (
    <AuthGuard>
      <Home />
    </AuthGuard>
  ),
});
