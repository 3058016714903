import { createFileRoute } from '@tanstack/react-router';
import { ClassDetails } from 'pages/class-details';
import { object, string } from 'zod';

const RouteParams = object({
  classId: string().min(1, 'classId is required'),
});

export const Route = createFileRoute('/_protected/classes/details/$classId')({
  component: () => {
    return <ClassDetails />;
  },
  parseParams: params => RouteParams.parse(params),
});

export const useClassDetailsPageParams = Route.useParams;
