import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Select, { SelectProps } from '@mui/material/Select';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import ListSubheader from '@mui/material/ListSubheader';

type TProps = SelectProps & {
  name: string;
  native?: boolean;
  helperText?: React.ReactNode;
  children: JSX.Element | JSX.Element[];
};

export const RHFSelect: FC<TProps> = props => {
  const { name, label, helperText, children, native, ...others } = props;

  const { control } = useFormContext();

  return (
    <FormControl fullWidth>
      <InputLabel id={`label-${name}`}>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <>
            <Select
              {...others}
              {...field}
              name={name}
              labelId={`label-${name}`}
              label={label}
              native={native}
              fullWidth
            >
              <ListSubheader>Select an Option</ListSubheader>
              {children}
            </Select>
            {(!!error || helperText) && (
              <FormHelperText error={!!error}>{error ? error?.message : helperText}</FormHelperText>
            )}
          </>
        )}
      />
    </FormControl>
  );
};
