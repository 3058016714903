import { FC, memo } from 'react';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

export const InstructorDetailsLoading: FC = memo(() => {
  return (
    <Container maxWidth="xl">
      <Grid container spacing={10}>
        <Grid item sm={6}>
          <Skeleton height={80} />

          <Skeleton height={120} sx={{ mt: 4 }} />
          <Skeleton height={30} sx={{ width: '70%' }} />
          <Skeleton height={30} sx={{ width: '200px' }} />

          <Divider sx={{ mb: 3, mt: 2.5 }} />

          <Skeleton height={24} />
          <Skeleton height={24} />
          <Skeleton height={24} />
          <Skeleton height={24} />
          <Skeleton height={24} />
          <Skeleton height={24} />
          <Skeleton height={24} />
          <Skeleton height={24} />
          <Skeleton height={24} />
          <Skeleton height={24} />

          <Stack direction="row" spacing={2} mt={3}>
            <Skeleton height={50} width={30} />
            <Skeleton height={50} width={30} />
            <Skeleton height={50} width={30} />
            <Skeleton height={50} width={30} />
          </Stack>
        </Grid>
        <Grid item sm={6} position="relative">
          <Skeleton
            sx={{
              height: 600,
              width: 700,
              borderRadius: '40px',
              position: 'relative',
              zIndex: 1,
            }}
          />

          <Box
            sx={{
              position: 'absolute',
              width: '100%',
              left: '50%',
              top: 0,
              background: theme => theme.palette.grey[50],
              height: '100%',
            }}
          />
        </Grid>

        <Grid item xs={12} mt={0}>
          <Skeleton height={200} />
        </Grid>
      </Grid>
    </Container>
  );
});
