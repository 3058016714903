import { darken, SxProps, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { TContent } from '@onesource/schemas';
import { Link } from '@tanstack/react-router';
import { BackButton } from 'components/back-button';
import { FC } from 'react';

export interface IProps {
  content: TContent;
  sx?: SxProps;
}

export const VideoHeader: FC<IProps> = props => {
  const { content, sx } = props;
  const theme = useTheme();

  return (
    <Stack direction="row" sx={sx}>
      <Box sx={{ flex: 1 }}>
        <BackButton color="black" />
      </Box>
      <Box sx={{ textAlign: 'center', flex: 11 }}>
        <Typography
          variant="h2"
          sx={{
            mb: 1,
            textAlign: 'center',
            fontSize: '40px',
            lineHeight: '1.5',
            color: darken(theme.palette.common.white, 0.799),
          }}
        >
          {content.name}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            mb: { xs: 1, md: 3 },
            textAlign: 'center',
            color: darken(theme.palette.common.white, 0.799),
          }}
        >
          <Link
            style={{
              color: darken(theme.palette.common.white, 0.799),
              textDecoration: 'none',
            }}
            to={`/instructors/${content.instructor.id}`}
          >
            {content.instructor.firstName} {content.instructor.lastName}
          </Link>
        </Typography>
      </Box>
    </Stack>
  );
};
