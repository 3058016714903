import { FC, useCallback, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { theme } from '@onesource/theme';
import { CommentProps, Comment, ListAnimation } from '@onesource/components';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { FaChevronRight } from 'react-icons/fa';

type TProps = {
  comments: Array<CommentProps & { id: string | number }>;
  handleSendComment: (comment: string) => void;
};

export const CommentsSection: FC<TProps> = props => {
  const { comments, handleSendComment } = props;

  const [newComment, setNewComment] = useState('');

  const handleAddComment = useCallback(() => {
    if (newComment.trim() !== '') {
      handleSendComment(newComment);
      setNewComment('');
    }
  }, [handleSendComment, newComment]);

  const isButtonDisabled = newComment.trim().length <= 0;

  return (
    <Grid container flexDirection="column" gap={2}>
      <Typography variant="h6">Comments</Typography>
      <Grid item display="flex" flexDirection="column" gap={2}>
        <TextField
          multiline
          rows={4}
          placeholder="Join your ideas"
          sx={{
            '& .MuiOutlinedInput-root': {
              backgroundColor: theme => theme.palette.grey[100],
              borderRadius: '5px !important',
              py: '10px !important',
              px: '15px !important',
            },
            '& .MuiInputBase-input::placeholder': {
              color: theme => theme.palette.grey[800],
              fontWeight: '400',
            },
          }}
          style={{
            width: '100%',
          }}
          value={newComment}
          onChange={e => setNewComment(e.target.value)}
        />
        <Box>
          <Button
            disabled={isButtonDisabled}
            onClick={handleAddComment}
            endIcon={
              <Box
                component="span"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '50px',
                  width: '50px',
                  borderRadius: '50%',
                  background: theme => (isButtonDisabled ? theme.palette.grey[800] : theme.palette.primary.main),
                  color: theme => theme.palette.common.white,
                  boxShadow: `5px 5px 15px ${theme.palette.common.black}20`,
                }}
              >
                <FaChevronRight size={20} />
              </Box>
            }
            sx={{ width: '218px' }}
          >
            Post Comment
          </Button>
        </Box>
      </Grid>
      <Box sx={{ mt: 4 }}>
        <ListAnimation items={comments} renderItem={comment => <Comment {...comment} />} />
      </Box>
    </Grid>
  );
};
