import { Outlet, createFileRoute, useParams } from '@tanstack/react-router';
import { ClassesTopNav } from 'components/sections/classes-top-nav';

export const Route = createFileRoute('/_protected/classes')({
  component: () => {
    const { classId } = useParams({ strict: false });

    if (classId) {
      return <Outlet />;
    }

    return (
      <>
        <ClassesTopNav />
        <Outlet />
      </>
    );
  },
});
