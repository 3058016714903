import { useAtom } from 'jotai';
import { useCallback } from 'react';
import { playlistMenuAtom, IPlaylistMenuAtom } from '../atom';

export function usePlaylistMenuService() {
  const [contextMenuConfig, setPlaylistMenuConfig] = useAtom(playlistMenuAtom);

  function setPlaylistMenu(config: IPlaylistMenuAtom) {
    setPlaylistMenuConfig(config);
  }

  const closePlaylistMenu = useCallback(() => {
    setPlaylistMenuConfig({
      contentId: null,
      anchorEl: null,
    });
  }, [setPlaylistMenuConfig]);

  return {
    contextMenuConfig,
    closePlaylistMenu,
    setPlaylistMenu,
  };
}
