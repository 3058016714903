import { FC, memo } from 'react';
import { DateRange, DayPicker, useDayPicker } from 'react-day-picker';
import 'react-day-picker/style.css';
import Grid from '@mui/material/Grid';
import { styled, useTheme, alpha } from '@mui/material';
import { TfiArrowCircleLeft, TfiArrowCircleRight } from 'react-icons/tfi';
import { DateTime } from 'luxon';
import { CaptionLabelItem } from './caption-label-item';
import { useGetByDevice } from '../../hooks';

const StyledDayPicker = styled(DayPicker)();

type TProps = {
  date: DateRange | undefined;
  handleDateChange: (date: DateRange | undefined) => void;
  size?: 'small' | 'medium' | 'large';
};

export const ClassCalender: FC<TProps> = memo(props => {
  const { date, handleDateChange, size = 'large' } = props;
  const theme = useTheme();
  const { isMobile } = useGetByDevice();

  const sizes = {
    large: {
      buttonWidth: '65px',
      buttonHeight: '67px',
    },
    medium: {
      buttonWidth: '48px',
      buttonHeight: '55px',
    },
    small: {
      buttonWidth: '40px',
      buttonHeight: '50px',
    },
  };

  return (
    <StyledDayPicker
      selected={date}
      onSelect={handleDateChange}
      numberOfMonths={1}
      mode="range"
      showOutsideDays
      hideNavigation
      sx={{
        ['*']: {
          fontFamily: 'Montserrat,Arial,sans-serif',
        },
        ['--rdp-accent-color']: theme.palette.primary.main,
        ['.rdp-day']: {
          font: 'initial',
          ['&:not(.rdp-range_middle)']: {
            backgroundColor: theme.palette.grey[100],
            color: theme.palette.grey[400],
          },
          ['&.rdp-range_middle']: {
            backgroundColor: alpha(theme.palette.primary.main, 0.1),
            color: theme.palette.grey[800],
          },
          ['&.rdp-today']: {
            color: theme.palette.primary.main,
            fontWeight: 'bolder',
          },
        },
      }}
      styles={{
        root: {
          display: 'inline-flex',
          justifyContent: 'center',
          width: isMobile ? '100%' : 'fit-content',
          height: 'fit-content',
          padding: '20px 5px 45px 5px',
          borderRadius: '30px',
          boxShadow: 'rgba(0, 0, 0, 0.05) 0px 11px 13px 2px, rgba(0, 0, 0, 0.035) 0px 2px 2px',
        },
        month_grid: {
          borderCollapse: 'separate',
          borderSpacing: '4px',
          margin: '16px 0 0 0',
        },
        day_button: {
          width: sizes[size].buttonWidth,
          height: sizes[size].buttonHeight,
          borderRadius: 0,
        },
      }}
      today={new Date()}
      disabled={{ after: new Date() }}
      components={{
        CaptionLabel: () => {
          const { previousMonth, nextMonth, goToMonth, months } = useDayPicker();
          const month = months[0];
          const monthName = DateTime.fromJSDate(month.date).monthLong;
          return (
            <Grid display="inline-flex" width="100%" alignItems="start" justifyContent="space-between" pb={5}>
              <CaptionLabelItem isIconButton onClick={() => previousMonth && goToMonth(previousMonth)}>
                <TfiArrowCircleLeft size="28px" color={theme.palette.grey[900]} />
              </CaptionLabelItem>
              <CaptionLabelItem
                sx={{
                  textTransform: 'uppercase',
                }}
              >
                {monthName}
              </CaptionLabelItem>
              <CaptionLabelItem isIconButton onClick={() => nextMonth && goToMonth(nextMonth)}>
                <TfiArrowCircleRight size="28px" color={theme.palette.grey[900]} />
              </CaptionLabelItem>
            </Grid>
          );
        },
      }}
    />
  );
});
