import Box, { BoxProps } from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { memo } from 'react';

export const LoadingBlock = memo(({ sx, ...other }: BoxProps) => {
  return (
    <Box
      sx={{
        py: 10,
        px: 5,
        width: 1,
        flexGrow: 1,
        minHeight: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...sx,
      }}
      {...other}
    >
      <CircularProgress color="primary" thickness={2} size={70} />
    </Box>
  );
});
