import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { IPaginationNew, TContent, TError } from '@onesource/schemas';
import { UseQueryResult } from '@tanstack/react-query';
import { FC } from 'react';
import { Content } from '../cards/content/content';
import { LoadingError } from '../loading-error';
import { NotFoundCard } from '../not-found';
import { Pagination } from '../pagination';
import { PostLoading } from '../cards/content/loading';

export interface IClassesDataStateHandlerProps {
  queryResult: UseQueryResult<IPaginationNew<TContent>, TError>;
  isSubscriptionActive: boolean;
  sx?: SxProps;
  onClickCardHandler?: (content: IPaginationNew<TContent>) => void;
  onNext: (pageNo: number) => void;
  onPrev: (pageNo: number) => void;
  onClickAddPlaylist?: (anchorEl: HTMLElement, contentId: string) => void;
}

export const ClassesDataStateHandler: FC<IClassesDataStateHandlerProps> = props => {
  const { sx, queryResult, onNext, onPrev, isSubscriptionActive, onClickAddPlaylist } = props;

  const { data: classesData, isLoading, isError, error, refetch } = queryResult;
  const { items = [], meta } = classesData ?? {};

  if (isError && error) {
    return (
      <Box sx={sx}>
        <LoadingError
          sx={{ width: '100%' }}
          entity="Class"
          message={error.message}
          refetch={refetch}
          errorCode={error.code}
        />
      </Box>
    );
  }

  if (isLoading) {
    return (
      <Grid container spacing={4}>
        {Array.from({ length: 12 }).map((_x, index) => (
          <Grid key={index} item sm={12} md={4} xs={12}>
            <PostLoading />
          </Grid>
        ))}
      </Grid>
    );
  }

  if (!classesData?.items.length) {
    return (
      <Box sx={sx}>
        <NotFoundCard entity="Class" sx={{ width: '100%' }} />
      </Box>
    );
  }

  return (
    <Box sx={sx}>
      <Grid container spacing={{ xs: 2, md: 4 }}>
        {items.map((content: TContent) => (
          <Grid item md={4} sm={6} xs={12} key={content.id}>
            <Content content={content} isLocked={!isSubscriptionActive} onClickAddPlaylist={onClickAddPlaylist} />
          </Grid>
        ))}

        <Box display="flex" justifyContent="center" width="100%">
          {meta && <Pagination sx={{ mt: 4 }} meta={meta} onNext={onNext} onPrev={onPrev} />}
        </Box>
      </Grid>
    </Box>
  );
};
