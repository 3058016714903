import type { SVGProps } from 'react';
const SvgFacebook = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 29 29"
    fontSize="1rem"
    {...props}
  >
    <g clipPath="url(#facebook_svg__a)">
      <path
        fill="#CE3B4D"
        d="M28.372 7.664c0-.58-.053-1.118-.155-1.603-.758-3.619-3.72-6.053-7.37-6.058-4.592-.007-9.107-.004-13.302.002-.449 0-.816.025-1.158.08C2.698.657.015 3.816.007 7.594c-.01 4.2-.009 8.655.004 13.62 0 .531.056 1.05.16 1.541.764 3.6 3.72 6.028 7.356 6.038q1.324.005 2.648.004h4.273c.595 0 .872-.281.872-.886v-9.637c0-.645-.289-.934-.935-.935h-2.418v-2.33h2.461c.608-.002.89-.291.892-.908q.001-.54.005-1.08c.005-.892.01-1.815-.01-2.723-.03-1.337.56-2.257 1.803-2.815 1.294-.58 2.717-.714 4.612-.437l.035.007a.5.5 0 0 1 .007.105q-.003.605-.002 1.212v.826q0 .025-.003.05H19.92c-1.294 0-2.045.703-2.116 1.98-.038.715-.03 1.442-.021 2.145q.005.39.006.78.005.855.845.86.922.004 1.842.002h.892l-.366 2.327h-2.2c-.764 0-1.01.253-1.013 1.036l-.005 3.202-.011 6.404c0 .488.32.816.801.816h.174c.778.002 1.583.004 2.374-.006.3-.004.6-.03.889-.077 3.747-.61 6.366-3.727 6.371-7.581.007-5.44.005-9.595-.005-13.468zm-5.89 10.121c.17-1.045.332-2.11.487-3.14l.028-.192q.069-.46-.153-.727c-.149-.176-.38-.268-.683-.27-.635-.005-1.27-.003-1.905-.003H19.6c-.092 0-.184.005-.277 0l-.003-.049q-.001-.296-.007-.591c-.007-.492-.014-.998.008-1.493.02-.434.116-.522.573-.522h2.516c.596 0 .887-.291.889-.882q.005-1.702 0-3.406c0-.505-.207-.764-.688-.865-2.408-.507-4.372-.365-6.178.449-1.405.633-2.261 1.65-2.545 3.024-.064.305-.094.64-.099 1.059-.008.82-.006 1.624-.005 2.475v.806h-2.439c-.655.002-.935.28-.937.93a631 631 0 0 0 0 3.589c0 .644.274.918.91.92h2.452v8.349H7.597c-3.015-.004-5.416-2.02-5.97-5.018a6 6 0 0 1-.085-1.088c-.005-4.314-.007-8.844-.004-13.466.002-3.022 2.05-5.5 4.978-6.031a6 6 0 0 1 1.043-.08c4.743-.005 9.084-.007 13.272-.001 2.979.003 5.421 2.082 5.937 5.055.052.298.077.635.077 1.03.005 4.945.007 9.359.002 13.498-.003 3.056-2.054 5.527-4.99 6.01-.587.096-1.183.103-1.814.11-.23.003-.47.005-.707.013v-8.38h1.85c1.022 0 1.13-.093 1.296-1.108z"
      />
    </g>
    <defs>
      <clipPath id="facebook_svg__a">
        <path fill="#fff" d="M0 0h28.383v28.8H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgFacebook;
