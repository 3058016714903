import { Outlet, createRootRoute } from '@tanstack/react-router';
import { lazy } from 'react';
import { Header } from '../components/header';
import { Footer } from '../components/footer';
import { useLogoutMutation } from '@onesource/services';
import { queryClient } from 'app';

const TanStackRouterDevtools = import.meta.env.PROD
  ? () => null // Render nothing in production
  : lazy(() =>
      // Lazy load in development
      import('@tanstack/router-devtools').then(res => ({
        default: res.TanStackRouterDevtools,
        // For Embedded Mode
        // default: res.TanStackRouterDevtoolsPanel
      })),
    );

export const Route = createRootRoute({
  component: RootRouteComponent,
});

function RootRouteComponent() {
  useLogoutMutation(() => {
    queryClient.clear();
    location.replace('/auth/login');
  });

  return (
    <>
      <Header />
      <Outlet />
      <TanStackRouterDevtools />
      <Footer />
    </>
  );
}
