import FormHelperText from '@mui/material/FormHelperText';
import { DateTimePicker, DateTimePickerProps, LocalizationProvider } from '@mui/x-date-pickers';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TProps = DateTimePickerProps<any> & {
  name: string;
  helperText?: string;
};

export const RHFDateTimePicker: FC<TProps> = props => {
  const { name, helperText, sx, ...others } = props;

  const { control } = useFormContext();

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onBlur, onChange, ...rest }, fieldState: { error } }) => {
          return (
            <>
              <DateTimePicker
                {...others}
                {...rest}
                onChange={value => {
                  onChange(value);
                  onBlur();
                }}
                name={name}
                sx={{
                  width: '100%',
                  ...sx,
                }}
              />

              {(!!error || helperText) && (
                <FormHelperText error={!!error}>{error ? error?.message : helperText}</FormHelperText>
              )}
            </>
          );
        }}
      />
    </LocalizationProvider>
  );
};
