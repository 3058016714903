import type { SVGProps } from 'react';
const SvgStrength = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 42 42"
    fontSize="1rem"
    {...props}
  >
    <path
      fill="currentColor"
      d="M40.688 19.688h-1.313v-5.25a2.625 2.625 0 0 0-2.625-2.626h-2.625V10.5A2.625 2.625 0 0 0 31.5 7.875h-3.937a2.625 2.625 0 0 0-2.625 2.625v9.188h-7.875V10.5a2.625 2.625 0 0 0-2.625-2.625H10.5A2.625 2.625 0 0 0 7.875 10.5v1.313H5.25a2.625 2.625 0 0 0-2.625 2.624v5.25H1.313a1.313 1.313 0 0 0 0 2.625h1.312v5.25a2.625 2.625 0 0 0 2.625 2.625h2.625V31.5a2.625 2.625 0 0 0 2.625 2.625h3.938a2.625 2.625 0 0 0 2.624-2.625v-9.187h7.875V31.5a2.625 2.625 0 0 0 2.625 2.625H31.5a2.625 2.625 0 0 0 2.625-2.625v-1.312h2.625a2.625 2.625 0 0 0 2.625-2.625v-5.25h1.313a1.313 1.313 0 0 0 0-2.625M5.25 27.563V14.438h2.625v13.125zm9.188 3.937H10.5v-21h3.938zm17.062 0h-3.937v-21H31.5v21m5.25-3.937h-2.625V14.438h2.625z"
    />
  </svg>
);
export default SvgStrength;
