import { useParams } from '@tanstack/react-router';
import { FC } from 'react';
import { SeriesDetailsBody } from '../components/sections/single-series-details';

export const SeriesDetails: FC = () => {
  const { seriesId } = useParams({
    from: '/series/$seriesId',
  });

  if (!seriesId) {
    return null;
  }

  return <SeriesDetailsBody seriesId={seriesId} />;
};
