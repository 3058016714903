import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { SxProps, useTheme } from '@mui/material';
import Container from '@mui/material/Container';
import { MvPlay } from '@onesource/icons';
import { TSlideProps } from '@onesource/services';

export type THeroSlideProps = TSlideProps & {
  customize?: {
    actionButtonSx?: SxProps;
  };
};

export const HeroSlide: FC<THeroSlideProps> = props => {
  const { sectionTitle, sectionSubtitle, sectionDescription, actionButton, bgImg, customize } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: 'relative',
        backgroundRepeat: 'no-repeat ',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundImage: `url(${bgImg})`,
        minHeight: {
          xs: '468px',
          md: '725px',
        },
        pt: { xs: 0, md: 11 },
        '&:before': {
          content: "''",
          position: 'absolute',
          height: '100%',
          width: {
            xs: '100%',
            md: '50%',
          },
          top: 0,
          backgroundImage: `linear-gradient(to left, transparent ,${theme.palette.common.black})`,
        },
      }}
    >
      <Container maxWidth="xl" sx={{ position: 'relative', zIndex: 1, overflow: 'hidden' }}>
        <Grid container spacing={4} sx={{}}>
          <Grid
            item
            md={6}
            xs={12}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              minHeight: {
                xs: '400px',
                md: '725px',
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                minHeight: '350px',
                pt: { xs: 6 },
                pb: { xs: 6 },
              }}
            >
              <Box>
                <Typography
                  variant="h2"
                  sx={{
                    mt: {
                      xs: 0,
                      md: 9,
                    },
                    mb: { xs: 1.5, md: 3 },
                    color: theme => theme.palette.common.white,
                    '& br': { display: { xs: 'none', md: 'block' } },
                  }}
                >
                  <span dangerouslySetInnerHTML={{ __html: sectionTitle }} />
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    mb: 2,
                    color: theme => theme.palette.common.white,
                  }}
                >
                  <span dangerouslySetInnerHTML={{ __html: sectionSubtitle }} />
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    mb: 3,
                    color: theme => theme.palette.common.white,
                    lineHeight: 1.6,
                  }}
                >
                  <span dangerouslySetInnerHTML={{ __html: sectionDescription }} />
                </Typography>
              </Box>
              {actionButton && (
                <Box sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-start' } }}>
                  <Button
                    endIcon={
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '50px',
                          width: '50px',
                          borderRadius: '50%',
                          background: theme => theme.palette.primary.main,
                          color: theme => theme.palette.common.white,
                          boxShadow: `5px 5px 15px ${theme.palette.common.black}20`,
                        }}
                      >
                        <MvPlay />
                      </Box>
                    }
                    {...actionButton}
                    sx={{ paddingLeft: '20px', ...customize?.actionButtonSx }}
                  >
                    {actionButton.children}
                  </Button>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid
            item
            md={6}
            sx={{
              display: {
                xs: 'none',
                md: 'flex',
              },
            }}
          ></Grid>
        </Grid>
      </Container>
    </Box>
  );
};
