import { useTheme } from '@mui/material';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { TPlaylist } from '@onesource/schemas';
import { FC } from 'react';
import { FaPlus } from 'react-icons/fa6';

type TAddToPlaylistMenu = {
  playlists?: TPlaylist[];
  isLoading?: boolean;
  onAddToPlaylist?: (playlistId: string, contentId: string) => void;
  onClickCreate?: (contentId?: string) => void;
  contentId: string | null;
  anchorEl: null | HTMLElement;
  handleClose: () => void;
};

export const AddToPlaylistMenu: FC<TAddToPlaylistMenu> = props => {
  const theme = useTheme();
  const { playlists, isLoading, handleClose } = props;
  const { contentId, anchorEl, onAddToPlaylist, onClickCreate } = props;
  const open = Boolean(anchorEl);

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        id="playlist-menu"
        open={open}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 6,
            sx: {
              p: `10px 20px `,
              borderRadius: 8,
              overflow: 'visible',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 30,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {playlists?.map(playlist => (
          <MenuItem
            disabled={isLoading}
            key={playlist.id}
            sx={{ px: 1.5 }}
            onClick={() => {
              if (!contentId) {
                throw new Error('Content ID is required to create a playlist');
              }

              onAddToPlaylist?.(playlist.id, contentId);
              handleClose();
            }}
          >
            {playlist.name}
          </MenuItem>
        ))}

        <Divider />

        {
          <MenuItem
            disabled={isLoading}
            sx={{
              px: 1.5,
            }}
            onClick={() => {
              if (!contentId) {
                throw new Error('Content ID is required to create a playlist');
              }

              onClickCreate?.(contentId);
            }}
          >
            <ListItemIcon>
              <FaPlus color={theme.palette.primary.main} />
            </ListItemIcon>
            Add to a new playlist
          </MenuItem>
        }
      </Menu>
    </>
  );
};
