import Box from '@mui/material/Box';
import { TInstructor } from '@onesource/schemas';
import { FC } from 'react';
import { InstructorCard, Slideshow, TeacherCardLoading } from '@onesource/components';
import Grid from '@mui/material/Grid';

interface TeachersFilterNavProps {
  slides: TInstructor[];
  onSelectTeacher: (teacherId: string) => void;
  selected: string | null;
  isLoading: boolean;
}

export const TeachersFilterNav: FC<TeachersFilterNavProps> = props => {
  const { slides, onSelectTeacher, selected, isLoading } = props;

  const SliderSettings = {
    slidesToShow: 6,
    slidesToScroll: 6,
    arrows: true,
    infinite: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerMode: false,
          infinite: true,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerMode: true,
          infinite: true,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          arrows: false,
        },
      },
    ],
  };

  if (isLoading) {
    return (
      <Grid container>
        {Array.from({ length: 6 }).map((_x, index) => (
          <Grid key={index} item sm={6} xs={6} md={2}>
            <TeacherCardLoading isNavFilter sx={{ p: 0 }} />
          </Grid>
        ))}
      </Grid>
    );
  }

  return (
    <Box sx={{ '& .slick-arrow': { mt: '-25px' }, '& .slick-track>div>div': { margin: 'auto' } }}>
      <Slideshow
        slides={slides}
        renderSlide={instructor => (
          <InstructorCard
            type="SLIDER"
            instructor={instructor}
            onClick={() => onSelectTeacher(instructor.id)}
            key={instructor.id}
            selected={selected === instructor.id}
            sx={{ cursor: 'pointer' }}
          />
        )}
        settings={SliderSettings}
      />
    </Box>
  );
};
