import Container from '@mui/material/Container';
import { SxProps } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { FeaturedNavItem } from '../featured-nav-item';
import { useTheme } from '@mui/material';
import { useGetByDevice } from '@onesource/components';

export type NavItem = {
  name: string;
  startIcon: JSX.Element;
  selected: boolean;
  onClick: (categoryId: string) => void;
};

export type TFeatureNavProps = {
  sectionTitle?: string;
  sectionSubtitle?: string;
  nav: NavItem[];
  sx?: SxProps;
};

export const FeaturedNav: FC<TFeatureNavProps> = props => {
  const { sectionTitle, sectionSubtitle, nav, sx } = props;
  const theme = useTheme();
  const { isXsMobile } = useGetByDevice();

  return (
    <Container
      maxWidth="xl"
      sx={{
        py: { xs: 3, md: 6 },
        background: theme => theme.palette.common.white,
        ...sx,
      }}
    >
      {sectionTitle && (
        <Typography
          variant="h2"
          sx={{
            mt: { xs: 1, md: 5 },
            mb: 1.5,
            textAlign: 'center',
            color: {
              md: theme.palette.primary.main,
            },
          }}
        >
          {sectionTitle}
        </Typography>
      )}
      {sectionSubtitle && (
        <Typography
          variant="body2"
          sx={{
            mb: { xs: 4, md: 8 },
            textAlign: 'center',
            fontSize: {
              sm: '1.25rem',
              md: '1.5rem',
            },
          }}
        >
          {sectionSubtitle}
        </Typography>
      )}

      <Grid container spacing={{ xs: 1.4, sm: 2, md: 3 }} sx={{ justifyContent: 'space-around', display: 'flex' }}>
        {nav.map(item => (
          <Grid item xs={isXsMobile ? 12 : 6} sm={6} md={6} lg={3} key={item.name}>
            <FeaturedNavItem {...item} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};
