import { createFileRoute } from '@tanstack/react-router';
import { AuthGuard } from '@onesource/components';
import { InstructorDetails } from '../../pages/instructor-details';

export const Route = createFileRoute('/instructors/$instructorId')({
  component: () => (
    <AuthGuard>
      <InstructorDetails />
    </AuthGuard>
  ),
});

export const useInstructorDetailsPageParams = Route.useParams;
