import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { FC, memo } from 'react';
import Skeleton from '@mui/material/Skeleton';

export const FeaturedInstructorLoading: FC = memo(() => {
  return (
    <Card
      sx={{
        position: 'relative',
        borderRadius: '30px',
        boxShadow: 'none',
        height: { xs: 350, md: 500 },
        width: '100%',
      }}
    >
      <Skeleton variant="rectangular" sx={{ width: '100%', height: '100%' }} animation="wave" />

      <CardContent
        sx={{
          position: 'absolute',
          bottom: '53px',
          left: 0,
          right: 0,
          pt: 2,
          pl: 3,
          pr: 3,
          pb: 3,
          zIndex: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      />
    </Card>
  );
});
