import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { FC, memo } from 'react';
import { useGetByDevice } from '../../hooks';

interface ITeacherCardLoadingProps {
  sx?: SxProps;
  isNavFilter?: boolean;
}
export const TeacherCardLoading: FC<ITeacherCardLoadingProps> = memo(props => {
  const { sx, isNavFilter } = props;
  const { isMobile } = useGetByDevice();

  const avatarSize = isMobile ? 150 : isNavFilter ? 170 : 250;

  return (
    <Card
      sx={{
        boxShadow: 'none',
        paddingRight: 2.5,
        paddingLeft: 2.5,
        background: 'none',
        ...sx,
      }}
    >
      <CardContent
        sx={{
          textAlign: 'center',
          margin: 'auto',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Skeleton variant="circular" sx={{ width: avatarSize, height: avatarSize }} />

        <Box sx={{ width: '100%' }}>
          <Typography
            variant="h6"
            sx={{
              mt: 2,
            }}
          >
            <Skeleton height={70} />
          </Typography>

          <Typography variant="body2" sx={{ mt: 1 }}>
            <Skeleton />
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
});
