import { TDeleteContentFromPlaylistRequestSchema } from '@onesource/schemas';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import axios from '../axios';
import { ENTITY } from './entity';
import { getPlaylistQueryKey } from './use-get-playlist-query';

export const useRemoveContentFromPlaylistMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: TDeleteContentFromPlaylistRequestSchema) => {
      return axios.patch(`/${ENTITY}/remove-content`, payload);
    },
    onSuccess: async res => {
      const playlistQueryKey = getPlaylistQueryKey(res.data.id.toString());
      await queryClient.invalidateQueries({ queryKey: playlistQueryKey });

      enqueueSnackbar(`Remove item from playlist successful!`, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    },
    onError: () => {
      enqueueSnackbar('Unable to remove item from playlist', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    },
  });
};
