import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { FC, memo } from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

export const FitnessSeriesLoading: FC = memo(() => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        position: 'relative',
        borderRadius: '30px',
        boxShadow: 'none',
      }}
    >
      <Skeleton variant="rectangular" height={300} />

      <CardContent
        sx={{
          position: 'absolute',
          bottom: '53px',
          left: 0,
          right: 0,
          pt: 2,
          pl: 3,
          pr: 3,
          pb: 3,
          zIndex: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography
            sx={{
              color: theme => theme.palette.common.white,
              width: '100%',
              fontSize: '30px',
              fontWeight: '700',
            }}
          >
            <Skeleton />
          </Typography>
        </Box>
      </CardContent>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          background: theme.palette.grey[100],
          borderRadius: '0 0 30px 30px',
          padding: '15px 30px',
          boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.05)',
          width: 'calc(100% - 60px)',
        }}
      >
        <Stack direction="row" alignItems="center">
          <Skeleton variant="rectangular" width={20} height={20} sx={{ mr: 1 }} />
          <Skeleton variant="rectangular" width={50} height={14} />
        </Stack>
      </Box>
    </Card>
  );
});
