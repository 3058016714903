import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Grid from '@mui/material/Grid';
import { TDeleteContentFromPlaylistRequestSchema, TPlaylistItem } from '@onesource/schemas';
import { DraggableContentCard } from 'components/sortable-contents/card/draggable-content-card';
import { FC } from 'react';

export type TSortableItemProps = {
  item: TPlaylistItem;
  playlistId: string;
  enableDrag: boolean;
  onRemove: (params: TDeleteContentFromPlaylistRequestSchema) => void;
  onMove: (item: TPlaylistItem) => void;
};

export const SortableItem: FC<TSortableItemProps> = props => {
  const { item, enableDrag, onRemove, playlistId, onMove } = props;
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: item.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Grid item xs={12} ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <DraggableContentCard
        onMove={onMove}
        onRemove={onRemove}
        item={item}
        enableDrag={enableDrag}
        playlistId={playlistId}
      />
    </Grid>
  );
};
