import { FC, useCallback } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { darken } from '@mui/material';
import { AddEditPlaylistDialog, Content } from '@onesource/components';
import { theme } from '@onesource/theme';
import { useGetSeriesQuery, useGetSubscriptionQuery } from '@onesource/services';
import { LoadingBlock } from '@onesource/components';
import { usePlaylistMenuService } from 'components/add-to-playlist/service';
import { usePlaylistAction } from 'hooks/use-playlist-actions';

export type TSingleSeriesDetailsProps = {
  seriesId: string;
};

export const SeriesDetailsBody: FC<TSingleSeriesDetailsProps> = props => {
  const { seriesId } = props;
  const queryResult = useGetSeriesQuery({ seriesId });
  const { data: series, isLoading } = queryResult;

  const {
    contextClassId,
    onAddToPlaylist,
    createPlaylist,
    isCreatePending,
    onClickCreate,
    onClosePlaylistDialog,
    isCreatePlaylistDialogOpen,
  } = usePlaylistAction();

  const { isSubscriptionActive } = useGetSubscriptionQuery();

  const { setPlaylistMenu } = usePlaylistMenuService();

  const onClickAddPlaylistHandler = useCallback(
    (anchorEl: HTMLElement, contentId: string) => {
      setPlaylistMenu({
        contentId,
        anchorEl,
        onAddToPlaylist,
        onClickCreate,
      });
    },
    [onAddToPlaylist, onClickCreate, onClosePlaylistDialog],
  );

  if (isLoading) {
    return <LoadingBlock />;
  }

  if (!series) {
    return null;
  }

  return (
    <Box sx={{ mt: { xs: 4, md: 10 } }}>
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid md={6} xs={12} item>
            <Typography
              variant="h2"
              sx={{
                color: darken(theme.palette.common.white, 0.799),
                '&:after': {
                  content: '" "',
                  display: 'block',
                  height: '4px',
                  width: '25px',
                  background: theme.palette.primary.main,
                },
              }}
            >
              {series.name}
            </Typography>
          </Grid>
          <Grid md={6} xs={12} item>
            <Typography variant="body2" sx={{ color: darken(theme.palette.common.white, 0.799) }}>
              <span dangerouslySetInnerHTML={{ __html: series?.description ?? '' }} />
            </Typography>

            <Divider sx={{ my: 2 }} />

            {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Exampleicon style={{ fontSize: '36px', marginRight: 1 }} />
              <Typography variant="body2" sx={{ color: darken(theme.palette.common.white, 0.799) }}>
                Example: Class 1 x3 per week for two weeks, and then move on to class 2.
              </Typography>
            </Box> */}
          </Grid>
          <Grid item xs={12} sx={{ my: { xs: 5, md: 13 } }}>
            <Grid container spacing={3.5}>
              {series.items.map(post => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={post.id}>
                  <Content
                    isLocked={!isSubscriptionActive}
                    content={post.content}
                    onClickAddPlaylist={onClickAddPlaylistHandler}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>{' '}
      <AddEditPlaylistDialog
        isPending={isCreatePending}
        open={isCreatePlaylistDialogOpen}
        onClose={onClosePlaylistDialog}
        onSave={createPlaylist}
        contentId={contextClassId}
      />
    </Box>
  );
};
