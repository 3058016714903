import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useGetByDevice } from '@onesource/components';
import { MouseEvent } from 'react';

export interface ITextFilterNavProps<T> {
  filter?: T | null;
  items: {
    label: string;
    value: string;
  }[];
  handleFilter: (event: MouseEvent<HTMLElement>, newFilter: T) => void;
}

export function TextFilterNav<T>(props: ITextFilterNavProps<T>) {
  const { items, filter, handleFilter } = props;
  const { isXsMobile } = useGetByDevice();

  return (
    <ToggleButtonGroup
      orientation={isXsMobile ? 'vertical' : 'horizontal'}
      value={filter}
      exclusive
      onChange={handleFilter}
    >
      {items.map((item, index) => {
        return (
          <ToggleButton
            key={index}
            disableRipple
            sx={{
              mb: isXsMobile ? 1 : 0,
              background: 'transparent',
              border: 0,
              borderRadius: 0,
              borderRight: theme => (items.length - 1 === index ? 'none' : `2px solid ${theme.palette.primary.main}`),
              textTransform: 'capitalize',
              fontSize: { xs: '12px', sm: '16px', md: '17px' },
              padding: 0,
              lineHeight: 1,
              color: theme => theme.palette.grey[700],
              px: { xs: 1, md: 2 },
              fontWeight: 600,

              '&.Mui-selected': {
                color: theme => theme.palette.primary.main,
                backgroundColor: 'transparent',
                fontWeight: 'bold',
              },
              '&:hover, &.Mui-selected:hover': {
                backgroundColor: 'transparent',
                color: theme => theme.palette.primary.main,
              },
            }}
            value={item.value}
          >
            {item.label}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
}
