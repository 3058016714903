import type { SVGProps } from 'react';
const SvgAddToCalender = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 44 44"
    fontSize="1rem"
    {...props}
  >
    <circle cx={22} cy={22} r={22} fill="#CE3B4D" />
    <g fill="#fff">
      <path d="M17.067 27.348q-.053.006-.104.007h-4.424c-.034 0-.069-.003-.121-.006V14.027c0-.944.666-1.608 1.61-1.608h13.319c.003.047.007.084.007.122v4.411c0 .039-.006.078-.009.116l.005-.004q-.032-.002-.065-.003h-10.1c-.037 0-.076.004-.128.006v7.454q.001 1.416.004 2.832zM27.346 17.069q.058-.006.117-.008h4.523v10.284c-.032.004-.065.01-.098.01h-4.452c-.03 0-.06-.005-.09-.007l.005.005q.003-.058.004-.117V17.181q-.001-.058-.004-.117z" />
      <path d="M27.347 27.347c.003.035.009.07.009.104v4.437c0 .03-.003.06-.005.1H17.066q-.006-.057-.007-.114v-4.41c0-.039.006-.078.01-.117l-.006.005q.059.002.118.003h10.054q.06 0 .117-.003zM12.423 27.772h4.214v4.219c-.036.002-.073.007-.11.007q-1.26.001-2.52 0c-.77-.003-1.372-.473-1.547-1.206a1.5 1.5 0 0 1-.04-.335q-.003-1.3-.002-2.597c0-.026.003-.05.005-.088M31.99 16.644h-4.21v-4.226h2.626c.894.001 1.572.648 1.587 1.54.015.875.004 1.75.004 2.624 0 .016-.004.033-.007.062M27.772 31.52v-3.744h3.736zM19.395 20.796l-.9-.371c.107-.178.19-.36.31-.513.416-.54.975-.77 1.652-.743.53.022.995.184 1.377.561.589.582.652 1.737-.246 2.304-.04.025-.076.053-.136.095l.136.077c.474.257.8.63.875 1.176.102.75-.251 1.423-.917 1.784-.916.495-2.052.322-2.728-.42a2 2 0 0 1-.445-.834l.883-.369.055.162c.331.92 1.316.977 1.84.56a.88.88 0 0 0-.042-1.42 1.34 1.34 0 0 0-.8-.232c-.16.002-.32 0-.49 0v-.88c.15 0 .296.003.442 0 .258-.005.498-.066.71-.22a.78.78 0 0 0-.122-1.334c-.585-.273-1.163-.05-1.42.546-.008.02-.019.038-.035.071M25.617 19.31v5.971h-.957v-4.669l-.982.714-.487-.743q.045-.04.083-.069c.529-.38 1.058-.763 1.59-1.14a.37.37 0 0 1 .189-.06c.185-.009.371-.004.564-.004" />
    </g>
  </svg>
);
export default SvgAddToCalender;
