import { createFileRoute } from '@tanstack/react-router';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { DataStateHandler, EventCardLoading, EventCardV2 } from '@onesource/components';
import { useGetAllEventsQuery } from '@onesource/services';
import { TBaseEventSchema } from '@onesource/schemas';
import { PageHeader } from 'components/page-header';
import { useTheme } from '@mui/material';
import { EventCardActions } from 'components';

export const Route = createFileRoute('/_protected/events/')({
  component: EventsPage,
});

function EventsPage() {
  const queryResult = useGetAllEventsQuery();
  const theme = useTheme();

  return (
    <>
      <PageHeader
        title="Events"
        sx={{
          '.MuiStack-root button': { display: { xs: 'none', sm: 'flex' } },
          '& svg': { stroke: theme.palette.grey[100] },
          '& svg path': { fill: theme.palette.grey[100] },
          '& svg circle': { stroke: theme.palette.grey[100] },
        }}
      />
      <Box sx={{ pt: 7, pb: 6, background: theme => theme.palette.common.white, overflow: 'hidden' }}>
        <Container maxWidth="xl">
          <Grid container spacing={4}>
            <Grid item xs={12} mt={6.2}>
              <DataStateHandler
                queryResult={queryResult}
                entity="Events"
                gridProps={{
                  md: 12,
                  sm: 6,
                  xs: 12,
                }}
                LoadingComponent={EventCardLoading}
                IterativeComponent={(props: { item: TBaseEventSchema }) => {
                  return (
                    <EventCardV2
                      event={props.item}
                      key={props.item.id}
                      actions={<EventCardActions event={props.item} />}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
