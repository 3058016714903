import { FC } from 'react';
import Box from '@mui/material/Box';
import { darken, useTheme } from '@mui/material';
import { ClassesNavFilter } from './nav-filter';
import { useLocation } from '@tanstack/react-router';
import { PageHeader } from './page-header';

export const ClassesTopNav: FC = () => {
  const theme = useTheme();
  const { pathname } = useLocation();

  const filterPathname = pathname.split('/').at(-1) ?? 'all';
  const isByDate = filterPathname === 'by-date';

  return (
    <Box
      sx={{
        background: isByDate
          ? theme.palette.common.white
          : { xs: darken(theme.palette.grey[50], 0.01), md: theme.palette.common.white },
        pb: { xs: 1, md: 0 },
        borderRadius: '0px 0px 50px 50px',
      }}
    >
      <PageHeader title="Classes" />
      <ClassesNavFilter filterPathname={filterPathname} isByDate={isByDate} />
    </Box>
  );
};
