import { setTokenToAxios, TSetSession, TLeaderBoardUser } from '../axios';

const AUTH_CONST = {
  SESSION: 'session',
  USER: 'user',
};

export const getSession = (): string | null => {
  return localStorage.getItem(AUTH_CONST.SESSION);
};

export const setSession = (param: TSetSession) => {
  const { token, user } = param;
  localStorage.setItem(AUTH_CONST.SESSION, token);
  localStorage.setItem(AUTH_CONST.USER, JSON.stringify(user));
  setTokenToAxios(token);
};

export const getUser = (): TLeaderBoardUser | null => {
  const user = localStorage.getItem(AUTH_CONST.USER);
  return user ? JSON.parse(user) : null;
};

export const isLoggedIn = (): boolean => {
  const session = getSession();
  return !!session;
};

export const logout = () => {
  localStorage.removeItem(AUTH_CONST.SESSION);
  localStorage.removeItem(AUTH_CONST.USER);
};
