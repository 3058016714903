import { createFileRoute } from '@tanstack/react-router';
import { PlaylistPlayer } from 'pages/playlist-player';
import { object, string } from 'zod';

const RouteParamSchema = object({
  playlistId: string().min(1, 'playlist id is required'),
});

export const Route = createFileRoute('/_protected/playlists/$playlistId/play')({
  component: () => <PlaylistPlayer />,
  parseParams: params => {
    return RouteParamSchema.parse(params);
  },
});

export const usePlaylistIdSearch = Route.useSearch;
export const usePlaylistIdParams = Route.useParams;
