import type { SVGProps } from 'react';
const SvgParticipants = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 33 19"
    fontSize="1rem"
    {...props}
  >
    <g clipPath="url(#participants_svg__a)">
      <path
        fill="#CE3B4D"
        d="M0 16.056c.116-.476.192-.965.354-1.425.65-1.848 1.89-3.155 3.7-3.92.07-.028.137-.059.252-.11-1.25-.982-1.827-2.24-1.58-3.802.159-1.005.678-1.82 1.502-2.425a3.84 3.84 0 0 1 5.195.628c1.282 1.535 1.279 4.043-.706 5.566L10.6 11.62a9.3 9.3 0 0 1 3.314-1.804c-3.098-1.799-3.459-5.847-1.23-8.18 2.121-2.217 5.67-2.173 7.712.085 2.129 2.355 1.755 6.264-1.315 8.092a9.3 9.3 0 0 1 3.319 1.812l1.884-1.06c-1.138-.896-1.728-2.08-1.557-3.576.115-1.013.583-1.85 1.38-2.493 1.558-1.256 3.814-1.115 5.169.33.791.842 1.14 1.846 1.03 3-.094.99-.333 1.407-1.52 2.714.642.416 1.344.776 1.933 1.27 1.475 1.236 2.207 2.859 2.28 4.78.01.304-.102.527-.408.607-.345.09-.61-.177-.625-.616-.041-1.215-.427-2.31-1.188-3.26-1.976-2.471-5.433-2.622-7.568-.926q.21.254.417.513c1.25 1.599 1.884 3.416 1.938 5.439.007.239-.031.478-.268.57-.15.058-.4.073-.502-.013a.84.84 0 0 1-.255-.541c-.07-1.26-.376-2.45-.98-3.56-1.64-3.01-4.966-4.623-8.414-4.077-3.284.52-5.976 3.183-6.56 6.498-.065.368-.088.744-.119 1.117-.038.445-.216.672-.537.659-.324-.013-.511-.268-.498-.694.067-2.153.786-4.058 2.188-5.702.054-.064.106-.13.175-.212a5.1 5.1 0 0 0-2.24-1.02 5.45 5.45 0 0 0-6.52 5.19c-.008.28-.068.513-.357.618-.313.114-.499-.085-.679-.289zM12.287 5.229c-.016 2.286 1.863 4.191 4.151 4.208 2.352.018 4.245-1.82 4.273-4.15a4.213 4.213 0 0 0-4.198-4.262 4.217 4.217 0 0 0-4.225 4.204m-5.806 4.998C8 10.24 9.262 9.01 9.28 7.497A2.78 2.78 0 0 0 6.514 4.67a2.786 2.786 0 0 0-2.82 2.744c-.019 1.54 1.229 2.8 2.787 2.813m20.054-5.563c-1.528-.015-2.793 1.204-2.815 2.712a2.78 2.78 0 0 0 2.783 2.843 2.785 2.785 0 0 0 2.801-2.762c.014-1.528-1.226-2.778-2.77-2.793"
      />
    </g>
    <defs>
      <clipPath id="participants_svg__a">
        <path fill="#fff" d="M0 0h33v19H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgParticipants;
