import { TPlaylistItemReorder } from '@onesource/schemas';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import axios from '../axios';
import { ENTITY } from './entity';
import { getPlaylistQueryKey } from './use-get-playlist-query';

export type TPayload = {
  items: TPlaylistItemReorder[];
  id: string;
};

export const useUpdatePlaylistItemsOrderMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: TPayload) => {
      return axios.patch(`/${ENTITY}/reorder`, params);
    },
    onSuccess: async res => {
      queryClient.invalidateQueries({
        queryKey: getPlaylistQueryKey(res.data.id),
      });

      enqueueSnackbar(`Updating video order successful!`, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    },
    onError: () => {
      enqueueSnackbar('Unable to update video order', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    },
  });
};
