import MuiLink from '@mui/material/Link';
import { alpha } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { RycLogo } from '@onesource/components';
import { MobileMenuBars, VideoIcon } from '@onesource/icons';
import { getSession, logout, useGetAllProfileQuery } from '@onesource/services';
import { theme } from '@onesource/theme';
import { Link, LinkOptions, useMatch } from '@tanstack/react-router';
import { MouseEvent, useCallback, useMemo, useState } from 'react';
import { WP_MEMBERS_SITE } from 'config';

const routes = [
  { name: 'Fit Home', link: '/' },
  { name: 'Classes', link: '/classes/all' },
  { name: 'series', link: '/series' },
  { name: 'instructors', link: '/instructors' },
] as const;

export const Header = () => {
  const token = getSession();
  const isPlaylistRouteMatch = useMatch({ from: '/_protected/playlists', shouldThrow: false });
  const { data } = useGetAllProfileQuery({ token });
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenNavMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  }, []);

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = useCallback(() => {
    setAnchorElNav(null);
  }, []);

  const handleCloseUserMenu = useCallback(() => {
    setAnchorElUser(null);
  }, []);

  const dropdownItems = useMemo(
    () =>
      [
        {
          label: 'Profile',
          onClick: () => {
            const redirectUrl = `${WP_MEMBERS_SITE}/verify-token?token=${token}&redirect_url=${WP_MEMBERS_SITE}/itbz-profile`;
            window.open(redirectUrl, '_blank');
          },
        },
        {
          label: 'Logout',
          onClick: () => {
            logout();
            handleCloseNavMenu();
            const loginPageLink: LinkOptions['to'] = '/auth/login';
            window.location.href = loginPageLink;
          },
        },
      ] as const,
    [],
  );

  return (
    <>
      <Box sx={{ height: { md: 94, xs: 62 } }} />

      <AppBar position="fixed" color="default" sx={{ boxShadow: 'none' }}>
        <Container maxWidth="xl">
          <Toolbar
            disableGutters
            sx={{
              pt: { xs: 0.4 },
              pb: { xs: 0.4 },
              justifyContent: 'space-between',
            }}
          >
            <MuiLink href="https://restoreyourcore.com">
              <RycLogo
                fill={theme.palette.primary.main}
                sx={{
                  '& svg': {
                    width: { xs: '54px', md: '97px' },
                    height: { xs: '34px', md: '62px' },
                  },
                }}
              />
            </MuiLink>

            <Box
              sx={{
                flexGrow: 1,
                justifyContent: 'flex-end',
                display: { xs: 'none', md: 'flex' },
                '& .MuiTypography-root': {
                  fontWeight: '300',
                  color: theme => theme.palette.grey[500],
                },
                '& .active .MuiTypography-root': {
                  fontWeight: '500',
                  color: theme => theme.palette.primary.main,
                },
              }}
            >
              {routes.map(route => (
                <Link
                  key={route.name}
                  to={route.link}
                  style={{
                    textTransform: 'uppercase',
                    textDecoration: 'none',
                  }}
                >
                  <Typography variant="body2" sx={{ color: 'black', textDecoration: 'none', margin: '0 30px', py: 4 }}>
                    {route.name}
                  </Typography>
                </Link>
              ))}
            </Box>

            <Box
              sx={{
                display: { xs: 'flex', md: 'flex', gap: 5 },
                justifyContent: 'flex-end',
              }}
            >
              <Link to="/playlists" style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  size="large"
                  color="inherit"
                  sx={theme => {
                    return {
                      background: isPlaylistRouteMatch ? alpha(theme.palette.primary.main, 0.1) : 'transparent',
                    };
                  }}
                >
                  <VideoIcon style={{ fontSize: '25px', color: theme.palette.primary.main }} />
                </IconButton>
              </Link>

              {/* <IconButton
                size="large"
                // onClick={handleOpenNavMenu}
                color="inherit"
              >
                <NotificationIcon style={{ fontSize: '25px' }} />
              </IconButton>
              <IconButton
                size="large"
                // onClick={handleOpenNavMenu}
                color="inherit"
              >
                <HelpQuestionIcon style={{ fontSize: '25px' }} />
              </IconButton>
              <IconButton
                size="large"
                // onClick={handleOpenNavMenu}
                color="inherit"
              >
                <AppsIcon style={{ fontSize: '25px' }} />
              </IconButton> */}
              <IconButton onClick={handleOpenUserMenu}>
                <Avatar alt={data?.firstName} src={data?.avatar || undefined} />
              </IconButton>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {dropdownItems.map(item => (
                  <MenuItem key={item.label} onClick={item.onClick}>
                    <Typography textAlign="center">{item.label}</Typography>
                  </MenuItem>
                ))}
              </Menu>
              <IconButton
                size="large"
                onClick={handleOpenNavMenu}
                color="inherit"
                sx={{ display: { xs: 'flex', md: 'none' } }}
              >
                <MobileMenuBars style={{ fontSize: '25px' }} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {routes.map(route => (
                  <MenuItem
                    key={route.name}
                    onClick={() => {
                      handleCloseNavMenu();
                    }}
                    sx={{
                      '& a': { color: theme.palette.grey[500] },
                      '& a.active': { color: theme => theme.palette.primary.main },
                    }}
                  >
                    <Link
                      to={route.link}
                      style={{
                        textTransform: 'uppercase',
                        textDecoration: 'none',
                      }}
                    >
                      {route.name}
                    </Link>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            {/* <Box
              sx={{
                flexGrow: 0,
                ml: 3,
                display: {
                  xs: 'none',
                  md: 'flex',
                },
              }}
            >
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {dropdownItems.map(item => (
                  <MenuItem key={item.label} onClick={item.onClick}>
                    <Typography textAlign="center">{item.label}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box> */}
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};
