import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { memo, useState } from 'react';
import { Lock } from '@onesource/icons';
import { ArrowRightIcon } from '@mui/x-date-pickers';

const SubscirbeCard = memo(() => (
  <Box
    sx={{
      maxHeight: '211px',
      width: 'clamp(300px, calc(100% - 64px), 494px)',
      height: 'clamp(150px, calc(100% - 64px), 211px)',
      padding: '8px',
      borderRadius: 6,
      display: 'grid',
      placeItems: 'center',
      gap: '4px',
      backgroundColor: theme => theme.palette.common.white,
    }}
    onClick={event => {
      event.preventDefault();
      event.stopPropagation();
    }}
  >
    <Typography
      sx={{
        fontSize: '16px',
        fontWeight: '600',
        textAlign: 'center',
        color: theme => theme.palette.grey[600],
      }}
    >
      You are currently not subscribed to RYC® FIT. Please subscribe to unlock the videos
    </Typography>
    <Button
      sx={{
        width: 'fit-content',
      }}
      variant="contained"
      endIcon={
        <Box
          sx={{
            display: 'grid',
            placeItems: 'center',
            padding: '4px',
            width: 'clamp(20px, 100%, 46px)',
            aspectRatio: '1/1',
            backgroundColor: theme => theme.palette.primary.dark,
            borderRadius: '50%',
          }}
        >
          <ArrowRightIcon />
        </Box>
      }
    >
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: '600',
          textAlign: 'center',
          lineHeight: '26px',
          color: theme => theme.palette.common.white,
        }}
      >
        Subscribe to RYC® FIT now!
      </Typography>
    </Button>
  </Box>
));

const LockButtn = memo(() => (
  <Box sx={{ position: 'relative' }}>
    <Lock fontSize="50px" color="white" />
  </Box>
));

export const LockedCardOverlay = ({ isLocked }: { isLocked: boolean }) => {
  const [clicked, setClicked] = useState(false);

  const toggleBackdrop = () => {
    setClicked(prev => !prev);
  };

  if (!isLocked) {
    return null;
  }

  return (
    <Backdrop
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'grid',
        placeItems: 'center',

        borderRadius: 6,
        overflow: 'hidden',
        cursor: 'pointer',
      }}
      open={isLocked}
      onClick={toggleBackdrop}
    >
      {clicked && <SubscirbeCard />}
      {!clicked && <LockButtn />}
    </Backdrop>
  );
};
