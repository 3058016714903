import { darken, styled, useTheme } from '@mui/material/styles';
import { CSSProperties, FC } from 'react';
import { SlArrowLeft, SlArrowRight } from 'react-icons/sl';
import Slider, { Settings as SliderSettings } from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

type CustomArrowProps = {
  className?: string;
  style?: CSSProperties;
  onClick?: () => void;
};

const CustomArrow = styled('div')(() => ({
  height: '40px',
  width: '40px',
  background: 'transparent',
  zIndex: 1,
  '&::before': {
    display: 'none',
  },
}));

const CustomSlider = styled(Slider)(() => ({}));

const NextArrow: FC<CustomArrowProps> = ({ className, style, onClick }) => {
  const theme = useTheme();
  return (
    <CustomArrow className={className} style={{ ...style, right: '-50px' }} onClick={onClick}>
      <SlArrowRight
        size={14}
        style={{
          color: darken(theme.palette.common.white, 0.799),
          border: '1px solid black',
          borderRadius: '50%',
          padding: '12px',
        }}
      />
    </CustomArrow>
  );
};

const PrevArrow: FC<CustomArrowProps> = ({ className, style, onClick }) => {
  const theme = useTheme();
  return (
    <CustomArrow className={className} style={{ ...style, left: '-50px' }} onClick={onClick}>
      <SlArrowLeft
        size={14}
        style={{
          color: darken(theme.palette.common.white, 0.799),
          border: '1px solid black',
          borderRadius: '50%',
          padding: '12px',
        }}
      />
    </CustomArrow>
  );
};

type SlideshowProps<T> = {
  slides: T[];
  renderSlide: (slide: T) => JSX.Element;
  settings?: SliderSettings;
};

const defaultSettings: SliderSettings = {
  arrows: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  className: 'fit-fe-slide',
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

export const Slideshow = <T,>({ slides, renderSlide, settings }: SlideshowProps<T>) => {
  const sliderSettings = { ...defaultSettings, ...settings };
  return <CustomSlider {...sliderSettings}>{slides.map(slide => renderSlide(slide))}</CustomSlider>;
};
