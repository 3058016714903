import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { PageHeader } from 'components/page-header';
import { FC } from 'react';
import { FaPlayCircle } from 'react-icons/fa';

export const PlaylistDetailsLoading: FC = () => {
  const theme = useTheme();

  return (
    <Box sx={{ pb: 6, background: theme.palette.common.white, overflow: 'hidden' }}>
      <PageHeader title="Playlist" />

      <Container maxWidth="xl" sx={{ position: 'relative', pb: 3 }}>
        <Grid container spacing={2} mt={8} mb={12}>
          <Grid item flexGrow={1}>
            <Stack direction="row" alignItems="center">
              <Skeleton
                variant="rectangular"
                width="200px"
                height={40}
                sx={{
                  '&:after': {
                    content: '" "',
                    display: 'block',
                    height: '4px',
                    width: '25px',
                    background: theme.palette.primary.main,
                    top: '40px',
                    position: 'relative',
                  },
                }}
              />
              <Box component="span" sx={{ position: 'relative', left: '10px' }}>
                <IconButton
                  disabled
                  size="large"
                  sx={{
                    p: 0,
                    color: theme.palette.primary.main,
                    transition: 'color 0.3s',
                    '&:hover': {
                      color: theme.palette.primary.dark,
                    },
                  }}
                >
                  <FaPlayCircle size={50} />
                </IconButton>
              </Box>
            </Stack>

            <Stack direction="row" alignItems="center" mt={3}>
              <Skeleton variant="rectangular" height={25} width={100} />

              <Box
                sx={{
                  borderRight: `1px solid ${theme.palette.grey[500]}`,
                  height: '20px',
                  display: 'inline-block',
                  position: 'relative',
                  top: '2px',
                  mx: 3,
                }}
              />

              <Skeleton variant="rectangular" height={25} width={160} />
            </Stack>
          </Grid>

          <Grid item>
            <Skeleton variant="rectangular" width={150} height={40} sx={{ borderRadius: 5 }} />
          </Grid>
        </Grid>

        {[...Array(5)].map((_, index) => (
          <Stack
            key={`playlist-details-skeleton-stack-${index}`}
            sx={{ p: 1.5, borderRadius: 3, mb: 2, backgroundColor: theme.palette.grey[50] }}
            direction="row"
            alignItems="center"
            spacing={2}
          >
            <Stack sx={{ mb: 2 }} direction="row" alignItems="center">
              <Skeleton height={40} width={40} variant="circular" sx={{ mr: 2 }} />
              <Skeleton height={50} width={50} variant="circular" />
            </Stack>

            <Stack sx={{ mb: 2, flexGrow: 1 }}>
              <Skeleton height={15} width={150} variant="rectangular" sx={{ mb: 1 }} />
              <Skeleton height={10} width={150} variant="rectangular" />
            </Stack>

            <Stack direction="row" alignItems="center" sx={{ flexGrow: 1 }}>
              <Skeleton height={40} width={40} variant="circular" sx={{ mr: 1.6 }} />
              <Skeleton height={25} width={100} variant="rectangular" sx={{ borderRadius: '15px' }} />
            </Stack>

            <Stack sx={{ mb: 2 }} direction="row" alignItems="center">
              <Skeleton height={30} width={40} variant="rectangular" sx={{ mr: 2 }} />
              <Skeleton height={30} width={40} variant="rectangular" sx={{ mr: 2 }} />
              <Skeleton height={30} width={40} variant="rectangular" sx={{ mr: 2 }} />
            </Stack>

            <Skeleton height={50} width={50} variant="circular" sx={{ mr: 2 }} />
          </Stack>
        ))}
      </Container>
    </Box>
  );
};
