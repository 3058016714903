import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { AddEditPlaylistDialog, LoadingError } from '@onesource/components';
import { ContentFilterTypeEnum } from '@onesource/schemas';
import {
  useCompleteContentMutation,
  useGetAllPlaylistsQuery,
  useGetContentQuery,
  useGetContentsQuery,
} from '@onesource/services';
import { usePlaylistMenuService } from 'components/add-to-playlist/service';
import { usePlaylistAction } from 'hooks/use-playlist-actions';
import { FC, useCallback } from 'react';
import { useClassDetailsPageParams } from 'routes/_protected/classes/details/$classId';
import { RelatedPosts } from '../components/sections/related-posts';
import { VideoSection } from '../components/sections/video-section';
import { VideoSectionLoading } from '../components/sections/video-section/loading';

const SliderSettings = {
  slidesToShow: 3,
  slidesToScroll: 3,
  arrows: true,
  infinite: true,
  centerMode: false,
  responsive: [
    {
      breakpoint: 960,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        centerMode: false,
        infinite: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '15%',
        infinite: true,
      },
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '5%',
        infinite: true,
      },
    },
  ],
};
export const ClassDetails: FC = () => {
  const { classId } = useClassDetailsPageParams();

  const {
    contextClassId,
    onAddToPlaylist,
    createPlaylist,
    isCreatePending,
    onClosePlaylistDialog,
    onClickCreate,
    isCreatePlaylistDialogOpen,
  } = usePlaylistAction();

  const {
    data: relatedContent,
    isLoading: relatedContentLoading,
    isError: isRelatedContentError,
    error: relatedContentError,
    refetch: refetchRelatedContent,
  } = useGetContentsQuery({
    filters: {
      type: ContentFilterTypeEnum.mostViewedWeekly,
      perPage: 4,
    },
  });

  const {
    data: content,
    isLoading: contentLoading,
    isError: isContentError,
    error: contentError,
    refetch: refetchContent,
  } = useGetContentQuery({
    contentId: classId,
  });

  const { data: playlistRes, isLoading: isPlaylistLoading, error: isPlaylistError } = useGetAllPlaylistsQuery();

  const { mutateAsync: completeContentMutation } = useCompleteContentMutation();

  const completeContentHandler = useCallback(async (contentId: string) => {
    await completeContentMutation({ contentId });
  }, []);

  const { setPlaylistMenu } = usePlaylistMenuService();

  const onClickAddPlaylistHandler = useCallback(
    (anchorEl: HTMLElement, contentId: string) => {
      setPlaylistMenu({
        contentId,
        anchorEl,
        onAddToPlaylist,
        onClickCreate,
      });
    },
    [onAddToPlaylist, onClickCreate, onClosePlaylistDialog],
  );

  const contents = relatedContent?.items || [];
  const isLoading = relatedContentLoading || contentLoading;

  if (isContentError) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <LoadingError
          sx={{ width: '100%' }}
          entity="Class"
          message={contentError.message}
          refetch={refetchContent}
          errorCode={contentError.code}
        />
      </Container>
    );
  }

  if (isPlaylistError) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <LoadingError
          sx={{ width: '100%' }}
          entity="Class"
          message={isPlaylistError.message}
          refetch={refetchContent}
          errorCode={isPlaylistError.code}
        />
      </Container>
    );
  }

  if (isRelatedContentError) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <LoadingError
          sx={{ width: '100%' }}
          entity="Class"
          message={relatedContentError.message}
          refetch={refetchRelatedContent}
          errorCode={relatedContentError.code}
        />
      </Container>
    );
  }

  if (isLoading || isPlaylistLoading) {
    return <VideoSectionLoading sx={{ mt: 2 }} />;
  }

  if (!content || !playlistRes) {
    return null;
  }

  return (
    <>
      <VideoSection
        sx={{ mt: 4 }}
        onComplete={completeContentHandler}
        content={content}
        onClickAddPlaylist={onClickAddPlaylistHandler}
      />

      <Box
        sx={{
          '& .slick-slide': {
            display: 'flex',
            alignItems: 'center',
          },
        }}
      >
        <RelatedPosts sectionTitle="You may like" contents={contents} sliderSettings={SliderSettings} />
      </Box>

      <AddEditPlaylistDialog
        isPending={isCreatePending}
        open={isCreatePlaylistDialogOpen}
        onClose={onClosePlaylistDialog}
        onSave={createPlaylist}
        contentId={contextClassId}
      />
    </>
  );
};
