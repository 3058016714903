import { IPaginationForRQ, TError, TInstructor } from '@onesource/schemas';
import { useQuery } from '@tanstack/react-query';
import axios from '../axios';
import { ENTITY } from './entity';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TGetInstructorFilter = Record<string, any>;

interface IGetTeamsParams {
  filters?: TGetInstructorFilter;
}

export const getInstructorsQueryKey = (filters: TGetInstructorFilter = {}) => {
  const serializeFilters = Object.entries(filters || {});
  return [ENTITY, serializeFilters];
};

export const useGetInstructorsQuery = (param: IGetTeamsParams = {}) => {
  const { filters } = param;
  return useQuery<IPaginationForRQ<TInstructor>, TError>({
    queryKey: getInstructorsQueryKey(filters),
    queryFn: async () => {
      const res = await axios.get<IPaginationForRQ<TInstructor>>(ENTITY, {
        params: filters,
      });
      return res.data;
    },
  });
};
