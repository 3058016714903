import type { SVGProps } from 'react';
const SvgNewBadge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 97 101"
    fontSize="1rem"
    {...props}
  >
    <g clipPath="url(#new-badge_svg__a)" filter="url(#new-badge_svg__b)">
      <path fill="#FFB7BF" d="M11.255 7.6 4 0v7.6zM85.467 93h7.348l-7.477-7.83z" />
      <path fill="#CE3B4D" d="M85.338 85.17 4 0h40.277L93 51.02 92.814 93z" />
      <path
        fill="#fff"
        d="m37.613 22.433 6.898-6.96 1.321 1.332-.897 9.118-.64-.647 4.967-5.011 1.577 1.59-6.9 6.961-1.31-1.322.887-9.128.64.646-4.966 5.011zm13.547 5.355 3.322 3.351-1.242 1.253-3.322-3.351zm-2.72 2.983 3.755 3.788-1.281 1.293-5.342-5.39 6.9-6.96 5.213 5.26-1.282 1.293-3.626-3.659zm5.304 7.936 4.642-9.237 1.656 1.67-4.15 8.144-.828-.835 8.161-4.097 1.478 1.491-4.14 8.154-.797-.806 8.12-4.136 1.528 1.541-9.156 4.683-1.715-1.73 3.617-7.169.454.458-7.155 3.6zm12.298 7.575 4.199-4.852 1.833 1.85-4.81 4.235zm-1.862 3.112q-.415-.417-.415-.954t.365-.905a1.14 1.14 0 0 1 .887-.358q.522.01.937.428.423.428.423.944.02.517-.355.895a1.21 1.21 0 0 1-.896.368q-.523.01-.947-.418"
      />
    </g>
    <defs>
      <clipPath id="new-badge_svg__a">
        <path fill="#fff" d="M93 0v93H4V0z" />
      </clipPath>
      <filter
        id="new-badge_svg__b"
        width={97}
        height={101}
        x={0}
        y={0}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_279_579" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_279_579" result="shape" />
      </filter>
    </defs>
  </svg>
);
export default SvgNewBadge;
