import { useQuery } from '@tanstack/react-query';
import { ENTITY } from './entity';
import axios from '../axios';
import {
  IPagination,
  IPaginationForRQ,
  TBaseSlideshowSchema,
  TError,
  TSlideshowFiltersSchema,
} from '@onesource/schemas';

export const getSlideshowsQueryKey = (filters?: TSlideshowFiltersSchema) => {
  const keys: unknown[] = [ENTITY];
  if (filters) {
    keys.push(Object.entries(filters));
  }
  return keys;
};

type TParams = {
  filters?: TSlideshowFiltersSchema;
  refetchInterval?: number | false;
};

const DEFAULT_REFETCH_INTERVAL = 5 * 60 * 1000;

export const useGetSlideshowsQuery = (params?: TParams) => {
  const filters = params?.filters;
  const refetchInterval = params?.refetchInterval ?? DEFAULT_REFETCH_INTERVAL;

  return useQuery<IPaginationForRQ<TBaseSlideshowSchema>, TError>({
    queryKey: getSlideshowsQueryKey(filters),
    queryFn: async () => {
      const response = await axios.get<IPagination<TBaseSlideshowSchema>>(ENTITY, {
        params: filters,
      });
      return response.data.data;
    },
    refetchInterval,
  });
};

export type TGetSlideshowQueryReturn = ReturnType<typeof useGetSlideshowsQuery>;
