import type { SVGProps } from 'react';
const SvgCalender = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 19"
    fontSize="1rem"
    {...props}
  >
    <g fill="currentColor" clipPath="url(#calender_svg__a)">
      <path d="M9.99 5.424h8.25c.31 0 .314 0 .31-.3-.006-.438.007-.878-.034-1.315-.064-.68-.617-1.147-1.316-1.148-.366 0-.732-.004-1.098.001-.134.003-.191-.046-.191-.176.003-.344-.007-.689.01-1.031.002-.061.108-.166.168-.166.544-.003 1.096-.037 1.633.033.955.125 1.599.697 2.003 1.532.207.428.275.887.274 1.364-.006 2.678 0 5.356 0 8.034 0 1.316.01 2.633-.006 3.95-.012 1.023-.438 1.847-1.327 2.416-.41.263-.866.378-1.355.378h-4.977c-3.168 0-6.337-.006-9.504.004-1.164.004-1.995-.501-2.522-1.498-.19-.358-.301-.748-.301-1.156-.003-4.134-.015-8.269.006-12.403C.02 2.74.79 1.706 2.038 1.351c.308-.087.652-.058.98-.07.277-.01.553.001.83-.004.135-.003.216.042.218.183.004.358.015.717.004 1.074 0 .044-.118.117-.183.12-.328.011-.659.02-.987.002-.95-.048-1.485.683-1.466 1.454.008.359.013.718 0 1.075-.006.182.051.24.24.24.852-.009 1.703-.005 2.556-.005H9.99zM1.438 6.837v8.997c0 .317-.033.628.1.945.198.474.648.83 1.171.83h14.57c.32 0 .592-.119.826-.327.346-.31.445-.713.445-1.15V7.144c0-.308 0-.309-.321-.309H1.437z" />
      <path d="M9.972 2.66H6.837q-.297 0-.299-.302c0-.27.014-.541-.004-.81-.014-.217.069-.273.28-.273 1.971.007 3.942.004 5.912.004.164 0 .33.007.492-.001.163-.01.232.052.23.214q-.01.481 0 .965c.003.163-.067.208-.229.208-1.082-.005-2.164-.003-3.247-.003zM15.425 1.967c0 .394-.006.79 0 1.184.008.459-.342.757-.74.77-.404.014-.73-.315-.735-.75q-.012-1.218 0-2.434c.005-.433.31-.734.718-.737.415-.003.715.284.738.717.01.21.004.423.004.635v.615zM6.015 1.944v1.249c-.002.396-.28.707-.648.73-.39.023-.758-.26-.769-.663-.02-.714.005-1.429.011-2.144 0-.117.015-.234.006-.35-.02-.28.124-.589.385-.697.47-.195.99.043 1.01.561.018.438.005.876.005 1.314M7.11 11.203l2.227 2.718c.334-.462.653-.9.97-1.34.977-1.347 1.957-2.693 2.929-4.045.095-.133.158-.137.28-.047q.42.309.854.595c.117.077.123.142.046.248q-2.091 2.887-4.18 5.775-.111.15-.219.303c-.35.494-.88.514-1.262.046-.428-.522-.854-1.048-1.28-1.57-.45-.553-.898-1.106-1.356-1.652-.103-.122-.095-.184.03-.277.318-.238.627-.489.96-.751z" />
    </g>
    <defs>
      <clipPath id="calender_svg__a">
        <path fill="#fff" d="M0 0h20v19H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCalender;
