import Stack from '@mui/material/Stack';
import { SxProps, alpha, darken, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { Calender, Cardio, Clock, Energy, Glass, Relax, Sweat, VideoIcon } from '@onesource/icons';
import { FC, memo } from 'react';
import { FaInfo } from 'react-icons/fa6';

export type VideoSectionProps = {
  sx?: SxProps;
};

export const VideoSectionLoading: FC<VideoSectionProps> = memo(props => {
  const { sx } = props;
  const theme = useTheme();

  return (
    <Box sx={sx}>
      <Container maxWidth="xl">
        <Box sx={{ position: 'relative', zIndex: 1 }}>
          <Grid container spacing={4}>
            <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row' }}>
              <Box sx={{ flex: 1 }}>
                <Skeleton width={50} height={80} />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 11, mb: 3 }}>
                <Skeleton height={80} width="70%" />

                <Skeleton width={180} />
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ padding: '58.25%, 0, 0,0', position: 'relative' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  background: theme.palette.grey[100],
                  borderRadius: { xs: '10px 10px 0 0', md: '30px 30px 0 0' },
                  padding: { xs: 1.5, md: '15px 30px' },
                }}
              >
                <Box sx={{ display: 'flex', gap: { xs: 1, md: 2 } }}>
                  <Cardio color={alpha(theme.palette.common.black, 0.7)} fontSize={22} />
                  <Typography variant="body2">
                    <Skeleton sx={{ width: { xs: 30, md: 90 } }} />
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: { xs: 1, md: 2 } }}>
                  <Clock color={alpha(theme.palette.common.black, 0.7)} fontSize={20} />
                  <Typography variant="body2">
                    <Skeleton sx={{ width: { xs: 30, md: 90 } }} />
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: { xs: 1, md: 2 } }}>
                  <Calender color={alpha(theme.palette.common.black, 0.7)} fontSize={20} />
                  <Typography variant="body2">
                    <Skeleton sx={{ width: { xs: 30, md: 90 } }} />
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: { xs: 1, md: 2 } }}>
                  <VideoIcon color={alpha(theme.palette.common.black, 0.7)} fontSize={20} />
                  <Typography variant="body2" sx={{ display: { xs: 'none', md: 'block' } }}>
                    <Skeleton width={90} />
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ textAlign: 'center', mb: 4, mt: -2 }}>
                <Card
                  sx={{
                    boxShadow: 'none',
                    background: 'transparent',
                  }}
                >
                  <Skeleton variant="rectangular" sx={{ height: { xs: '250px', md: '600px' }, mt: 2 }} />
                </Card>
              </Box>
            </Grid>
            <Grid item xs={12} md={9}>
              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Stack direction="row" spacing={1}>
                    <Skeleton variant="rectangular" height={30} width={100} />
                    <Skeleton variant="rectangular" height={30} width={100} />
                  </Stack>
                </Box>

                <Skeleton sx={{ mt: 2 }} />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />

                <Skeleton variant="rectangular" width={100} height={28} sx={{ borderRadius: '70px', mt: 2 }} />
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ mb: 7, ...sx }}>
                <Box
                  sx={{
                    backgroundColor: theme.palette.grey[100],
                    borderRadius: '30px 30px 0 0',
                    p: 3,
                    paddingLeft: 3.5,
                    display: 'flex',
                    gap: 2,
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: '700', color: darken(theme.palette.common.white, 0.799) }}
                  >
                    <Skeleton variant="rectangular" width={120} />
                  </Typography>
                  <FaInfo style={{ fontSize: '16px', color: theme.palette.grey[500] }} />
                </Box>
                <Box sx={{ mb: 2, backgroundColor: theme.palette.grey[50], p: 3.5 }}>
                  <Stack direction="row" mb={2.5} alignItems="center">
                    <Energy fontSize={22} color={theme.palette.grey[700]} />
                    <Skeleton width={180} height={20} sx={{ ml: 2 }} />
                    <Skeleton variant="rectangular" width={40} height={30} sx={{ ml: 2 }} />
                  </Stack>
                  <Stack direction="row" mb={2.5} alignItems="center">
                    <Sweat fontSize={22} color={theme.palette.grey[700]} />
                    <Skeleton width={180} height={20} sx={{ ml: 2 }} />
                    <Skeleton variant="rectangular" width={40} height={30} sx={{ ml: 2 }} />
                  </Stack>
                  <Stack direction="row" mb={2.5} alignItems="center">
                    <Glass fontSize={22} color={theme.palette.grey[700]} />
                    <Skeleton width={180} height={20} sx={{ ml: 2 }} />
                    <Skeleton variant="rectangular" width={40} height={30} sx={{ ml: 2 }} />
                  </Stack>
                  <Stack direction="row" mb={2.5} alignItems="center">
                    <Relax fontSize={22} color={theme.palette.grey[700]} />
                    <Skeleton width={180} height={20} sx={{ ml: 2 }} />
                    <Skeleton variant="rectangular" width={40} height={30} sx={{ ml: 2 }} />
                  </Stack>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
});
