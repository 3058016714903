import { alpha } from '@mui/material';
import Menu from '@mui/material/Menu';
import { FC, ReactNode } from 'react';

export interface IContextDialogProps {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  children: ReactNode | ReactNode[];
}

export const ContextMenu: FC<IContextDialogProps> = props => {
  const { onClose, anchorEl, children } = props;

  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={!!anchorEl}
      onClose={onClose}
      onClick={onClose}
      slotProps={{
        paper: {
          elevation: 0,
          sx: {
            boxShadow: theme => `0px 0px 9px 4px ${alpha(theme.palette.common.black, 0.1)}`,
            p: `10px 20px `,
            borderRadius: 7,
            overflow: 'visible',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 30,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {children}
    </Menu>
  );
};
