import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Box from '@mui/material/Box';

type TOption = {
  id: string;
  label: string;
  value: string | number;
};

type TProps = TextFieldProps & {
  name: string;
  maxHeight?: boolean | number;
  fullWidth?: boolean;
  options: TOption[];
};

export const RHFAutoComplete: FC<TProps> = props => {
  const { name, helperText, options, autoComplete: _autoComplete, ...rest } = props;
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const { ref } = field;
        return (
          <Autocomplete
            {...field}
            fullWidth
            value={options.find(option => option.value === field.value) ?? null}
            onChange={(_, value) => {
              field.onChange(value ? value.value : null);
            }}
            options={options}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            getOptionLabel={(option: TOption) => option.label} // Ensure option is of type TOption
            renderInput={params => (
              <TextField
                {...params}
                inputRef={ref}
                fullWidth
                error={!!error}
                helperText={error ? error.message : helperText}
                label={rest.label}
                autoComplete="off"
              />
            )}
            renderOption={(props, option, { selected }) => (
              <Box
                component="li"
                {...props}
                sx={{
                  color: theme => (selected ? theme.palette.primary.main : theme.palette.common.black),
                }}
                key={option.id}
              >
                <Typography variant="body2">{option.label}</Typography>
              </Box>
            )}
          />
        );
      }}
    />
  );
};
