import { FC, ReactNode } from 'react';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';

export interface IBreadcrumbProps {
  children: ReactNode;
}

export const Breadcrumbs: FC<IBreadcrumbProps> = props => {
  const { children } = props;
  return (
    <MuiBreadcrumbs
      separator="|"
      sx={{
        '& .MuiBreadcrumbs-separator': {
          color: theme => theme.palette.grey[500],
        },
        '& a': {
          fontSize: '16px',
          color: theme => theme.palette.grey[500],
        },
        '& .MuiTypography-root': {
          fontSize: '16px',
          color: theme => theme.palette.grey[800],
        },
        '& li:nth-of-type(2)': {
          display: 'none',
        },
        '& li:nth-of-type(1)': {
          marginRight: '26px',
        },
      }}
    >
      {children}
    </MuiBreadcrumbs>
  );
};
