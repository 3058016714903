import { alpha, darken, SxProps, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useGetAllSeriesQuery } from '@onesource/services';
import { Link, RegisteredRouter, RoutePaths } from '@tanstack/react-router';
import { FC, useMemo, useState } from 'react';
import { SlArrowRight } from 'react-icons/sl';
import { FitnessSeries, FitnessSeriesLoading, LoadingError, Slideshow } from '@onesource/components';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

export type TFocussedFitnessProps = {
  title: string;
  description: string;
  buttonLink: RoutePaths<RegisteredRouter['routeTree']>;
  buttonLabel: string;
  sx?: SxProps;
};

export const FocusedFitnessSection: FC<TFocussedFitnessProps> = ({
  title,
  description,
  buttonLink,
  buttonLabel,
  sx,
}) => {
  const theme = useTheme();

  const {
    data: seriesQueryRes,
    isLoading,
    error,
    refetch,
  } = useGetAllSeriesQuery({
    filters: { perPage: 8 },
  });

  const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);

  const handleToggleDescription = () => {
    setIsDescriptionVisible(!isDescriptionVisible);
  };
  const slides = useMemo(() => seriesQueryRes?.items || [], [seriesQueryRes]);

  const SliderSettings = {
    slidesToShow: 3,
    slidesToScroll: 3,
    dot: false,
    arrows: false,
    autoplay: false,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
        },
      },
    ],
  };

  if (isLoading) {
    return (
      <Box sx={{ background: theme.palette.grey[100], ...sx }}>
        <Container maxWidth="xl" sx={{ mt: 0, pt: 8, pb: 8 }}>
          <Stack flexDirection="column" justifyContent="center" alignItems="center">
            <Typography variant="h2" sx={{ mb: 1, width: 450 }}>
              <Skeleton />
            </Typography>
            <Typography sx={{ mb: 1, width: 450, display: { xs: 'none', md: 'block' } }}>
              <Skeleton />
            </Typography>
            <Typography sx={{ mb: 5, width: 800, display: { xs: 'none', md: 'block' } }}>
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </Typography>
          </Stack>

          <Grid container spacing={4}>
            {Array.from({ length: 3 }).map((_x, index) => (
              <Grid xs={12} md={4} item key={index}>
                <FitnessSeriesLoading />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ background: theme.palette.grey[100], ...sx }}>
        <Container maxWidth="xl" sx={{ mt: 0, pt: 8, pb: 8 }}>
          <LoadingError
            sx={{ width: '100%' }}
            entity="Class"
            message={error.message}
            refetch={refetch}
            errorCode={error.code}
          />
        </Container>
      </Box>
    );
  }

  return (
    <Box sx={{ background: theme.palette.grey[100], ...sx }}>
      <Container maxWidth="xl" sx={{ mt: 0, pt: { xs: 5, md: 8 }, pb: { xs: 5, md: 8 } }}>
        <Grid container spacing={{ xs: 2, md: 4 }}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                justifyContent: { xs: 'space-between', md: 'center' },
              }}
            >
              <Typography variant="h2" sx={{ mb: 3 }}>
                {title}
              </Typography>

              <Box
                sx={{
                  display: { xs: 'flex', md: 'none' },
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: alpha(theme.palette.primary.light, 0.12),
                    height: '40px',
                    width: '40px',
                    borderRadius: '50%',
                    cursor: 'pointer',
                  }}
                  onClick={handleToggleDescription}
                >
                  {isDescriptionVisible ? <IoIosArrowUp size={25} /> : <IoIosArrowDown size={25} />}
                </Box>
              </Box>
            </Box>
            <Typography
              variant="body2"
              sx={{
                color: theme => darken(theme.palette.common.white, 0.799),
                lineHeight: 1.8,
                textAlign: { xs: 'left', md: 'center' },
                display: { xs: isDescriptionVisible ? 'block' : 'none', md: 'block' },
              }}
            >
              <span dangerouslySetInnerHTML={{ __html: description }} />
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              m: 'auto',
              '& .slick-track>div>div': { margin: 'auto' },
              '& .slick-track': {
                ml: { xs: -0.4 },
                gap: { xs: 1, md: 4 },
                display: 'flex',

                alignItems: 'center',
              },
            }}
          >
            <Slideshow
              slides={slides}
              renderSlide={fitnessItem => <FitnessSeries fitnessItem={fitnessItem} key={fitnessItem.id} />}
              settings={SliderSettings}
            />
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'center', mt: 3 }}>
            <Link to={buttonLink}>
              <Button
                endIcon={
                  <Box
                    sx={{
                      background: theme.palette.primary.main,
                      borderRadius: '100%',
                      padding: '14px',
                      lineHeight: 0,
                    }}
                  >
                    <SlArrowRight
                      style={{
                        height: '17px',
                        width: '17px',
                      }}
                    />
                  </Box>
                }
                sx={{ minWidth: '160px', justifyContent: 'space-between', paddingLeft: '20px' }}
              >
                {buttonLabel}
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
