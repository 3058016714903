import { alpha, useTheme } from '@mui/material';
import { FC } from 'react';

export type TProps = {
  point: number;
  width?: string;
};

export const PointBadge: FC<TProps> = props => {
  const { point, width } = props;
  const theme = useTheme();

  const breakpoints = {
    0: {
      backgroundColor: alpha(theme.palette.primary.main, 0.25),
      color: theme.palette.common.black,
    },
    5: {
      backgroundColor: alpha(theme.palette.primary.main, 0.25),
      color: theme.palette.common.black,
    },
    10: {
      backgroundColor: alpha(theme.palette.primary.main, 0.25),
      color: theme.palette.common.black,
    },
    30: {
      backgroundColor: alpha(theme.palette.primary.main, 0.25),
      color: theme.palette.common.black,
    },
    50: {
      backgroundColor: alpha(theme.palette.primary.main, 0.25),
      color: theme.palette.common.black,
    },
    100: {
      backgroundColor: alpha(theme.palette.primary.main, 0.4),
      color: theme.palette.common.black,
    },
    300: {
      backgroundColor: alpha(theme.palette.primary.main, 0.6),
      color: theme.palette.common.white,
    },
    500: {
      backgroundColor: alpha(theme.palette.primary.main, 0.75),
      color: theme.palette.common.white,
    },
    1000: {
      backgroundColor: alpha(theme.palette.primary.main, 0.85),
      color: theme.palette.common.white,
    },
    1500: {
      backgroundColor: alpha(theme.palette.primary.main, 1),
      color: theme.palette.common.white,
    },
  };

  const pointFloor = Object.keys(breakpoints)
    .sort((a, b) => Number(b) - Number(a))
    .find(key => point >= Number(key)) as unknown as keyof typeof breakpoints;

  const colorConfig = breakpoints[pointFloor || 0];

  return (
    <svg
      style={{ transform: 'scale(1.1)', width }}
      xmlSpace="preserve"
      x={0}
      y={0}
      viewBox="0 0 453.9 440.8"
      {...props}
    >
      <circle
        cx={227.6}
        cy={217.7}
        r={191.9}
        style={{
          fill: colorConfig.backgroundColor,
          stroke: '#414042',
          strokeWidth: 10,
          strokeMiterlimit: 10,
        }}
      />
      <path
        style={{ fill: colorConfig.color }}
        d="m75.6 131.5 11.3 7.9 12.9-4.9-4 13.2 8.6 10.8-13.8.3-7.6 11.5-4.5-13.1-13.3-3.6 11-8.3zM53.7 179.7l13.2 3.9 10.8-8.6.3 13.8 11.6 7.5-13 4.6-3.6 13.3-8.4-11-13.8.7 7.8-11.3zM51.3 234l13.8-.8 7.1-11.8 5.1 12.8 13.4 3.1-10.6 8.8 1.2 13.8-11.7-7.5-12.7 5.4 3.5-13.4zM64.3 282.9l13.1-4.5 3.7-13.3 8.3 11 13.8-.6-7.9 11.4 4.8 12.9-13.2-4.1-10.8 8.6-.3-13.8zM89.8 323.6l12-6.8L103 303l10.2 9.3 13.4-3.1-5.7 12.6 7.2 11.8-13.8-1.6-9 10.5-2.8-13.6zM123.7 357.5l9.2-10.4-3.3-13.3 12.6 5.5 11.7-7.3-1.3 13.7 10.5 8.9-13.4 3-5.2 12.8-7-11.9z"
      />

      <path
        style={{ fill: colorConfig.color }}
        d="m163.5 377.8 6.1-12.4-6.8-12 13.7 1.9 9.4-10.1 2.3 13.6 12.6 5.7-12.2 6.5-1.7 13.7-9.8-9.6zM207.7 388.3l6.1-12.4-6.8-12.1 13.7 2 9.3-10.2 2.4 13.6 12.5 5.8-12.2 6.5-1.6 13.7-9.9-9.7zM251.5 367.8l12.6-5.6 2.7-13.5 9.1 10.3 13.7-1.7-7 11.9 5.9 12.6-13.5-3-10.1 9.4-1.3-13.8zM291.1 352.4l10.8-8.5-.9-13.8 11.5 7.7 12.8-5.2-3.7 13.3 8.8 10.6-13.8.6-7.3 11.7-4.8-13zM327.5 331.4l7.3-11.7-5.6-12.7 13.4 3.3 10.3-9.2 1.1 13.8 11.9 6.9-12.8 5.3-2.9 13.4-9-10.5zM351.5 296.5l5.3-12.7-7.4-11.7 13.7 1.2 8.8-10.7 3.1 13.5 12.9 5-11.8 7.1-.8 13.8-10.5-9zM369.6 258.3l1.8-13.7-10.3-9.2 13.6-2.5 5.6-12.6 6.6 12.1 13.7 1.5-9.5 10 2.9 13.5-12.5-6zM381.2 212.5l-4.9-12.9-13.4-3.3 10.7-8.6-1-13.8 11.6 7.6 12.7-5.3-3.6 13.4 8.9 10.5-13.7.7zM372.7 170.4l-8.4-11-13.8.7 7.8-11.3-4.9-12.9 13.2 3.9 10.7-8.7.4 13.8 11.6 7.5-13 4.6z"
      />

      <path
        style={{ fill: colorConfig.color }}
        d="m139.3 116.9-14.4-5c-.6.5-1.3 1.1-2 1.6l-8.2 5.9 7.2 10-4.7 3.4-23.7-33 12.8-9.2c8.6-6.2 16.9-5.6 22 1.6 3.7 5.1 3.7 10.7.2 15.6l15.8 5.4-5 3.7zm-15.6-21.4c-3.4-4.7-8.5-5.1-14.5-.8l-8 5.8 10.7 14.8 8-5.8c6-4.3 7.2-9.3 3.8-14zM159.7 91.3l5.5 12.9-5.3 2.2-5.5-13-25.2-18.1 5.7-2.4 20.3 14.6 3.7-24.8 5.3-2.2-4.5 30.8zM176.2 78.1c-2.2-11.8 5.2-22.1 17.3-24.4 6.2-1.2 11.9 0 16.3 3.4l-3 4.3c-3.7-2.7-7.7-3.5-12.1-2.6-9 1.7-14.5 9.4-12.8 18.3 1.6 8.8 9.5 14.1 18.5 12.4 4.4-.8 7.9-3.1 10.3-6.9l4.4 2.9c-2.9 4.8-7.8 7.9-14.1 9.1-11.9 2.2-22.5-4.7-24.8-16.5zM244 60.7c-.1 6.7-5.4 11.9-12.1 11.9-6.7-.1-11.8-5.4-11.8-12.1s5.3-11.9 12.1-11.8c6.9.1 11.9 5.3 11.8 12zm-1.6 0c.1-5.9-4.2-10.4-10.2-10.5s-10.4 4.5-10.5 10.3c-.1 5.8 4.3 10.5 10.2 10.5s10.5-4.4 10.5-10.3zm-7.1 2 3 4.8H236l-2.8-4.4h-3.7l-.1 4.3h-2.3l.2-13.5 5.4.1c3.4 0 5.4 1.9 5.4 4.7.1 1.9-1 3.3-2.8 4zm.7-4.2c0-1.7-1.2-2.7-3.4-2.7h-3l-.1 5.4h3c2.2 0 3.4-1 3.5-2.7zM282.7 63l-4 13.6 19.5 5.8-1.4 4.8-19.5-5.8-4.8 15.6-5.6-1.7 11.6-38.9 27.5 8.2-1.4 4.8-21.9-6.4zM319.9 71.2l5.2 2.5-17.7 36.5-5.2-2.5 17.7-36.5zM345.7 91.6l-11.4-7.9 2.9-4.1 27.6 19.2-2.9 4.1-11.4-7.9-20.3 29.2-4.7-3.3 20.2-29.3z"
      />

      <text
        style={{
          fill: colorConfig.color,
          stroke: colorConfig.color,
          fontSize: '85.7719px',
        }}
        transform="translate(108.149 250.29)"
      >
        {pointFloor}+
      </text>
    </svg>
  );
};
