import Skeleton from '@mui/material/Skeleton';
import { alpha, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Calender, Clock } from '@onesource/icons';
import { FC, memo } from 'react';
import { ellipsis } from '@onesource/utils';

export const PostLoading: FC = memo(() => {
  const theme = useTheme();

  const postMetaStyle = {
    fontSize: '13px',
    fontWeight: '400',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: theme.palette.grey[800],
    gap: '6px',
  };

  return (
    <Card sx={{ borderRadius: 6, boxShadow: `0px 0px 11px ${theme.palette.common.black}20` }}>
      <Box sx={{ position: 'absolute', top: '15px' }}>
        <Skeleton variant="circular" animation="wave" />
      </Box>

      <Skeleton variant="rectangular" animation="wave" height={234} />

      <CardContent sx={{ p: 0 }}>
        <Box sx={{ background: theme => alpha(theme.palette.grey[500], 0.1), padding: '8px' }}>
          <Stack direction="row" spacing={2} justifyContent="space-between">
            <Typography sx={postMetaStyle}>
              <Clock fontSize={20} color={theme.palette.grey[500]} />
              <Skeleton variant="rectangular" animation="wave" width={50} />
            </Typography>
            <Typography sx={postMetaStyle}>
              <Calender fontSize={20} color={theme.palette.grey[500]} />
              <Skeleton variant="rectangular" animation="wave" width={80} />
            </Typography>
            <Typography sx={postMetaStyle}>
              <Skeleton variant="rectangular" animation="wave" width={15} />
              <Skeleton variant="rectangular" animation="wave" width={15} />
            </Typography>
          </Stack>
        </Box>

        <Box pb={2} px={2} pt={2}>
          <Typography gutterBottom variant="body1" component="div" sx={{ fontSize: '18px', ...ellipsis }}>
            <Skeleton variant="rectangular" animation="wave" />
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <Skeleton variant="rectangular" animation="wave" width={100} />
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
});
