import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { SxProps } from '@mui/material';
import Grid from '@mui/material/Grid';
import { FC, ReactNode } from 'react';

type TTypographyProps = {
  isIconButton?: false;
  onClick?: undefined;
};

type TIconButtonProps = {
  isIconButton: true;
  onClick: () => void;
};

type TProps = {
  children: ReactNode;
  sx?: SxProps;
} & (TTypographyProps | TIconButtonProps);

export const CaptionLabelItem: FC<TProps> = props => {
  const { children, sx, isIconButton = false, onClick } = props;

  const Child = () => {
    if (isIconButton) {
      return <IconButton onClick={() => onClick?.()}>{children}</IconButton>;
    }

    return (
      <Typography fontWeight={400} fontSize="28px">
        {children}
      </Typography>
    );
  };

  return (
    <Grid
      item
      sx={{
        height: '100%',
        display: 'grid',
        placeItems: 'center',
        ...sx,
      }}
    >
      <Child />
    </Grid>
  );
};
