import { capitalize, darken, useTheme } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { InstructorSelect, TUseSetSearchParam, useGetByDevice } from '@onesource/components';
import { CategoryEnumValues } from '@onesource/schemas';
import { FC, useCallback } from 'react';
import { GrClose } from 'react-icons/gr';
import { LiaAngleDownSolid } from 'react-icons/lia';
import { RangeSlider } from '../filter-fields/range-for-indicator/index.tsx';
import { SidebarFilterCheckboxGroup } from './checkbox-group/index.tsx';
import { SidebarFilterRadioGroup } from './radio-group/index.tsx';

export type TSidebarItem =
  | 'instructor'
  | 'category'
  | 'duration'
  | 'equipments'
  | 'difficulty'
  | 'intensity'
  | 'nerdiness';
// | 'awesomeness';

export type TPostFilterSidebarProps = {
  searchFilterUtils: TUseSetSearchParam;
  hide?: TSidebarItem[];
  isDefaultExpanded?: boolean;
};

export const PostFilterSidebar: FC<TPostFilterSidebarProps> = props => {
  const { searchFilterUtils, hide = [], isDefaultExpanded } = props;
  const { patchFilter, filters, handleResetFilters } = searchFilterUtils;
  const theme = useTheme();
  const { isPortable } = useGetByDevice();

  const existingEquipments = filters?.equipments?.split(',') || [];

  const hideInstructor = !hide.includes('instructor');
  const hideCategory = !hide.includes('category');
  const hideDuration = !hide.includes('duration');
  const hideEquipments = !hide.includes('equipments');
  const hideDifficulty = !hide.includes('difficulty');
  const hideIntensity = !hide.includes('intensity');
  const hideNerdiness = !hide.includes('nerdiness');
  // const hideAwesomeness = !hide.includes('awesomeness');

  const categories = CategoryEnumValues.map(category => ({ id: category, label: capitalize(category.toLowerCase()) }));

  const equipments = [
    { label: 'Block', id: 'block' },
    { label: 'Round Band / Resistance Loop', id: 'round-band-resistance-loop' },
    { label: 'Weights', id: 'weights' },
    { label: 'Slider', id: 'slider' },
    { label: 'Resistance Band', id: 'resistance-band' },
    { label: 'Light Weights', id: 'light-weights' },
    { label: 'Chair / Stool', id: 'chair-stool' },
    { label: 'Blanket', id: 'blanket' },
    { label: 'Bolster', id: 'bolster' },
    { label: 'Half Roller', id: 'half-roller' },
    { label: 'Dowel', id: 'dowel' },
    { label: 'Inflatable (Pilates) Ball', id: 'inflatable-ball' },
    { label: 'Pinky / Tennis Ball', id: 'pinky-tennis-ball' },
    { label: 'No Props', id: 'no-props' },
    { label: 'Pillows / Blankets', id: 'pillows-blankets' },
    { label: 'Strap (yoga strap)', id: 'strap-yoga-strap' },
    { label: 'Ankle Weight', id: 'ankle-weight' },
  ];

  const onFilterChange = useCallback((filterName: string, value: string) => {
    const filterToUpdate = { [filterName]: value };
    patchFilter(filterToUpdate);
  }, []);

  const [durationValueMin, durationValueMax] = (filters?.duration || '')?.split('-').map(Number);
  const [difficultyValueMin, difficultyValueMax] = (filters?.difficulty || '')?.split('-').map(Number);
  const [intensityValueMin, intensityValueMax] = (filters?.intensity || '')?.split('-').map(Number);
  const [nerdinessValueMin, nerdinessValueMax] = (filters?.nerdiness || '')?.split('-').map(Number);
  // const [awesomenessValueMin, awesomenessValueMax] = (filters?.awesomeness || '')?.split('-').map(Number);

  const accordionStyle = {
    background: 'none',
    borderRadius: `0 !important`,
    borderBottom: { xs: `1px solid ${theme.palette.grey[300]}`, md: `1px solid ${theme.palette.common.black}` },
    mb: 0,
    mt: 0,
    '&.Mui-expanded': { mb: 0, mt: 0 },
  };

  const accordionSummaryStyle = {
    fontSize: { xs: '16px', md: '24px' },
    '&.Mui-expanded': { mb: 0 },
    '& .MuiAccordionSummary-content': {
      mt: { xs: 0, md: 2.5 },
      mb: { xs: 0, md: 2.5 },
    },
  };

  return (
    <Box>
      <Typography
        variant="body2"
        sx={{
          mt: -8,
          mb: 1,
          textAlign: 'center',
          fontSize: '13px',
          lineHeight: 2,
          color: theme => theme.palette.grey[500],
          display: isPortable ? 'none' : 'block',
        }}
      >
        Use the filters or search by class title or keywords to find the perfect class!
      </Typography>
      <Box
        sx={{
          background: theme => darken(theme.palette.grey[50], 0.01),
          borderRadius: '30px',
          p: 2,
          pb: { xs: 2, md: 2 },
          mb: { xs: -1, md: 5 },
          boxShadow: '10px 10px 10px 0px rgba(0, 0, 0, 0.05)',
        }}
      >
        <Stack direction="row" justifyContent="end" mb={3}>
          <Button
            onClick={handleResetFilters}
            endIcon={
              <Box
                sx={{
                  background: theme.palette.primary.main,
                  borderRadius: '100%',
                  padding: '14px',
                  lineHeight: 0,
                }}
              >
                <GrClose
                  style={{
                    height: '17px',
                    width: '17px',
                  }}
                />
              </Box>
            }
            sx={{ minWidth: 'auto' }}
          >
            Clear
          </Button>
        </Stack>

        {hideInstructor && (
          <Accordion defaultExpanded={isDefaultExpanded} sx={accordionStyle}>
            <AccordionSummary
              expandIcon={<LiaAngleDownSolid color={theme.palette.grey[800]} />}
              sx={accordionSummaryStyle}
            >
              Instructor
            </AccordionSummary>
            <AccordionDetails>
              <InstructorSelect
                sx={{ mb: { xs: 0, md: 2 } }}
                selected={filters.instructorId}
                onChange={onFilterChange}
              />
            </AccordionDetails>
          </Accordion>
        )}
        {hideCategory && (
          <Accordion defaultExpanded={isDefaultExpanded} sx={accordionStyle}>
            <AccordionSummary
              expandIcon={<LiaAngleDownSolid color={theme.palette.grey[800]} />}
              sx={accordionSummaryStyle}
            >
              Category
            </AccordionSummary>
            <AccordionDetails>
              <SidebarFilterRadioGroup
                value={filters.categoryId}
                onChange={onFilterChange}
                items={categories}
                name="categoryId"
              />
            </AccordionDetails>
          </Accordion>
        )}
        {hideDuration && (
          <Accordion defaultExpanded={isDefaultExpanded} sx={accordionStyle}>
            <AccordionSummary
              expandIcon={<LiaAngleDownSolid color={theme.palette.grey[800]} />}
              sx={accordionSummaryStyle}
            >
              Duration
            </AccordionSummary>
            <AccordionDetails>
              <RangeSlider
                type="duration"
                onChange={value => {
                  onFilterChange('duration', value.join('-'));
                }}
                value={[durationValueMin, durationValueMax]}
                min={0}
                max={100}
              />
            </AccordionDetails>
          </Accordion>
        )}
        {hideEquipments && (
          <Accordion defaultExpanded={isDefaultExpanded} sx={accordionStyle}>
            <AccordionSummary
              expandIcon={<LiaAngleDownSolid color={theme.palette.grey[800]} />}
              sx={accordionSummaryStyle}
            >
              Props
            </AccordionSummary>
            <AccordionDetails>
              <SidebarFilterCheckboxGroup
                onChange={(name, value) => {
                  if (existingEquipments.includes(value)) {
                    onFilterChange(name, existingEquipments.filter((x: string) => x !== value).join(','));
                    return;
                  }
                  onFilterChange(name, [...existingEquipments, value].join(','));
                }}
                items={equipments}
                name="equipments"
                value={existingEquipments.filter((x: string) => x)}
              />
            </AccordionDetails>
          </Accordion>
        )}
        {hideDifficulty && (
          <Accordion defaultExpanded={isDefaultExpanded} sx={accordionStyle}>
            <AccordionSummary
              expandIcon={<LiaAngleDownSolid color={theme.palette.grey[800]} />}
              sx={accordionSummaryStyle}
            >
              Difficulty
            </AccordionSummary>
            <AccordionDetails>
              <RangeSlider
                type="intensity"
                onChange={value => {
                  onFilterChange('difficulty', value.join('-'));
                }}
                value={[difficultyValueMin, difficultyValueMax]}
                min={1}
                max={5}
              />
            </AccordionDetails>
          </Accordion>
        )}
        {hideIntensity && (
          <Accordion defaultExpanded={isDefaultExpanded} sx={accordionStyle}>
            <AccordionSummary
              expandIcon={<LiaAngleDownSolid color={theme.palette.grey[800]} />}
              sx={accordionSummaryStyle}
            >
              Intensity
            </AccordionSummary>
            <AccordionDetails>
              <RangeSlider
                type="intensity"
                onChange={value => {
                  onFilterChange('intensity', value.join('-'));
                }}
                value={[intensityValueMin, intensityValueMax]}
                min={1}
                max={5}
              />
            </AccordionDetails>
          </Accordion>
        )}
        {hideNerdiness && (
          <Accordion defaultExpanded={isDefaultExpanded} sx={accordionStyle}>
            <AccordionSummary
              expandIcon={<LiaAngleDownSolid color={theme.palette.grey[800]} />}
              sx={accordionSummaryStyle}
            >
              Nerdiness
            </AccordionSummary>
            <AccordionDetails>
              <RangeSlider
                type="intensity"
                onChange={value => {
                  onFilterChange('nerdiness', value.join('-'));
                }}
                value={[nerdinessValueMin, nerdinessValueMax]}
                min={1}
                max={5}
              />
            </AccordionDetails>
          </Accordion>
        )}

        {/* {hideAwesomeness && (
          <Accordion
            defaultExpanded
            sx={{
              background: 'none',
              borderRadius: `0 !important`,
              borderBottom: theme => `1px solid ${theme.palette.common.black}`,
              mb: 0,
              mt: 0,
              '&.Mui-expanded': { mb: 0, mt: 0 },
            }}
          >
            <AccordionSummary
              expandIcon={<LiaAngleDownSolid color={theme.palette.grey[800]} />}
              sx={{
                fontSize: '24px',
                '&.Mui-expanded': { mb: 0 },
                '& .MuiAccordionSummary-content': {
                  mt: 2.5,
                  mb: 2.5,
                },
              }}
            >
              Awesomeness
            </AccordionSummary>
            <AccordionDetails>
              <RangeSlider
                type="intensity"
                onChange={value => {
                  onFilterChange('awesomeness', value.join('-'));
                }}
                value={[awesomenessValueMin, awesomenessValueMax]}
                min={0}
                max={5}
              />
            </AccordionDetails>
          </Accordion>
        )} */}
        <Stack direction="row" justifyContent="end" mt={3}>
          <Button
            onClick={handleResetFilters}
            endIcon={
              <Box
                sx={{
                  background: theme.palette.primary.main,
                  borderRadius: '100%',
                  padding: '14px',
                  lineHeight: 0,
                }}
              >
                <GrClose
                  style={{
                    height: '17px',
                    width: '17px',
                  }}
                />
              </Box>
            }
            sx={{ minWidth: 'auto' }}
          >
            Clear
          </Button>
        </Stack>
      </Box>

      {/* <Box sx={{ position: 'relative' }}>
        <SearchIcon
          style={{
            position: 'absolute',
            top: '5px',
            right: '11px',
            height: '45px',
            width: '45px',
            zIndex: 1,
          }}
        />
        <TextField
          label="Search"
          fullWidth
          InputProps={{
            sx: {
              background: theme => darken(theme.palette.grey[50], 0.01),
              borderRadius: '30px',
            },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: 'none',
              },
              '&:hover fieldset': {
                border: 'none',
              },
              '&.Mui-focused fieldset': {
                border: 'none',
              },
            },
          }}
        />
      </Box> */}
    </Box>
  );
};
