import { FC, ReactElement } from 'react';
import { AlertDialog } from '../dialog';
import { useConfirmService } from '../service';

export interface IConfirmProps {
  children: ReactElement;
}

export const ConfirmProvider: FC<IConfirmProps> = props => {
  const { children } = props;
  const { confirmConfig, closeConfirm } = useConfirmService();

  return (
    <>
      {children}

      {confirmConfig && (
        <AlertDialog onClose={closeConfirm} {...confirmConfig}>
          {confirmConfig?.message}
        </AlertDialog>
      )}
    </>
  );
};
