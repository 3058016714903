import type { SVGProps } from 'react';
const SvgCardio = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 42 42"
    fontSize="1rem"
    {...props}
  >
    <path
      fill="currentColor"
      d="M11.813 22.313H5.25a1.313 1.313 0 0 1 0-2.625h5.86l2.235-3.354a1.313 1.313 0 0 1 2.185 0l4.158 6.234 1.532-2.296a1.31 1.31 0 0 1 1.093-.584h3.937a1.313 1.313 0 0 1 0 2.625h-3.235l-2.235 3.353a1.313 1.313 0 0 1-2.185 0l-4.157-6.238-1.533 2.297a1.31 1.31 0 0 1-1.092.587M29.203 5.25c-3.388 0-6.354 1.457-8.203 3.92-1.849-2.463-4.815-3.92-8.203-3.92A10.183 10.183 0 0 0 2.625 15.422v.369a1.313 1.313 0 0 0 2.625-.082v-.287a7.557 7.557 0 0 1 7.547-7.547c3.19 0 5.87 1.7 6.989 4.43a1.313 1.313 0 0 0 2.428 0c1.119-2.735 3.798-4.43 6.99-4.43a7.557 7.557 0 0 1 7.546 7.547c0 8.795-12.757 16.759-15.75 18.506-1.777-1.035-6.994-4.265-10.94-8.566a1.313 1.313 0 0 0-1.936 1.776c5.114 5.578 11.966 9.299 12.254 9.455a1.31 1.31 0 0 0 1.244 0c.725-.391 17.753-9.687 17.753-21.171A10.183 10.183 0 0 0 29.203 5.25"
    />
  </svg>
);
export default SvgCardio;
