import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material';
import { MvPlay } from '@onesource/icons';
import { ellipsis } from '@onesource/utils';
import { FC } from 'react';
import { IndicatorEnum, TContent } from '@onesource/schemas';
import { RequirementIndicator } from '../../requirement-indicator';
import { Link } from '@tanstack/react-router';

type TProps = {
  content: TContent;
  isLocked: boolean;
  blockClick?: boolean;
};

export const HoverCardOverlay: FC<TProps> = props => {
  const { content, isLocked, blockClick } = props;
  const theme = useTheme();

  if (isLocked) {
    return null;
  }

  const { equipments, averageRating, video, instructor, name } = content;
  const videoDescription = video.description;
  const videoDescriptionLength = videoDescription.length;

  return (
    <Grid
      sx={{
        padding: 3,
        width: '100%',
        height: '100%',
        borderRadius: 6,
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        cursor: 'default',
      }}
    >
      <Grid container spacing={0}>
        <Grid xs={9} item>
          <Tooltip title={name}>
            <Typography gutterBottom variant="body1" sx={ellipsis} color={theme.palette.grey[800]}>
              {name}
            </Typography>
          </Tooltip>
          <Typography variant="body2" color="text.secondary">
            {instructor.firstName} {instructor.lastName}
          </Typography>
        </Grid>

        <Grid xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }} item>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '50px',
              width: '50px',
              borderRadius: '50%',
              background: theme => theme.palette.primary.main,
              color: theme => theme.palette.common.white,
              boxShadow: `5px 5px 15px ${theme.palette.common.black}20`,
            }}
          >
            <Link
              to={`/classes/details/${content.id}`}
              {...(blockClick && {
                onClick: event => event.preventDefault(),
              })}
            >
              <Tooltip title="Play">
                <IconButton size="large" sx={{ color: theme => theme.palette.common.white }}>
                  <MvPlay />
                </IconButton>
              </Tooltip>
            </Link>
          </Box>
        </Grid>

        <Grid item xs={12} sx={{ pt: 1, pb: 2 }}>
          <Divider />
        </Grid>
      </Grid>

      <Typography
        variant="body2"
        color="text.secondary"
        sx={{
          marginBottom: '10px',
          fontSize: '13px',
          flexGrow: 1,
        }}
      >
        {Boolean(videoDescriptionLength) && (
          <span
            dangerouslySetInnerHTML={{
              __html: videoDescriptionLength > 200 ? videoDescription.substring(0, 200) + '...' : videoDescription,
            }}
          />
        )}
      </Typography>

      <Stack direction="row" flexWrap="wrap" overflow="hidden" height="60px" width="100%" alignItems="end" mb={2.5}>
        {equipments.map(item => (
          <Chip
            key={item.slug}
            label={item.name}
            sx={{ mr: 1, px: 2, mb: 1, backgroundColor: theme => theme.palette.grey[800], color: 'white' }}
            size="small"
          />
        ))}
      </Stack>

      <Stack direction="row" justifyContent="space-between" width="100%">
        <RequirementIndicator
          indicator={IndicatorEnum.difficulty}
          point={averageRating.difficulty}
          isInteractive={false}
        />

        <RequirementIndicator
          indicator={IndicatorEnum.intensity}
          point={averageRating.intensity}
          isInteractive={false}
        />

        <RequirementIndicator
          indicator={IndicatorEnum.nerdiness}
          point={averageRating.nerdiness}
          isInteractive={false}
        />
      </Stack>
    </Grid>
  );
};
