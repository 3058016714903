import { TMovePlaylistContentRequestSchema } from '@onesource/schemas';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import axios from '../axios';
import { ENTITY } from './entity';
import { getAllPlaylistsQueryKey } from './use-get-all-playlist-query';

export const useMovePlaylistContentMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: TMovePlaylistContentRequestSchema) => {
      return axios.patch(`/${ENTITY}/move-playlist-item`, payload);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: getAllPlaylistsQueryKey() });

      enqueueSnackbar(`Move playlist item successful!`, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    },
    onError: () => {
      enqueueSnackbar('Unable to move playlist item', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    },
  });
};
