import type { SVGProps } from 'react';
const SvgLocation = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 14 19"
    fontSize="1rem"
    {...props}
  >
    <g fill="currentColor" clipPath="url(#location_svg__a)">
      <path d="M6.856 19c-.21-.183-.437-.35-.624-.553-1.554-1.686-2.979-3.477-4.17-5.45C1.335 11.79.697 10.534.31 9.162-.37 6.744.068 4.54 1.611 2.593 2.751 1.155 4.26.307 6.068.068c2.274-.3 4.242.397 5.873 2.016 1.12 1.111 1.768 2.488 1.994 4.072.196 1.364-.07 2.656-.543 3.919-.504 1.347-1.206 2.588-1.992 3.781a35 35 0 0 1-3.832 4.804c-.123.13-.28.227-.42.34zm.141-1.407c.057-.086.076-.126.105-.157 1.204-1.339 2.334-2.738 3.328-4.246.913-1.385 1.716-2.827 2.17-4.446.262-.935.31-1.877.116-2.825-.492-2.394-2.355-4.223-4.722-4.646a5.55 5.55 0 0 0-3.332.413C3.285 2.31 2.28 3.33 1.666 4.733a5.7 5.7 0 0 0-.436 3.17c.162 1.101.569 2.118 1.064 3.1.746 1.48 1.664 2.846 2.692 4.136.646.811 1.315 1.604 2.013 2.453z" />
      <path d="M10.502 7.125c-.004 1.963-1.586 3.566-3.51 3.558-1.929-.008-3.503-1.62-3.49-3.578.011-1.958 1.581-3.549 3.501-3.548 1.927 0 3.504 1.609 3.5 3.568M7 9.495c1.282.001 2.332-1.065 2.335-2.37.004-1.303-1.04-2.374-2.323-2.38C5.724 4.738 4.666 5.81 4.67 7.123 4.67 8.428 5.719 9.494 7 9.495" />
    </g>
    <defs>
      <clipPath id="location_svg__a">
        <path fill="#fff" d="M0 0h14v19H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgLocation;
