import { TContentRatingSet, TError } from '@onesource/schemas';
import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import axios from '../axios';
import { ENTITY } from './entity';

interface ISaveContentRatingParams {
  contentId: string;
  rating: TContentRatingSet;
}

const mutationFn = async (params: ISaveContentRatingParams) => {
  const { contentId, rating } = params;
  const res = await axios.patch<TContentRatingSet>(`${ENTITY}/rating/${contentId}`, rating);
  return res.data;
};

export const useSaveContentRatingQuery = () => {
  return useMutation<TContentRatingSet, TError, ISaveContentRatingParams>({
    mutationFn,
    onSuccess: async () => {
      enqueueSnackbar(`Rating save successful!`, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    },
    onError: () => {
      enqueueSnackbar('Unable to submit rating', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    },
  });
};
