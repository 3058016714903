import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { FC, memo } from 'react';

export const PlaylistCardLoading: FC = memo(() => {
  return (
    <Card
      sx={{
        borderRadius: 4,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        minHeight: '200px',
        boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      }}
    >
      <Grid container spacing={2}>
        <Skeleton width="100%" height={300} variant="rectangular" />
      </Grid>
    </Card>
  );
});
