import type { SVGProps } from 'react';
const SvgInstagram = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 29 29"
    fontSize="1rem"
    {...props}
  >
    <g fill="#CE3B4D" clipPath="url(#instagram_svg__a)">
      <path d="M7.148.417h14.073c.09.015.18.037.27.047.769.078 1.518.245 2.214.568 3.004 1.398 4.612 3.744 4.652 7.02.053 4.23.015 8.465.012 12.695 0 1.326-.293 2.59-.978 3.733-1.533 2.562-3.83 3.874-6.847 3.889-4.238.021-8.476.01-12.714 0-.533 0-1.078-.03-1.596-.143-3.128-.69-5.133-2.57-5.997-5.613-.122-.415-.16-.854-.237-1.28V7.462c.046-.296.074-.595.14-.883C.851 3.49 2.75 1.504 5.845.649 6.268.53 6.713.492 7.148.417M1.663 14.369c0 2.092-.008 4.185.007 6.278.003.47.032.952.139 1.407.666 2.832 3.033 4.68 5.977 4.682q6.397.009 12.795-.005a6.2 6.2 0 0 0 1.372-.136c2.872-.659 4.748-2.99 4.751-5.892q.009-6.305-.005-12.61c0-.444-.035-.896-.13-1.328-.62-2.791-3.018-4.697-5.93-4.706-4.302-.011-8.605-.003-12.907-.003-.87 0-1.714.147-2.497.522-2.328 1.116-3.531 2.963-3.567 5.513-.029 2.092-.005 4.185-.005 6.278" />
      <path d="M14.205 6.752c4.276.023 7.76 3.477 7.736 7.666-.024 4.213-3.528 7.647-7.778 7.623-4.275-.023-7.759-3.477-7.735-7.665.024-4.214 3.527-7.647 7.777-7.624m6.072 7.657c.012-3.298-2.734-6.011-6.087-6.016-3.338-.005-6.074 2.676-6.097 5.98-.024 3.293 2.715 6.012 6.073 6.029 3.343.017 6.1-2.686 6.111-5.993M22.131 8.553c-1.247.005-2.29-1.02-2.294-2.253-.003-1.227 1.039-2.258 2.286-2.263 1.241-.005 2.29 1.026 2.297 2.255.007 1.222-1.04 2.256-2.289 2.26m.62-2.268a.634.634 0 0 0-.628-.604.636.636 0 0 0-.621.612.64.64 0 0 0 .643.619c.331-.012.612-.304.605-.625z" />
    </g>
    <defs>
      <clipPath id="instagram_svg__a">
        <path fill="#fff" d="M0 .417h28.383v27.965H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgInstagram;
